const buildEnv = 'preprod';
export const environment = {
  production: true,
  apiDomain: 'https://api.prod.astrotalk.in/AstroTalk/',
  apiDomainForList: 'https://api.consultant.list.astrotalk.com/AstroTalk/',
  chatApi: 'https://api.paidchat.astrotalk.com/AstrotalkChat/',
  apiDomainSupport: 'https://api.supportchat.astrotalk.com/AstroChat/',
  tokenName: 'jwt_token',
  domain: 'https://shaadi.astrotalk.com/',
  kundliApi: 'https://api.kundali.astrotalk.com/v1/',

  LOCATIONAPIDOMAIN: 'https://api.locationiq.com/v1/',
  SECONDPLACES: 'pk.e8a0a377dd1df522d510716ba61eaeab',
  CODEYETI_RAZOR_PAY: 'rzp_live_nbFIyWp9PWCqNl',
  MASKYETI_RAZOR_PAY: 'rzp_live_idcMSOkCdvdxCB',
  ASTROTALK_RAZOR_PAY: 'rzp_live_Mw6ZYXea2k4yyj',
  astroMallApi: 'https://api.astromall.astrotalk.com/AstroMall/',
  astroTvApi: 'https://api.live.astrotalk.com/AstrotalkLive/',
  trueCallerKey: 'WQSEab6e063f5fe3c4884b10401f4ce45147a',
  trueCallerName: 'Astrotalk',
  PaypalID: 'AXcfQe-af8zaTrrfPYHwXDcVZ1CwkamtBa-VXBwbqP4cJBhni3_34Zy8t4mxyA3sjudNpXexeqe50XcP',
  PaypalEnv: 'production',
  PAYTMMID: 'CODEYE84459150770958',
  PAYTMWEBSITE: 'WEBPROD',
  INDUSTRY_TYPE_ID: 'Retail109',
  CHANNEL_ID: 'WAP',
  payULink: 'https://secure.payu.in/_payment',
  payUKey: 'g1tT0E',
  firebase: {
    apiKey: 'AIzaSyCwst9CLNKuA5UvPuxWdRV6nd-_fE8TF6E',
    authDomain: 'astroyeti-4bc02.firebaseapp.com',
    databaseURL: 'https://astroyeti-4bc02.firebaseio.com',
    projectId: 'astroyeti-4bc02',
    storageBucket: 'astroyeti-4bc02.appspot.com',
    messagingSenderId: '135983970470',
    appId: '1:135983970470:web:704107c27bf32e2a351c67',
    measurementId: 'G-MB47Q5ZT36'
  },
  PORT: 4000,
  CDN_DOMAIN: 'http://aws.astrotalk.com',
  // runPM2In Server: "/var/www/html/astroTalk$ pm2 start dist/webApp/server/main.js"
  // run Pm2In dev: "/var/www/html/astroTalk$ PORT=4200 pm2 start dist/webApp/server/main.js"
};
