import {NgModule} from '@angular/core';
import {BrowserModule, BrowserTransferStateModule, Title, TransferState} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {GlobalModule} from './global/global.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_BASE_HREF} from '@angular/common';
import {CommonService} from './global/common-file/common.service';
import {HttpWrapperService} from './global/common-file/http-wrapper.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {MatDialogModule} from '@angular/material/dialog';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment.prod';
import {MessagingService} from './shared/messaging.service';
import {WindowService} from './global/common-file/window.service';
import {TranslateBrowserLoaderService} from './lang-translate/translate-browser-loader.service';
import {TransferStateInterceptor} from './transfer-state-intercepter';
import {TranslateLoader, TranslateModule, TranslateStore} from '@ngx-translate/core';
import {CustomPreloadingStrategy} from './custom-preloading-strategy';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AngularFireModule.initializeApp(environment.firebase, 'firebase'),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GlobalModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: false,
      progressBar: true
    }),
    BrowserTransferStateModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState]
      }
    }),
    MatDialogModule
  ],
  providers: [
    Title,
    CommonService,
    HttpWrapperService,
    MessagingService,
    WindowService,
    CustomPreloadingStrategy,
    {provide: APP_BASE_HREF, useValue: ''},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferStateInterceptor,
      multi: true
    },
    TranslateStore
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function exportTranslateStaticLoader(http: HttpClient, transferState: TransferState) {
  return new TranslateBrowserLoaderService('./assets/i18n/', '.json', transferState, http);
}
