import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {

  transform(value: number, formate: string): string {
    if (value) {
      return this.secondsToHms(value, formate);
    }
    return '';
  }

  secondsToHms = (time, formate) => {
    time = Number(time);
    const d = Math.floor(time / 86400);
    const h = Math.floor((time / 3600) % 24);
    const m = Math.floor((time % 3600) / 60);
    const s = Math.floor((time % 3600) % 60);

    const dDisplay = d;
    const hDisplay = h;
    const mDisplay = m;
    const sDisplay = s;
    if (formate == 'd') {
      return this.pad(dDisplay) + ':' + this.pad(hDisplay) + ':' + this.pad(mDisplay) + ':' + this.pad(sDisplay) + ' days';
    } else if (formate == 'h') {
      return this.pad(hDisplay) + ':' + this.pad(mDisplay) + ':' + this.pad(sDisplay) + ' hours';
    } else if (formate == 'm') {
      return this.pad(mDisplay) + ':' + this.pad(sDisplay) + ' mins';
    } else if (formate == 's') {
      return this.pad(sDisplay) + ' sec';
    } else if (formate == 'minsOnly') {
      return this.padOnly(sDisplay) + 'm';
    } else if (formate == 'mins') {
      return this.pad((h * 60) + m) + ':' + this.pad(sDisplay) + ' mins';
    } else if (formate == 'live-wait') {
      time = time * 1000;
      return this.pad(Math.floor(time / 1000 / 60)) + ' m ' + this.pad(Math.floor((time / 1000) % 60)) + ' s';
    } else if (formate == 'live') {
      return this.pad(Math.floor(time / 60)) + ' m ' + this.pad(Math.floor((time) % 60)) + ' s';
    } else if (formate == 'live-m') {
      return this.pad(mDisplay);
    } else if (formate == 'live-s') {
      return this.pad(sDisplay);
    }
  }

  pad = (d) => {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  padOnly = (d) => {
    return d.toString();
  }

}
