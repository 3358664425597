import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticateService} from '../../auth/authenticate.service';
import {CommonService} from '../common-file/common.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {MessagingService} from '../../shared/messaging.service';
import firebase from 'firebase/app';
import {WindowService} from '../common-file/window.service';
import {environment} from '../../../environments/environment';
import {ProfileService} from '../../profile-user/profile.service';
import {Location} from '@angular/common';
import {interval, Subscription} from 'rxjs';
import {AppConstant} from '../common-file/constant';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {GoogleLoginProvider, SocialAuthService} from 'angularx-social-login';

declare let gtag: Function;
declare let fbq: Function;

declare var $: any;
declare let window: any;

firebase.initializeApp(environment.firebase);
require('firebase/auth');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public mobileNoSearch: string;
  public isMobile = false;
  jwtToken: string = null;
  isLogin = false;
  userData: any = [];
  modal: any;
  otpBox = false;
  public countries: any = null;
  public country: any;
  public verifyNumber: string;
  public sentMobile: boolean = false;
  public otpErrorMessage: string = '';
  public resentMobileCall: boolean = false;
  public sentMobileOtp: boolean = false;
  public otpSentErrorMessage: string = '';
  timeLeft: number = 60;
  timeLeftForeign: number = 30;
  interval;
  intervalForeign;
  resendCount: number = 5;
  resentMobile: boolean;
  successMessage: boolean;

  public lastName: string;
  public langDisabled: boolean = false;
  balence: number = 0;
  isForgen = false;
  isBrowser: boolean;
  browserTimeZone;
  windowRef: any;
  captcha = false;
  cdnDomain: string = environment.CDN_DOMAIN;
  otpResponse;
  loginObj = {
    mobile: '',
    countryCode: '+91'
  };
  otpLoader = false;
  otpVerifyLoader = false;
  fir;
  sec;
  thi;
  fou;
  fiv;
  six;
  otpSubmitButton = false;
  isRegistered: boolean;
  gotOtp = 1;
  firstNameNewUser;
  errorCountryTrue = false;
  errorMobileTrue = false;
  otpError = false;
  otpMismatchError = '';
  otpSentError = false;
  otpSentErrorMsg = '';
  mobileFirstZero = false;
  mobileFirstZeroMsg = '';
  switchLang: string;
  pageName: string;
  pageParams: any;
  langStatus: string;
  langTextFormat: string;
  isWebView: boolean;
  truecaller: boolean = false;
  loginFromTrueCallerData;
  callback;
  trueCallerSubscription: Subscription;
  callBackCount: number;
  queryParams: Subscription;
  partner;
  chatCallClose = false;
  checkCaptcha;
  uniqueUID = '';
  isTwilioActive = true;
  isToi = false;
  guestUser;
  // tokenData;
  productUUID = '';
  googleLoader = false;
  googleLoginErrorMsg = '';
  googleMobileRequired = false;
  googleLoginError = false;
  otpSeconds = false;
  googleUser;
  shaadiToken: string = '';
  isToShowPricePopUp = false;
  creditBonusPrice = 0;
  @ViewChild('otherDeviceLogout') logoutAllOtherDevices: TemplateRef<any>;
  @ViewChild('loginViaMobile') loginModal: TemplateRef<any>;
  @ViewChild('bonusTemplate') bonusTemplate: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticateService,
    private common: CommonService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private messagingService: MessagingService,
    private windowService: WindowService,
    private profileService: ProfileService,
    private recaptchaV3Service: ReCaptchaV3Service,
    public location: Location,
    private authSocialService: SocialAuthService,
  ) {
    const pageParams = [];
    this.isWebView = true; // (this.common.getItem('webview') == 'true') ? true : false;
    this.common.langStatus.subscribe((res) => {
      this.switchLang = res;
      this.langTextFormat = res;
      if (this.langTextFormat == 'en' || this.langTextFormat == '') {
        this.langTextFormat = 'Eng';
      }
      if (this.langTextFormat == 'hi') {
        this.langTextFormat = 'हिंदी';
      }
      if (this.langTextFormat == 'pa') {
        this.langTextFormat = 'ਪੰਜਾਬੀ';
      }
      if (this.langTextFormat == 'kn') {
        this.langTextFormat = 'ಕನ್ನಡ';
      }
      this.langStatus = (res == 'en') ? '/' : res + '/';
    });
    this.common.pageName.subscribe(res => {
      res = decodeURIComponent(res);
      if (res.indexOf('?') > 0) {
        this.pageName = res.substr(0, res.indexOf('?'));
        const params = res.slice(res.indexOf('?') + 1).split('&');
        for (let parm of params) {
          if (parm != '') {
            pageParams[parm.substr(0, parm.indexOf('='))] = parm.substr(parm.indexOf('=') + 1);
          }
        }
        this.pageParams = Object.assign({}, pageParams);
      } else {
        this.pageName = res;
        this.pageParams = null;
      }
      if ((this.pageName == '' || this.pageName == this.langStatus) && this.common.getItem('webview') == 'true') {
        this.router.navigateByUrl(this.langStatus + 'talk-to-astrologer?webview=true');
      }
      if (this.pageName.includes('book-a-pooja/booking-details') || this.pageName.includes('e-pooja/booking-details')) {
        const segments = this.pageName.split('/');
        this.productUUID = segments[segments.length - 1];
      }
    });
  }

  openModel(otp) {
    if (this.isBrowser) {
      this.messagingService.allowNotification(this.isLogin);
    }
    this.isToLoginMobileOrGoogle();
  }

  async isToUseCaptcha() {
    this.common.showFullPageLoader();
    const promise = await <any>this.common.getStaticValueForLogin(507).toPromise();
    this.common.hideFullPageLoader();
    if (promise?.status == 'success') {
      this.checkCaptcha = promise.value;
    }
  }

  closeModalLanguage() {
    this.modalService.dismissAll('click');
  }

  closeModal(reset = false, checkOtp) {
    this.modalService.dismissAll('click');
    this.fir = '';
    this.sec = '';
    this.thi = '';
    this.fou = '';
    this.fiv = '';
    this.six = '';
    this.otpSubmitButton = false;
    this.googleMobileRequired = false;
    if (checkOtp == 3) {
      this.triggerEventNewUser('', this.isRegistered, this.loginFlow == 'EMAIL' ? 'email' :
        ((this.loginObj.countryCode != '+91' && !this.isTwilioActive) ? 'firebase' : (this.truecaller ? 'truecaller' : 'phonnumber')));
      if (this.productUUID) {
        this.redirectGuestUser();
      }
    }
  }

  ngOnInit(): void {
    this.isMobile = this.common.checkDeviceIsMobile();
    this.isBrowser = this.common.checkDeviceIsBrowser();
    if (this.isBrowser) {
      if (this.isMobile && this.getMobileOperatingSystem() == 'Android' && !this.isWebView) {
        this.checkValueForTrueCaller();
      }
      this.getIp();
      this.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (this.browserTimeZone == 'Asia/Calcutta') {
        this.browserTimeZone = 'Asia/Kolkata';
      }
      this.windowRef = this.windowService.windowRef;
      $(document).click(function (e) {
        if (!$(e.target).closest('.modal').length) {
          $('#sideMenu').modal('hide');
        }
      });
    }
    this.queryParams = this.route.queryParams.subscribe(params => {
      this.isWebView = (params?.webview == 'true') ? true : false;
      this.common.setItem('webview', this.isWebView.toString());
      if (params?.partner) {
        this.partner = params.partner;
        this.common.setItem('partner', this.partner);
      }
      if (params?.q && params?.q != '') {
        this.shaadiToken = params?.q;
        if (this.isBrowser) {
          this.loginShaadiDotCom();
        }
      }
      if (this.queryParams) {
        this.queryParams.unsubscribe();
      }
    });
    this.common.getLoginValue().subscribe((value) => {
      this.isLogin = value;
      this.guestUser = this.common.getItem('guestUser');
      if (this.isLogin) {
        if (this.isBrowser) {
          this.jwtToken = this.common.getToken();
          this.userData = this.common.getUserData();
          this.getRateOfConvirsion();
        }
        if (this.userData.email) {
          const usersEmail = this.userData.email;
          if (usersEmail.includes('@')) {
          } else {
            this.userData.email = '';
          }
        }
        this.isForgen = (this.common.timeZone() != 'Asia/Kolkata');
      } else {
        if (this.isBrowser) {
          if (!this.common.getUserData('isForeign')) {
            this.common.setLocalTimeZone();
          }
        }
        this.isForgen = (this.common.timeZone() != 'Asia/Kolkata');
      }
    });
    this.countries = this.common.getMobileCode();
    this.country = this.common.getBrowserTimeZoneCountry();
    this.loginObj.countryCode = this.country.dial_code;
  }

  logout() {
    this.common.logout();
    this.userData = null;
    this.jwtToken = null;
    this.common.setLoginValue(false);
    this.router.navigate([this.langStatus + 'chat-with-astrologer']);
  }

  logoutOtherDevices = () => {
    const paramsData = {
      isLogoutFromOtherDevice: true,
      userId: this.common.getUserData('id')
    };
    this.authService.logoutFromAllDevices(paramsData).subscribe((res: any) => {
      if (res.status == 'success') {
        this.jwtToken = res.message;
        this.common.setToken(res.message);
        this.toastr.success('Successfully logged out from all the other devices', 'Logged out Successfully');
      }
    }, (error) => {
      console.error(error, 'logout error');
    });
  }

  logoutConfirm = () => {
    this.dialog.open(this.logoutAllOtherDevices, {
      data: {
        message: 'Are you sure you want to logout from all other devices?',
        warning: true,
      }
    });
  }

  public getIp() {
    if (!this.common.getIp()) {
      this.authService.ipAddress().subscribe((response: any) => {
        this.common.setIp(response?.ip);
      });
    }
  }

  startTimer(time = 0) {
    if (time) {
      this.timeLeft = time;
    } else {
      this.timeLeft = 60;
    }

    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  stopTimer() {
    this.pauseTimer();
    this.timeLeft = 0;
  }

  startTimerForeign(time = 0) {
    if (time) {
      this.timeLeftForeign = time;
    } else {
      this.timeLeftForeign = 30;
    }
    this.intervalForeign = setInterval(() => {
      if (this.timeLeftForeign > 0) {
        this.timeLeftForeign--;
      }
    }, 1000);
  }

  pauseTimerForeign() {
    clearInterval(this.intervalForeign);
  }

  stopTimerForeign() {
    this.pauseTimerForeign();
    this.timeLeftForeign = 0;
  }

  changeSource(event) {
    event.target.src = 'https://aws.astrotalk.com/assets/images/astro-background-gray.webp';
  }

  checkBalence = () => {
    if (this.isLogin && this.common.canApiCall() && !this.guestUser) {
      this.balence = 0;
      this.profileService.getBalence({
        pageNo: 0,
        pageSize: 1,
        userId: this.common.getUserData('id')
      }).subscribe((response: any) => {
        if (response.status == 'success') {
          this.balence = response.amount;
        }
      });
    }
  }

  closeMenu = () => {
    $('#sideMenu').modal('hide');
  }

  renderCaptcha = (contanar = 'phone-sign-in-recaptcha') => {
    this.captcha = true;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(contanar, {
      size: 'invisible',
      callback: (response) => {
        this.captcha = false;
      }
    });
    this.windowRef.recaptchaVerifier.render();
  }

  loginViaOtp = (form: NgForm) => {
    if (form.valid) {
      this.otpSentError = false;
      if (this.loginObj.countryCode != '+91') {
        this.twilioCheck();
      } else {
        if (this.loginObj.mobile?.length == 10) {
          this.otpLoader = true;
          if (this.checkCaptcha == 'true' || this.checkCaptcha == true) {
            this.captchaSolve();
          } else {
            this.loginNow();
          }
        } else {
          this.otpLoader = false;
          this.otpSentError = true;
          this.otpSentErrorMsg = 'Enter valid mobile number';
        }
      }
    }
  }

  twilioCheck() {
    this.otpLoader = true;
    const data = {
      countryCode: encodeURIComponent(this.loginObj.countryCode),
      appId: this.common.getAppId(),
      businessId: this.common.getBusinessId(),
      mobile: this.loginObj.mobile,
    };
    this.profileService.twilioCheck(data).subscribe((res: any) => {
      this.otpLoader = false;
      if (res.status == 'success') {
        this.isTwilioActive = res.isTwilioActive;
        if (res.isTwilioActive == true) {
          this.otpSeconds = true;
          if (this.checkCaptcha == 'true' || this.checkCaptcha == true) {
            this.captchaSolve();
          } else {
            this.loginNow();
          }
        } else {
          this.otpSeconds = false;
          this.sendOtpLoginViaFirebase(this.loginObj.countryCode + this.loginObj.mobile);
        }
      } else {
        this.common.toastrInvoke('error', res?.reason ? res.reason : 'Something went wrong please try again!');
      }
    }, () => {
      this.otpLoader = false;
      console.error('twilio error');
    });
  }

  loginNow(loginCaptcha = '') {
    const data = {
      countryCode: this.loginObj.countryCode.replace(/\+/g, ''),
      isCall: false,
      appId: this.common.appId,
      businessId: this.common.businessId,
      mobile: this.loginObj.mobile,
      captcha: loginCaptcha ?? '',
      /* email: '',
       password: '',
       appId: '',
       businessId: this.common.getBusinessId(),
       hardwareId: null,
       timeZone: this.common.timeZone(),
       gcmId: '' ---------------------------------------> use these params for username and password*/
    };
    this.profileService.loginVia4DigitOtpDetails(data).subscribe((res: any) => {
      this.otpLoader = false;
      if (res?.status == 'success') {
        const giveOtp = document.getElementById('giveOtp');
        this.gotOtp = 2;
        this.startTimer(this.timeLeft);
        this.otpResponse = res;
        setTimeout(() => {
          giveOtp.focus();
        }, 100);
      } else {
        this.otpSentError = true;
        this.otpSentErrorMsg = res.reason;
      }
    }, (error) => {
      this.otpLoader = false;
      this.otpSentError = true;
      this.otpSentErrorMsg = error.reason;
    });
  }

  captchaSolve(isCall = false, isResend = 0) {
    // this.streamLoader = true;
    this.recaptchaV3Service.execute('6LcOcMoZAAAAACytcWRqWrl13cVOXjAx2JEG_QHs').subscribe((token) => {
      if (token) {
        // this.getStreamToken();
        if (isResend == 0) {
          this.loginNow(token);
        } else {
          this.resendOtpNow(isCall, token);
        }
      } else {
        // this.streamLoader = false;
        this.common.toastrInvoke('error', 'Please resolve the captcha');
      }
    }, (error) => {
      this.otpLoader = false;
      if (error) {
        this.otpSentErrorMsg = error.error ? error.error : 'Seems its not a user activity';
      }
      // this.streamLoader = false;
      console.error('captchaError', error.error);
    });
  }

  resendOtpNow(isCalls, loginCaptcha = '') {
    const data = {
      countryCode: this.loginObj.countryCode.replace(/\+/g, ''),
      isCall: isCalls,
      appId: this.common.appId,
      businessId: this.common.businessId,
      mobile: this.loginObj.mobile,
      captcha: loginCaptcha ?? '',
    };
    this.profileService.loginVia4DigitOtpDetails(data).subscribe((response: any) => {
      this.resentMobile = false;
      this.resentMobileCall = false;
      if (response?.status == 'success') {
        this.gotOtp = 2;
        this.startTimer(this.timeLeft);
        this.otpResponse = response;
      } else {
        this.otpError = true;
        this.otpMismatchError = response?.reason;
        this.successMessage = false;
      }
    }, () => {
      this.successMessage = false;
      this.resentMobile = false;
      this.resentMobileCall = false;
    });
  }

  /*-------------------------------firebase otp---------------------------------------*/
  sendOtpLoginViaFirebase(mobile, resend = false) {
    this.isTwilioActive = false;
    this.otpLoader = true;
    this.googleLoader = true;
    this.otpSentError = false;
    this.googleLoginError = false;
    firebase.auth().signInWithPhoneNumber(mobile, this.windowRef.recaptchaVerifier).then(result => {
      this.otpLoader = false;
      this.googleLoader = false;
      this.windowRef.confirmationResult = result;
      this.gotOtp = 2;
      this.resentMobile = false;
      this.resentMobileCall = false;
      if (resend) {
        this.stopTimerForeign();
      }
      this.startTimerForeign(this.timeLeftForeign);
      this.isRegistered = true;
      setTimeout(() => {
        const giveOtp = document.getElementById('giveOtp');
        giveOtp.focus();
        this.saveLogs();
      }, 100);
    }, (error) => {
      this.otpLoader = false;
      this.otpSentError = true;
      this.googleLoader = false;
      this.googleLoginError = true;
      this.otpSentErrorMsg = this.googleLoginErrorMsg = error?.message;
    }).catch(error => {
      this.otpLoader = false;
      this.otpSentError = true;
      this.googleLoader = false;
      this.googleLoginError = true;
      this.otpSentErrorMsg = this.googleLoginErrorMsg = error?.message;
    });
  }

  verifyOtpLoginViaFirebase() {
    this.pauseTimer();
    this.otpVerifyLoader = true;
    const otpData = this.fir + this.sec + this.thi + this.fou + this.fiv + this.six;
    this.windowRef.confirmationResult.confirm(otpData).then(result => {
      const user = result.user;
      user.getIdToken().then((idToken) => {
        this.mobileLoginVerify(idToken, user.uid);
      });
    }).catch(error => {
      this.otpError = true;
      this.otpVerifyLoader = false;
      this.startTimerForeign(this.timeLeftForeign);
      this.otpMismatchError = error.message;
    });
  }

  mobileLoginVerify = (IDToken, uid) => {
    this.sentMobileOtp = true;
    const params = {
      mobile: this.loginObj.mobile,
      countryCode: encodeURIComponent(this.loginObj.countryCode),
      businessId: this.common.getBusinessId(),
      appId: this.common.getAppId(),
      idToken: IDToken,
      hardwareId: this.common.getDeviceId(),
      uId: uid,
      uuid: this.uniqueUID,
      timeZone: encodeURIComponent(this.browserTimeZone),
      gcmId: '',
      registerBy: 'WEB',
      version: '1.1',
      toi: (this.common.getItem('referrer') == 'TOI') ? true : false,
    };
    if (this.common.getItem('partner')) {
      params['marketSource'] = this.common.getItem('partner');
    }
    if (this.loginFlow == 'EMAIL' && this.googleUser?.idToken) {
      params['googleAuthToken'] = this.googleUser?.idToken;
    }
    if (this.productUUID) {
      params['productUUID'] = this.productUUID;
    }
    this.authService.mobileVerifyFirebase(params).subscribe((response: any) => {
      this.sentMobileOtp = false;
      this.otpVerifyLoader = false;
      this.uniqueUID = '';
      if (response.status == 'success') {
        if (response?.data) {
          this.common.setUserData(response.data);
          this.common.setToken(response.data.authToken);
          this.userData = response.data;
          this.jwtToken = response.data.authToken;
          this.sendGtagTokenOnLogin();
        }
        this.common.setItem('isToTakeVerifiedNo', response?.isToTakeVerifiedNo);
        this.common.setItem('hasLanguage', response?.hasLanguage);
        this.common.setItem('isToTakeVerifiedNo_firebase', response?.isToTakeVerifiedNo_firebase);
        this.common.setLoginValue(true);
        this.loginObj.mobile = '';
        this.fir = '';
        this.sec = '';
        this.thi = '';
        this.fou = '';
        this.fiv = '';
        this.six = '';
        this.isRegistered = response?.isRegistered;
        this.stopTimer();
        this.stopTimerForeign();
        this.googleMobileRequired = false;
        if (this.isRegistered) {
          this.gotOtp = 1;
          this.closeModal(false, this.gotOtp);
          if (this.productUUID) {
            this.redirectGuestUser();
          }
        } else {
          this.gotOtp = 3;
        }
      } else {
        this.sentMobileOtp = false;
        this.startTimerForeign(this.timeLeftForeign);
        this.successMessage = false;
        this.otpError = true;
        this.otpMismatchError = response?.reason ? response.reason : 'Something went wrong please try again!';
      }
    }, () => {
      this.sentMobileOtp = false;
      this.otpVerifyLoader = false;
    });
  }

  /*----------------------------------end firebase otp----------------------------------------*/

  focusEvent(ev, ngmod): any {
    const cursorNext = ev.srcElement.nextElementSibling;
    const cursorPrev = ev.srcElement.previousElementSibling;
    const cursorDefault = ev.srcElement.firstElementChild;
    if (ngmod) {
      if (ngmod?.length == 1 && ngmod != this.fou && this.loginObj.countryCode == '+91') {
        cursorNext?.focus();
      }
      if (ngmod?.length == 1 && ngmod != this.six && this.loginObj.countryCode != '+91' && !this.isTwilioActive) {
        cursorNext?.focus();
      }
      if (ngmod?.length == 1 && ngmod != this.fou && this.loginObj.countryCode != '+91' && this.isTwilioActive) {
        cursorNext?.focus();
      }
      if ((ev.keyCode == 8 || ev.keyCode == 12 || ev.keyCode == 46) && ngmod != this.fir) {
        cursorPrev?.focus();
      }
      if (this.fou && this.loginObj.countryCode == '+91') {
        this.submitOtp();
      }
      if (this.six && this.loginObj.countryCode != '+91') {
        this.submitOtp();
      }
    } else if (ngmod?.length == 0 && ev.keyCode == 8 && ngmod != this.fir) {
      cursorPrev?.focus();
    } else if (ngmod?.length == 0 && this.fir) {
      cursorDefault?.focus();
    } else if ((ev.keyCode == 8 || ev.keyCode == 12 || ev.keyCode == 46)) {
      cursorPrev?.focus();
    }
    if (this.loginObj.countryCode != '+91' && !this.isTwilioActive) {
      if (this.fir && this.sec && this.thi && this.fou && this.fiv && this.six) {
        this.otpSubmitButton = true;
      } else {
        this.otpSubmitButton = false;
      }
    } else if (this.loginObj.countryCode != '+91' && this.isTwilioActive) {
      if (this.fir && this.sec && this.thi && this.fou) {
        this.otpSubmitButton = true;
      } else {
        this.otpSubmitButton = false;
      }
    } else if (this.loginObj.countryCode == '+91') {
      if (this.fir && this.sec && this.thi && this.fou) {
        this.otpSubmitButton = true;
      } else {
        this.otpSubmitButton = false;
      }
    }
  }

  submitOtp() {
    this.otpError = false;
    if (this.loginObj.countryCode != '+91' && !this.isTwilioActive) {
      this.verifyOtpLoginViaFirebase();
    } else {
      if (this.fir && this.sec && this.thi && this.fou) {
        this.otpVerifyLoader = true;
        const otpData = this.fir + this.sec + this.thi + this.fou;
        const data = {
          appId: this.common.appId,
          businessId: this.common.getBusinessId(),
          countryCode: this.loginObj.countryCode.replace(/\+/g, ''),
          mobile: this.loginObj.mobile,
          otp: otpData,
          token: this.otpResponse.token,
          timeZone: encodeURIComponent(this.browserTimeZone),
          registerBy: 'WEB',
          version: '1.1',
          toi: (this.common.getItem('referrer') == 'TOI') ? true : false,
          hardwareId: this.common.getDeviceId()
        };
        if (this.common.getItem('partner')) {
          data['marketSource'] = this.common.getItem('partner');
        }

        if (this.loginFlow == 'EMAIL' && this.googleUser?.idToken) {
          data['googleAuthToken'] = this.googleUser?.idToken;
        }
        if (this.productUUID) {
          data['productUUID'] = this.productUUID;
        }
        this.profileService.submitOtp(data).subscribe((res: any) => {
          this.otpVerifyLoader = false;
          if (res.status == 'success') {
            this.pauseTimer();
            if (res?.data) {
              this.common.setUserData(res.data);
              this.common.setToken(res.data.authToken);
              this.userData = res.data;
              this.jwtToken = res.data.authToken;
              this.sendGtagTokenOnLogin();
            }
            this.isRegistered = res.isRegistered;
            this.common.setItem('isToTakeVerifiedNo', res.isToTakeVerifiedNo);
            this.common.setItem('hasLanguage', res.hasLanguage);
            this.common.setItem('isToTakeVerifiedNo_firebase', res.isToTakeVerifiedNo_firebase);
            this.common.setLoginValue(true);
            this.loginObj.mobile = '';
            this.fir = '';
            this.sec = '';
            this.thi = '';
            this.fou = '';
            if (this.isBrowser) {
              this.messagingService.allowNotification(this.isLogin);
            }
            if (res?.isRegistered) {
              this.gotOtp = 1;
              this.closeModal(false, this.gotOtp);
              if (this.productUUID) {
                this.redirectGuestUser();
              }
            } else {
              this.gotOtp = 3;
            }
          } else {
            this.otpError = true;
            this.otpMismatchError = res?.reason;
          }
        }, () => {
          this.otpVerifyLoader = false;
        });
      }
    }
  }

  submitLocalData(): void {
    const data = {
      name: this.firstNameNewUser ? this.firstNameNewUser : 'User',
      userId: this.userData.id
    };
    this.authService.updateUserProfile(data).subscribe((res: any) => {
      this.toastr.success('Updated Successfully');
      this.userData.name = res.data.name;
      this.triggerEventNewUser(this.userData.name, this.isRegistered, this.loginFlow == 'EMAIL' ? 'email' :
        ((this.loginObj.countryCode != '+91' && !this.isTwilioActive) ? 'firebase' : (this.truecaller ? 'truecaller' : 'phonnumber')));
      this.common.setUserData(this.userData);
      if (this.productUUID) {
        this.redirectGuestUser();
      } else {
        window.location.reload();
      }
    });
  }

  backToPhone(): void {
    this.gotOtp = 1;
    this.fir = '';
    this.sec = '';
    this.thi = '';
    this.fou = '';
    this.fiv = '';
    this.six = '';
    this.stopTimer();
    this.stopTimerForeign();
  }

  selectedCountry() {
    this.loginObj.countryCode = this.country.dial_code;
  }

  resendVerifyMobileOtpLogin(isCalls = false) {
    this.otpError = false;
    this.otpMismatchError = '';
    this.successMessage = false;
    if (this.resendCount) {
      if (!isCalls) {
        this.resentMobile = true;
      } else {
        this.resentMobileCall = true;
      }
      if (this.loginObj.countryCode != '+91' && !this.isTwilioActive) {
        this.verifyOtpLoginViaFirebase();
      } else {
        this.pauseTimer();
        this.otpError = false;
        if (this.checkCaptcha == 'true' || this.checkCaptcha == true) {
          this.captchaSolve(isCalls, 1);
        } else {
          this.resendOtpNow(isCalls);
        }
      }
    }
    this.resendCount--;
  }

  back(): void {
    this.location.back();
  }

  languageChange = (lang = '') => {
    const firstSlash = this.pageName.split('/');
    if (firstSlash[1] == 'todays-love-horoscope' && lang != '') {
      if (firstSlash?.length > 2 && lang == 'hi') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/aaj-ka-love-rashifal/' + hindiEnglishNames],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && lang == 'kn') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/indina-preeti-rashi-bhavishya/' + hindiEnglishNames],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/aaj-ka-love-rashifal'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'aaj-ka-love-rashifal' && lang != 'hi') {
      if (firstSlash?.length > 2 && lang == 'kn') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/indina-preeti-rashi-bhavishya/' + hindiEnglishNames],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && lang == '') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/todays-love-horoscope/' + hindiEnglishNames],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/todays-love-horoscope'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'indina-preeti-rashi-bhavishya' && lang != 'kn/') {
      if (firstSlash?.length > 2 && lang == 'hi') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/aaj-ka-love-rashifal/' + hindiEnglishNames],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && lang == '') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/todays-love-horoscope/' + hindiEnglishNames],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/indina-preeti-rashi-bhavishya'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'horoscope-2022' && lang != '') {
      if (firstSlash?.length > 2 && lang == 'hi') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal-2022/' + hindiEnglishNames + '-rashifal-2022'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && lang == 'kn') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/varshika-rashi-bhavishya-2022/' + hindiEnglishNames + '-rashi-bhavishya-2022'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/rashifal-2022'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'rashifal-2022' && lang != '') {
      if (firstSlash?.length > 2 && (lang == '/' || lang == '')) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal-2022/' + hindiEnglishNames + '-rashifal-2022'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && lang == 'kn') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/varshika-rashi-bhavishya-2022/' + hindiEnglishNames + '-rashi-bhavishya-2022'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2022'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'varshika-rashi-bhavishya-2022' && lang != '') {
      if (firstSlash?.length > 2 && lang == 'hi') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal-2022/' + hindiEnglishNames + '-rashifal-2022'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && (lang == '/' || lang == '')) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/horoscope-2022/' + hindiEnglishNames + '-horoscope-2022'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2022'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'rashifal-2022' && lang == '') {
      if (firstSlash?.length > 2) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate(['horoscope-2022/' + hindiEnglishNames + '-horoscope-2022'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2022'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'varshika-rashi-bhavishya-2022' && lang == '') {
      if (firstSlash?.length > 2) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate(['horoscope-2022/' + hindiEnglishNames + '-horoscope-2022'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2022'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'horoscope-2023' && lang != '') {
      if (firstSlash?.length > 2 && lang == 'hi') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal-2023/' + hindiEnglishNames + '-rashifal-2023'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && lang == 'kn') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/varshika-rashi-bhavishya-2023/' + hindiEnglishNames + '-rashi-bhavishya-2023'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/rashifal-2023'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'rashifal-2023' && lang != '') {
      if (firstSlash?.length > 2 && (lang == '/' || lang == '')) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal-2023/' + hindiEnglishNames + '-rashifal-2023'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && lang == 'kn') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/varshika-rashi-bhavishya-2023/' + hindiEnglishNames + '-rashi-bhavishya-2023'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2023'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'varshika-rashi-bhavishya-2023' && lang != '') {
      if (firstSlash?.length > 2 && lang == 'hi') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal-2023/' + hindiEnglishNames + '-rashifal-2023'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && (lang == '/' || lang == '')) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/horoscope-2023/' + hindiEnglishNames + '-horoscope-2023'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2023'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'rashifal-2023' && lang == '') {
      if (firstSlash?.length > 2) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate(['horoscope-2023/' + hindiEnglishNames + '-horoscope-2023'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2023'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'varshika-rashi-bhavishya-2023' && lang == '') {
      if (firstSlash?.length > 2) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate(['horoscope-2023/' + hindiEnglishNames + '-horoscope-2023'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2023'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'horoscope-2024' && lang != '') {
      if (firstSlash?.length > 2 && lang == 'hi') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal-2024/' + hindiEnglishNames + '-rashifal-2024'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && lang == 'kn') {
        const hindiEnglishNames = this.common.getHoroscopeName('/', firstSlash[2].split('-')[0]);
        this.router.navigate(['/horoscope-2024/' + hindiEnglishNames + '-horoscope-2024'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/rashifal-2024'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'rashifal-2024' && lang != '') {
      if (firstSlash?.length > 2 && (lang == '/' || lang == '')) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal-2024/' + hindiEnglishNames + '-rashifal-2024'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && lang == 'kn') {
        const hindiEnglishNames = this.common.getHoroscopeName('/', firstSlash[2].split('-')[0]);
        this.router.navigate(['/horoscope-2024/' + hindiEnglishNames + '-horoscope-2024'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2024'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'varshika-rashi-bhavishya-2024' && lang != '') {
      if (firstSlash?.length > 2 && lang == 'hi') {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal-2024/' + hindiEnglishNames + '-rashifal-2024'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 2 && (lang == '/' || lang == '')) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/horoscope-2024/' + hindiEnglishNames + '-horoscope-2024'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2024'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'rashifal-2024' && lang == '') {
      if (firstSlash?.length > 2) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate(['horoscope-2024/' + hindiEnglishNames + '-horoscope-2024'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2024'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'varshika-rashi-bhavishya-2024' && lang == '') {
      if (firstSlash?.length > 2) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate(['horoscope-2024/' + hindiEnglishNames + '-horoscope-2024'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope-2024'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'horoscope' && lang == 'hi') {
      if (firstSlash?.length == 3) {
        const allLanguageNames = this.common.getDailyName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal/' + allLanguageNames + '-rashifal'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 3) {
        const allLanguageNames = this.common.getDailyName(lang, firstSlash[2]);
        const rashiNamesAllLanguage = this.common.getHoroscopeName(lang, firstSlash[3]);
        this.router.navigate([lang + '/rashifal/' + allLanguageNames + '-rashifal/' + rashiNamesAllLanguage],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/rashifal'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'horoscope' && (lang == 'kn')) {
      if (firstSlash?.length == 3) {
        const allLanguageNames = this.common.getDailyName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashi-bhavishya/' + allLanguageNames + '-rashi-bhavishya'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 3) {
        const allLanguageNames = this.common.getDailyName(lang, firstSlash[2]);
        const rashiNamesAllLanguage = this.common.getHoroscopeName(lang, firstSlash[3]);
        this.router.navigate([lang + '/rashi-bhavishya/' + allLanguageNames + '-rashi-bhavishya/' + rashiNamesAllLanguage],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/rashi-bhavishya'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'rashifal' && (lang == '' || lang == 'en')) {
      if (firstSlash?.length == 3) {
        let key = firstSlash[2].split('-')[0];
        if (key == 'aaj') {
          key = 'aaj-ka';
        }
        const allLanguageNames = this.common.getDailyName(lang, key);
        this.router.navigate(['horoscope/' + allLanguageNames + '-horoscope'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 3) {
        let key = firstSlash[2].split('-')[0];
        if (key == 'aaj') {
          key = 'aaj-ka';
        }
        const allLanguageNames = this.common.getDailyName(lang, key);
        const rashiNamesAllLanguage = this.common.getHoroscopeName(lang, firstSlash[3]);
        this.router.navigate(['horoscope/' + allLanguageNames + '-horoscope/' + rashiNamesAllLanguage],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'rashifal' && lang == 'kn') {
      if (firstSlash?.length == 3) {
        let key = firstSlash[2].split('-')[0];
        if (key == 'aaj') {
          key = 'aaj-ka';
        }
        const allLanguageNames = this.common.getDailyName(lang, key);
        this.router.navigate([lang + '/rashi-bhavishya/' + allLanguageNames + '-rashi-bhavishya'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 3) {
        let key = firstSlash[2].split('-')[0];
        if (key == 'aaj') {
          key = 'aaj-ka';
        }
        const allLanguageNames = this.common.getDailyName(lang, key);
        const rashiNamesAllLanguage = this.common.getHoroscopeName(lang, firstSlash[3]);
        this.router.navigate([lang + '/rashi-bhavishya/' + allLanguageNames + '-rashi-bhavishya/' + rashiNamesAllLanguage],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/rashi-bhavishya'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'rashi-bhavishya' && (lang == '' || lang == 'en')) {
      if (firstSlash?.length == 3) {
        const allLanguageNames = this.common.getDailyName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate(['horoscope/' + allLanguageNames + '-horoscope'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 3) {
        const allLanguageNames = this.common.getDailyName(lang, firstSlash[2].split('-')[0]);
        const rashiNamesAllLanguage = this.common.getHoroscopeName(lang, firstSlash[3]);
        this.router.navigate(['horoscope/' + allLanguageNames + '-horoscope/' + rashiNamesAllLanguage],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate(['horoscope'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'rashi-bhavishya' && lang == 'hi') {
      if (firstSlash?.length == 3) {
        const allLanguageNames = this.common.getDailyName(lang, firstSlash[2].split('-')[0]);
        this.router.navigate([lang + '/rashifal/' + allLanguageNames + '-rashifal'],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 3) {
        const allLanguageNames = this.common.getDailyName(lang, firstSlash[2].split('-')[0]);
        const rashiNamesAllLanguage = this.common.getHoroscopeName(lang, firstSlash[3]);
        this.router.navigate([lang + '/rashifal/' + allLanguageNames + '-rashifal/' + rashiNamesAllLanguage],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/rashifal'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'compatibility' && lang != '') {
      if (firstSlash?.length == 3) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2]);
        this.router.navigate([lang + '/compatibility/' + hindiEnglishNames],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 3) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2]);
        const hindiEnglishNamesOther = this.common.getHoroscopeName(lang, firstSlash[3]);
        this.router.navigate([lang + '/compatibility/' + hindiEnglishNames + '/' + hindiEnglishNamesOther],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/compatibility'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'compatibility' && lang == '') {
      if (firstSlash?.length == 3) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2]);
        this.router.navigate([lang + '/compatibility/' + hindiEnglishNames],
          {queryParams: this.pageParams});
      } else if (firstSlash?.length > 3) {
        const hindiEnglishNames = this.common.getHoroscopeName(lang, firstSlash[2]);
        const hindiEnglishNamesOther = this.common.getHoroscopeName(lang, firstSlash[3]);
        this.router.navigate(['compatibility/' + hindiEnglishNames + '/' + hindiEnglishNamesOther],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/compatibility'], {queryParams: this.pageParams});
      }
    } else if ((firstSlash[1] == 'numerology-introduction') && lang == 'kn' && firstSlash?.length > 2) {
      const hindiEnglishNames = this.common.getNumeroType(lang, firstSlash[2]);
      this.router.navigate([lang + '/sankhyashastra-numerology-in-kannada/' + hindiEnglishNames],
        {queryParams: this.pageParams});
    } else if (firstSlash[1] == 'numerology-introduction' && lang == 'hi' && firstSlash?.length > 2) {
      const hindiEnglishNames = this.common.getNumeroType(lang, firstSlash[2]);
      this.router.navigate([lang + '/numerology-in-hindi/' + hindiEnglishNames],
        {queryParams: this.pageParams});
    } else if (firstSlash[1] == 'sankhyashastra-numerology-in-kannada' && (lang == '' || lang == '/' || lang == 'en')
      && firstSlash?.length > 2) {
      const hindiEnglishNames = this.common.getNumeroType(lang, firstSlash[2]);
      this.router.navigate([lang + '/numerology-introduction/' + hindiEnglishNames],
        {queryParams: this.pageParams});
    } else if (firstSlash[1] == 'sankhyashastra-numerology-in-kannada' && lang == 'hi' && firstSlash?.length > 2) {
      const hindiEnglishNames = this.common.getNumeroType(lang, firstSlash[2]);
      this.router.navigate([lang + '/numerology-in-hindi/' + hindiEnglishNames],
        {queryParams: this.pageParams});
    } else if (firstSlash[1] == 'numerology-in-hindi' && lang == 'kn' && firstSlash?.length > 2) {
      const hindiEnglishNames = this.common.getNumeroType(lang, firstSlash[2]);
      this.router.navigate([lang + '/sankhyashastra-numerology-in-kannada/' + hindiEnglishNames],
        {queryParams: this.pageParams});
    } else if (firstSlash[1] == 'numerology-in-hindi' && (lang == '' || lang == '/' || lang == 'en') && firstSlash?.length > 2) {
      const hindiEnglishNames = this.common.getNumeroType(lang, firstSlash[2]);
      this.router.navigate([lang + '/numerology-introduction/' + hindiEnglishNames],
        {queryParams: this.pageParams});
    } else if ((firstSlash[1] == 'vastu-shastra') && lang == 'hi' && firstSlash?.length > 2) {
      const hindiEnglishNames = this.common.getVastuName(lang, firstSlash[2]);
      this.router.navigate([lang + '/vastu-shastra/' + hindiEnglishNames],
        {queryParams: this.pageParams});
    } else if ((firstSlash[1] == 'vastu-shastra') && lang != 'hi' && firstSlash?.length > 2) {
      const hindiEnglishNames = this.common.getVastuName(lang, firstSlash[2]);
      this.router.navigate([lang + '/vastu-shastra/' + hindiEnglishNames],
        {queryParams: this.pageParams});
    } else if (firstSlash[1] == 'zodiac-signs' && lang != '') {
      if (firstSlash?.length == 3) {
        this.router.navigate([lang + '/zodiac-signs/' + firstSlash[2] + '-in-hindi'],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/zodiac-signs'], {queryParams: this.pageParams});
      }
    } else if (firstSlash[1] == 'zodiac-signs' && lang == '') {
      if (firstSlash?.length == 3 && firstSlash[2].includes('-in-hindi')) {
        const ret = firstSlash[2].replace('-in-hindi', '');
        this.router.navigate([lang + '/zodiac-signs/' + ret],
          {queryParams: this.pageParams});
      } else {
        this.router.navigate([lang + '/zodiac-signs'], {queryParams: this.pageParams});
      }
    } else {
      this.router.navigate([lang + '/' + this.pageName], {queryParams: this.pageParams});
    }
  }

  mobileNumberValidation(mobile, event) {
    if (mobile?.length == 0 && event.key == '0') {
      this.mobileFirstZero = true;
      this.mobileFirstZeroMsg = 'Please don\'t enter 0 in the beginning';
      mobile.length = 0;
    } else {
      this.mobileFirstZero = false;
      this.mobileFirstZeroMsg = '';
    }
  }

  triggerEventNewUser(name, register, registeredPlatform = '') {
    console.log('New-User SignUp Triggered', name);
    if (this.common.isEnvironmentProd()) {
      if (register == false) {
        gtag('event', 'Sign_up', {
          app_name: 'Astrotalk',
          screen_name: 'New User SignUp',
          hitType: 'event',
          name: name ? name : '',
          type: registeredPlatform
        });
        fbq('track', 'Sign_up', {
          app_name: 'Astrotalk',
          screen_name: 'New User SignUp',
          name: name ? name : '',
          type: registeredPlatform
        });
      }
    }
  }

  async getTimeZoneByApi() {
    const isForeign = this.common.getUserData('isForeign');
    await this.common.setApiTimeZone(isForeign);
  }

  async getRateOfConvirsion() {
    const response = await <any>this.common.rateOfConversion({
      appId: this.common.getAppId(),
      timezone: encodeURIComponent(this.browserTimeZone)
    }, true).toPromise();
    if (response?.status == 'success') {
      this.common.setWithExpiry('conRate', response.data.currencyDto, '86400');
      if (response.data.isForeign) {
        this.common.setItem('timeZone', 'Foreign');
        this.common.setItem('multipleCurrencyEnabled', response.data.multipleCurrencyEnabled);
      } else {
        this.common.setItem('timeZone', 'Asia/Kolkata');
        this.common.setItem('multipleCurrencyEnabled', 'false');
      }
    }
  }

  openLogin = () => {
    if ($('#loginButton').length > 0) {
      document.getElementById('loginButton').click();
    } else if ($('#guestUserLogin').length > 0) {
      document.getElementById('guestUserLogin').click();
    }
  }

  checkValueForTrueCaller() {
    this.common.getStaticValueForLogin(203).subscribe((res: any) => {
      if (res.status == 'success') {
        this.truecaller = (res.value == 'true') ? true : false;
      }
    });
  }

  openTrueCaller() {
    if (this.isBrowser) {
      this.common.showFullPageLoader();
      const d = new Date();
      const reqNonce = Math.floor(100000000 + (Math.random() * 900000000) + d.getTime()); // random number of length 8 to 64 characters
      window.location = 'truecallersdk://truesdk/web_verify?requestNonce=' + reqNonce + '&partnerKey=' + AppConstant.trueCallerKey + '&partnerName=' + AppConstant.trueCallerName + '&lang=en&privacyUrl=https://astrotalk.com/privacy-policies &termsUrl=https://astrotalk.com/terms-and-conditions&loginPrefix=continue&loginSuffix=login&ctaPrefix=continuewith&ctaColor=%23f0df20&ctaTextColor=%23212529&btnShape=round&skipOption=useanothernum';
      setTimeout(() => {
        this.common.hideFullPageLoader();
        if (document.hasFocus()) {
          this.truecaller = false;
          this.openLogin();
        } else {
          this.perSecondLogin(reqNonce);
        }
      }, 2000);
    }
  }

  perSecondLogin(token) {
    this.callBackCount = 0;
    this.common.showFullPageLoader();
    this.trueCallerSubscription = interval(3000).subscribe((x => {
      if (this.callBackCount <= 5) {
        const params = {
          requestId: token,
          businessId: this.common.getBusinessId(),
          appId: this.common.getAppId(),
          version: '1.0.0',
          registerBy: 'WEB',
          timezone: encodeURIComponent(this.browserTimeZone),
          toi: (this.common.getItem('referrer') == 'TOI') ? true : false
        };
        if (this.common.getItem('partner')) {
          params['marketSource'] = this.common.getItem('partner');
        }
        if (this.productUUID) {
          params['productUUID'] = this.productUUID;
        }
        this.profileService.loginTrueCaller(params).subscribe((response: any) => {
          if (response?.status == 'success') {
            if (response.message == 'try again') {
              this.callBackCount++;
            } else {
              if (response?.data) {
                this.common.setUserData(response.data);
                this.common.setToken(response.data.authToken);
                this.userData = response.data;
                this.jwtToken = response.data.authToken;
                this.sendGtagTokenOnLogin();
              }
              this.common.setItem('isToTakeVerifiedNo', response.isToTakeVerifiedNo);
              this.common.setItem('hasLanguage', response.hasLanguage);
              this.common.setItem('isToTakeVerifiedNo_firebase', response.isToTakeVerifiedNo_firebase);
              this.common.setLoginValue(true);
              if (this.productUUID) {
                this.redirectGuestUser();
              }
              document.getElementById('sideMenuButton').click();
              this.trueCallerSubscription.unsubscribe();
              this.common.hideFullPageLoader();
            }
          } else {
            this.truecaller = false;
            this.openLogin();
            this.trueCallerSubscription.unsubscribe();
            this.common.hideFullPageLoader();
          }
        }, () => {
          this.truecaller = false;
          this.openLogin();
          this.trueCallerSubscription.unsubscribe();
          this.common.hideFullPageLoader();
        });
      } else {
        this.truecaller = false;
        this.openLogin();
        this.trueCallerSubscription.unsubscribe();
        this.common.hideFullPageLoader();
      }
    }));
  }

  loginTrueCaller() {
    const trueCallerFormData = {
      appId: this.common.getAppId(),
      businessId: this.common.getBusinessId(),
      countryCode: this.callback.countrycode,
      mobile: this.callback.phoneNumber,
      registerBy: 'WEB',
      payload: this.callback.payload,
      signatureAlgorithm: this.callback.signatureAlgorithm,
      signedString: this.callback.signature,
      version: '',
      hardwareId: this.common.getDeviceId(),
      timeZone: this.common.timeZone(),
      gcmId: ''
    };
    this.profileService.trueCallerLoginMethod(trueCallerFormData).subscribe((res: any) => {
      this.loginFromTrueCallerData = res;
    }, err => {
      console.error(err);
    });
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return 'Windows';
    } else if (/android/i.test(userAgent)) {
      return 'Android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    } else {
      return 'browser';
    }
  }
  saveLogs() {
    this.uniqueUID = this.guid();
    const params = {
      appId: this.common.getAppId(),
      businessId: this.common.getBusinessId(),
      deviceId: null,
      type: 'FIREBASE',
      appVersion: this.common.getAppVersion(),
      uuid: this.uniqueUID,
    };
    this.profileService.saveLogsAlert(params).subscribe(() => {
    }, (err) => {
      console.error(err);
    });
  }

  guid = () => {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)?.toString(16)?.substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  loginFlow = 'MOBILE';

  isToLoginMobileOrGoogle() {
    const body = {
      fcmId: '',
      businessId: this.common.getBusinessId(),
      timezone: this.common.timeZone(),
      appId: this.common.getAppId(),
      deviceId: null,
      gpsAdId: ''
    };
    this.authService.isToLoginWith(body).subscribe((res: any) => {
      if (res.status == 'success') {
        if (res.userLoginMode == 'EMAIL') {
          this.loginFlow = 'EMAIL';
          this.isToUseCaptcha();
          this.googleMobileRequired = false;
          this.modalService.open(this.loginModal, {
            windowClass: 'display_flex mobile_number_registration_popup',
            backdrop: 'static',
          });
          this.renderCaptcha('firebase-otp-recaptcha');
        } else {
          this.loginFlow = 'MOBILE';
          this.useMobileFlow();
        }
      } else {
        this.useMobileFlow();
      }
    }, (err) => {
      console.error('loginErr', err);
      this.useMobileFlow();
    });
  }

  useMobileFlow() {
    if (this.truecaller && this.pageName != '/live') {
      this.openTrueCaller();
    } else {
      this.otpSentErrorMsg = '';
      this.isToUseCaptcha();
      this.modalService.open(this.loginModal, {
        windowClass: 'display_flex mobile_number_registration_popup',
        backdrop: 'static',
      });
      this.renderCaptcha('firebase-otp-recaptcha');
    }
  }

  async newCaptchaSolve() {
    return new Promise<string>((resolve, reject) => {
      this.recaptchaV3Service.execute('6LcOcMoZAAAAACytcWRqWrl13cVOXjAx2JEG_QHs').subscribe((token) => {
        if (token) {
          resolve(token);
        } else {
          this.common.toastrInvoke('error', 'Please resolve the captcha');
          reject('No reCAPTCHA token available');
        }
      }, (error) => {
        reject(error);
      });
    });
  }

  signInWithGoogle() {
    this.authSocialService.signIn(GoogleLoginProvider.PROVIDER_ID, {prompt: 'select_account'}).then((user) => {
      this.googleUser = user;
      if (this.checkCaptcha) {
        this.newCaptchaSolve().then((token) => {
          this.googleLoginApi(token);
        });
      } else {
        this.googleLoginApi();
      }
    }).catch((error) => {
      if (error.error === 'popup_closed_by_user') {
        console.error('Login popup was closed by the user' + 'ppp' + JSON.stringify(error));
      } else {
        console.error('Error during login:', error);
      }
    });
  }

  googleLoginApi(token = '', mobile = '', country = '') {
    this.googleLoader = true;
    this.googleLoginError = false;
    const googleData = {
      appId: this.common.getAppId(),
      businessId: this.common.getBusinessId(),
      googleAuthToken: this.googleUser?.idToken,
      googleId: this.googleUser?.id,
      captchaEnterPrise: token ? token : '',
      userLoginMode: 'EMAIL',
      name: this.googleUser?.name,
      email: this.googleUser?.email,
      profile_pic: this.googleUser?.photoUrl,
      timeZone: encodeURIComponent(this.browserTimeZone),
      appVersion: this.common.getAppVersion(),
      gcmId: '',
      toi: (this.common.getItem('referrer') == 'TOI') ? true : false,
    };
    if (mobile && country) {
      googleData['mobile'] = mobile;
      googleData['countryCode'] = encodeURIComponent(country);
    }

    if (this.productUUID) {
      googleData['productUUID'] = this.productUUID;
    }

    this.authService.loginWithGoogleApi(googleData).subscribe((res: any) => {
      this.googleLoader = false;
      if (res.status == 'success') {
        if (res.isOtpRequired) {
          if (this.loginObj.countryCode != '+91') {
            this.twilioCheckForGoogleLogin();
          } else {
            this.otpResponse = res;
            const giveOtp = document.getElementById('giveOtp');
            this.gotOtp = 2;
            this.startTimer(this.timeLeft);
            setTimeout(() => {
              giveOtp.focus();
            }, 100);
          }
        } else {
          if (res?.data) {
            this.common.setUserData(res.data);
            this.common.setToken(res.data.authToken);
            this.userData = res.data;
            this.jwtToken = res.data.authToken;
            this.sendGtagTokenOnLogin();
          }
          this.isRegistered = res.isRegistered;
          this.common.setItem('isToTakeVerifiedNo', res.isToTakeVerifiedNo);
          this.common.setItem('hasLanguage', res.hasLanguage);
          this.common.setItem('isToTakeVerifiedNo_firebase', res.isToTakeVerifiedNo_firebase);
          this.common.setLoginValue(true);
          this.loginObj.mobile = '';
          if (this.isRegistered) {
            this.gotOtp = 1;
            this.closeModal(false, this.gotOtp);
            if (this.productUUID) {
              this.redirectGuestUser();
            }
          } else {
            this.gotOtp = 3;
          }
        }
      } else if (res.status == 'fail' && res.isOtpRequired && res.isFireBaseRequired) {
        this.sendOtpLoginViaFirebase(this.loginObj.countryCode + this.loginObj.mobile);
      } else if (res.status == 'fail' && res.isMobileRequired) {
        this.googleMobileRequired = true;
      } else {
        this.googleLoginErrorMsg = res.reason;
        this.googleLoginError = true;
      }
    }, (error) => {
      this.googleLoader = false;
      this.googleLoginError = true;
      this.googleLoginErrorMsg = error.reason;
    });
  }

  signOut(): void {
    this.authSocialService.signOut();
  }

  refreshToken(): void {
    this.authSocialService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  mobileNoGoogleLogin = (form: NgForm) => {
    if (form.valid) {
      this.googleLoginError = false;
      this.googleLoader = true;
      if (this.checkCaptcha) {
        this.newCaptchaSolve().then((token) => {
          this.googleLoginApi(token, this.loginObj.mobile, this.loginObj.countryCode);
        });
      } else {
        this.googleLoginApi('', this.loginObj.mobile, this.loginObj.countryCode);
      }
    } else {
      this.otpLoader = false;
      this.otpSentError = true;
      this.otpSentErrorMsg = 'Enter valid mobile number';
    }
  }

  twilioCheckForGoogleLogin() {
    this.googleLoader = true;
    const data = {
      countryCode: encodeURIComponent(this.loginObj.countryCode),
      appId: this.common.getAppId(),
      businessId: this.common.getBusinessId(),
      mobile: this.loginObj.mobile,
    };
    this.profileService.twilioCheck(data).subscribe((res: any) => {
      this.googleLoader = false;
      if (res.status == 'success') {
        this.isTwilioActive = res.isTwilioActive;
        if (res.isTwilioActive) {
          this.otpSeconds = true;
          const giveOtp = document.getElementById('giveOtp');
          this.gotOtp = 2;
          this.startTimer(this.timeLeft);
          this.otpResponse = res;
          setTimeout(() => {
            giveOtp.focus();
          }, 100);
        } else {
          this.otpSeconds = false;
          this.sendOtpLoginViaFirebase(this.loginObj.countryCode + this.loginObj.mobile);
        }
      } else {
        this.common.toastrInvoke('error', res?.reason ? res.reason : 'Something went wrong please try again!');
      }
    }, () => {
      this.googleLoader = false;
      console.error('twilio error');
    });
  }

  redirectGuestUser() {
    this.common.removeItem('guestUser');
    this.guestUser = false;
    if (this.pageName.includes('e-pooja/booking-details') || this.pageName.includes('book-a-pooja/booking-details')) {
      this.router.navigate([this.langStatus + 'order-history/astro-mall']);
    }
  }

  routeToProfile() {
    if (!this.guestUser) {
      this.router.navigate(['/' + this.langStatus + 'profile-settings']);
    }
  }

  reload() {
    if ((this.pageName.includes('e-pooja') || this.pageName.includes('book-a-pooja')) && this.guestUser) {
    } else {
      this.router.navigate([this.langStatus]);
    }
  }

  goBack() {
    this.location.back();
  }

  loginShaadiDotCom() {
    this.common.showFullPageLoader();
    const data = {
      appId: this.common.getAppId(),
      businessId: 1,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      externalToken: this.shaadiToken,
      appVersion: this.common.getAppVersion(),
    };
    this.profileService.loginWithShaadiDotCom(data).subscribe((response: any) => {
      this.common.hideFullPageLoader();
      if (response?.status == 'success') {
        if (response?.data) {
          this.common.setUserData(response.data);
          this.common.setToken(response.data.authToken);
          this.userData = response.data;
          this.jwtToken = response.data.authToken;
          this.isToShowPricePopUp = response.data?.isToShowRechargePopToShaadiUser;
          this.creditBonusPrice = response.data?.amountCreditedToShaadiUser;
        }
        this.common.setItem('isToTakeVerifiedNo', response.isToTakeVerifiedNo);
        this.common.setItem('hasLanguage', response.hasLanguage);
        this.common.setItem('isToTakeVerifiedNo_firebase', response.isToTakeVerifiedNo_firebase);
        this.common.setLoginValue(true);
        if (this.isToShowPricePopUp && this.creditBonusPrice > 0) {
          this.showBonusTemplate();
        }
      }
    }, () => {
      this.common.hideFullPageLoader();
    });
  }

  sendGtagTokenOnLogin() {
    gtag('get', 'G-NRVH59QRSM', 'client_id', (clientID) => {
      const data = {
        gtagClientId: clientID,
        userId: this.common.getUserData('id')
      };
      this.common.callGtagOnLogin(data).subscribe((res: any) => {
        if (res.status == 'success') {
          console.log('gtag clientID sent successfully');
        } else {
          console.error(res, 'response fail -> error sending gtag clientID');
        }
      }, error => {
        console.error(error, 'error sending gtag clientID');
      });
    });
  }

  hideElmt() {
    const element = document.getElementById('elementToHide');
    if (element) {
      element.style.display = 'none';
    }
  }

  showElmt() {
    const element = document.getElementById('elementToHide');
    if (element) {
      element.style.display = 'block';
    }
  }

  hideElmtProfile() {
    const element = document.getElementById('elementToHideProfile');
    if (element) {
      element.style.display = 'none';
    }
  }

  showElmtProfile() {
    const element = document.getElementById('elementToHideProfile');
    if (element) {
      element.style.display = 'block';
    }
  }

  hideElmtLang() {
    const element = document.getElementById('elementToHideLang');
    if (element) {
      element.style.display = 'none';
    }
  }

  showElmtLang() {
    const element = document.getElementById('elementToHideLang');
    if (element) {
      element.style.display = 'block';
    }
  }

  showBonusTemplate() {
    this.dialog.open(this.bonusTemplate, {
      minWidth: 400,
      disableClose: true,
      data: {
        heading: 'Congratulations',
        message: 'Welcome bonus of ',
        remainingMsg: ' has been credited to you account.'
      },
    });
  }
}
