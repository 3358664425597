import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {mergeMapTo} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpWrapperService} from '../global/common-file/http-wrapper.service';
import {AppConstant} from '../global/common-file/constant';
import {CommonService} from '../global/common-file/common.service';

@Injectable()
export class MessagingService {
  private fireBaseTokenApi = AppConstant.apiUrl + AppConstant.fireBaseToken;
  private fireBaseTokenApiNormal = AppConstant.apiUrl + AppConstant.fireBaseTokenNormal;

  currentMessage = new BehaviorSubject<any>(null);
  isLogin = false;

  constructor(private afMessaging: AngularFireMessaging,
              private http: HttpWrapperService,
              private common: CommonService) {
    this.receiveMessage();
  }

  receiveMessage() {
    this.afMessaging.messages.subscribe((payload) => {
      this.currentMessage.next(payload);
    });
  }

  allowNotification = (isLogin: boolean = false) => {
    this.isLogin = isLogin;
    let fireBaseToken = localStorage.getItem('notificationToken');
    if (!fireBaseToken) {
      this.requestPermission();
    } else {
      fireBaseToken = JSON.parse(fireBaseToken);
      if (fireBaseToken['isLogin'] == false && fireBaseToken['token'] && isLogin) {
        this.saveToken(fireBaseToken['token']);
      } else if (fireBaseToken['isLogin'] == false && !fireBaseToken['token'] && isLogin) {
        this.requestPermission();
      }
    }
  }

  requestPermission() {
    this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges)).subscribe((token) => {
      this.saveToken(token);
    }, (error) => {
      console.error(error);
      return '';
    });
  }

  saveToken = (tokens) => {
    if (this.isLogin) {
      this.saveTokenToServer({
        userId: this.common.getUserData('id'),
        webtoken: tokens
      }).subscribe((response: any) => {
        if (response.status == 'success') {
          const fireBaseToken = {
            token: tokens,
            isLogin: true
          };
          localStorage.setItem('notificationToken', JSON.stringify(fireBaseToken));
        }
      });
    } else {
      this.saveTokenToServerWithoutLogin({
        webToken: tokens
      }).subscribe((response: any) => {
        if (response.status == 'success') {
          const fireBaseToken = {
            token: tokens,
            isLogin: false
          };
          localStorage.setItem('notificationToken', JSON.stringify(fireBaseToken));
        }
      });
    }
  }

  saveTokenToServer = (params) => {
    return this.http.putParamRequest(this.fireBaseTokenApi, params, true);
  }

  saveTokenToServerWithoutLogin = (params) => {
    return this.http.postParams(this.fireBaseTokenApiNormal, params);
  }

  getNotification(): Observable<boolean> {
    return this.currentMessage.asObservable();
  }

  getToken() {
    this.afMessaging.getToken.subscribe((token) => {
    }, (error) => {
      console.error(error);
    });
  }
}
