<section class="nav_head head_section desktopHeader">
  <div class="container">
    <div class="row_header_desktop">
      <div class="left_side_header_desktop">
        <div class="navbar-brand custom_brand" (click)="reload()">
          <div class="logo_img">
            <img height="51" width="247" src="https://aws.astrotalk.com/assets/images/logo_with-shadi-com.webp"
                 alt="logo">
          </div>
        </div>
      </div>
      <div class="right_side_header_desktop">
        <div class="flex_use_with_justify buttons">
          <div class="navLinks" *ngIf="!guestUser">
            <a class="nav-link" routerLink="{{langStatus}}freekundli">{{'Free Kundli' | translate}}</a>
          </div>
          <div class="navLinks" *ngIf="!guestUser">
            <a *ngIf="langStatus != 'hi/'" class="nav-link"
               routerLink="{{langStatus}}matchmaking">{{'Kundli Matching' | translate}}</a>
            <a *ngIf="langStatus == 'hi/'" class="nav-link"
               routerLink="{{langStatus}}kundli-milan-matching">{{'Kundli Matching' | translate}}</a>
          </div>
          <div class="userProfile" *ngIf="!guestUser">
            <div class="dropdown" ngbDropdown (mouseleave)="hideElmt()">
              <div class="navLinks" (mouseenter)="showElmt()">
                <span class="nav-link dropdown-toggle">{{'Horoscopes' | translate}}</span>
              </div>
              <div class="display_set_dropdwn" id="elementToHide" (mouseenter)="showElmt()"
                   *ngIf="langStatus == '/' || langStatus == 'en/'">
                <ul ngbDropdownMenu class="dropdown-menu user_profile_dropdown width_special_set">
                  <li><a routerLink="/{{langStatus}}horoscope-2024/" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Horoscope' | translate}} 2024</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}horoscope-2023/" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Horoscope' | translate}} 2023</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}horoscope/todays-horoscope" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Today\'s Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}horoscope/weekly-horoscope" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Weekly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}horoscope/monthly-horoscope" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Monthly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}horoscope/yearly-horoscope" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Yearly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}horoscope/daily-horoscope" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Daily Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}freekundali" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Free Kundali' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}horoscope/tomorrow-horoscope" (click)="hideElmt()"
                         ngbDropdownItem class="dropdown-item padding_additional">{{'Tomorrow\'s Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}horoscope/yesterday-horoscope" (click)="hideElmt()"
                         ngbDropdownItem class="dropdown-item padding_additional">{{'Yesterday\'s Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}chinese-astrology" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Chinese Horoscope' | translate}}</a>
                  </li>
                </ul>
              </div>
              <div class="display_set_dropdwn" id="elementToHide" (mouseenter)="showElmt()"
                   *ngIf="langStatus == 'hi/'">
                <ul ngbDropdownMenu class="dropdown-menu user_profile_dropdown width_special_set">
                  <li><a routerLink="/{{langStatus}}rashifal-2024/" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Horoscope' | translate}} 2024</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal-2023/" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Horoscope' | translate}} 2023</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/aaj-ka-rashifal" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Today\'s Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/saptahik-rashifal" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Weekly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/masik-rashifal" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Monthly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/varshik-rashifal" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Yearly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/dainik-rashifal" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Daily Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/tomorrow-rashifal" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Tomorrow\'s' | translate}}
                    {{'Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/yesterday-rashifal" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Yesterday\'s' | translate}}
                    {{'Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}chinese-rashifal" (click)="hideElmt()" ngbDropdownItem
                         class="dropdown-item padding_additional">{{'Chinese Horoscope' | translate}}</a>
                  </li>
                </ul>
              </div>
              <div class="display_set_dropdwn" id="elementToHide" (mouseenter)="showElmt()"
                   *ngIf="langStatus == 'kn/'">
                <ul ngbDropdownMenu class="dropdown-menu user_profile_dropdown width_special_set">
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/indina-rashi-bhavishya" (click)="hideElmt()"
                         ngbDropdownItem class="dropdown-item padding_additional">
                    {{'Today\'s Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/vaara-rashi-bhavishya" (click)="hideElmt()"
                         ngbDropdownItem class="dropdown-item padding_additional">
                    {{'Weekly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/maasika-rashi-bhavishya" (click)="hideElmt()"
                         ngbDropdownItem class="dropdown-item padding_additional">
                    {{'Monthly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/varshika-rashi-bhavishya" (click)="hideElmt()"
                         ngbDropdownItem class="dropdown-item padding_additional">
                    {{'Yearly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/dainandina-rashi-bhavishya" (click)="hideElmt()"
                         ngbDropdownItem class="dropdown-item padding_additional">
                    {{'Daily Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/tomorrow-rashi-bhavishya" (click)="hideElmt()"
                         ngbDropdownItem class="dropdown-item padding_additional">
                    {{'Tomorrow\'s' | translate}} {{'Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/yesterday-rashi-bhavishya" (click)="hideElmt()"
                         ngbDropdownItem class="dropdown-item padding_additional">
                    {{'Yesterday\'s' | translate}} {{'Horoscope' | translate}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="chooseLang" *ngIf="!guestUser">
            <div class="langLabel">{{'language' | translate}}</div>
            <div class="langDropdown" (mouseenter)="showElmtLang()" tabindex="0">
              <span>{{langTextFormat}}</span>
              <ul class="dropdown" id="elementToHideLang">
                <li *ngIf="langTextFormat != 'हिंदी'"><span
                  (click)="languageChange('hi'); hideElmtLang()">हिंदी</span></li>
                <li *ngIf="langTextFormat != 'Eng'"><span (click)="languageChange(); hideElmtLang()">Eng</span></li>
                <li *ngIf="langTextFormat != 'ಕನ್ನಡ'"><span
                  (click)="languageChange('kn'); hideElmtLang()">ಕನ್ನಡ</span></li>
              </ul>
            </div>
          </div>
          <div class="userProfile user-profile-dropdown" *ngIf="isLogin && !guestUser">
            <div class="dropdown" ngbDropdown (mouseleave)="hideElmtProfile()">
              <div (mouseenter)="checkBalence(); showElmtProfile();"
                   class="profile_pic_astrologer_Web user_profile_btn">
                <img loading="lazy" [errorImage]="'https://aws.astrotalk.com/assets/images/astro-background-gray.webp'"
                     [defaultImage]="'https://aws.astrotalk.com/assets/images/lazy_loading.webp'"
                     [lazyLoad]="userData.profile_pic?userData.profile_pic:cdnDomain +
                      '/assets/images/profile_pic.webp'?userData.profile_pic?userData.profile_pic:cdnDomain +
                       '/assets/images/profile_pic.webp':cdnDomain + '/assets/images/profile_pic.webp'" alt="avatar">
              </div>
              <div class="display_set_dropdwn profile-list" id="elementToHideProfile"
                   (mouseenter)="showElmtProfile()">
                <ul ngbDropdownMenu class="dropdown-menu user_profile_dropdown width_special_set">
                  <li class="background_hover_white">
                    <a ngbDropdownItem (click)="routeToProfile(); hideElmtProfile()" class="bg_white_hover">
                      <div class="photo_user_profile">
                        <img [defaultImage]="'https://aws.astrotalk.com/assets/images/lazy_loading.webp'"
                             [errorImage]="'https://aws.astrotalk.com/assets/images/astro-background-gray.webp'"
                             [lazyLoad]="userData.profile_pic ? userData.profile_pic: cdnDomain +
                              '/assets/images/profile_pic.webp'" alt="avatar" loading="lazy">
                      </div>
                      <div class="name_user_profile">{{userData.name}}
                        <span *ngIf="userData?.lastName && userData.lastName != 'null'">{{userData.lastName}}</span>
                      </div>
                      <div class="email_user_profile" *ngIf="userData?.email">{{userData.email}}</div>
                      <div class="contact_number_user_profile" *ngIf="userData.verifiedCountryCode &&
                       userData.verifiedMobile">{{userData.verifiedCountryCode}} {{userData.verifiedMobile}}
                      </div>
                    </a>
                  </li>
                  <li><a ngbDropdownItem class="dropdown-item padding_additional" (click)="hideElmtProfile()"
                         routerLink="{{langStatus}}notification">{{'notification' | translate}}</a>
                  </li>
                  <li>
                    <a ngbDropdownItem class="dropdown-item padding_additional wallet-balance"
                       (click)="hideElmtProfile()"
                       routerLink="{{langStatus}}my-wallet">{{'Wallet Transactions' | translate}}
                      <span class="wallet_amount_dktp_view">{{balence | rateSign}}</span>
                    </a>
                  </li>
                  <li><a ngbDropdownItem class="dropdown-item padding_additional" (click)="hideElmtProfile()"
                         routerLink="{{langStatus}}order-history">
                    {{'order history' | translate}}</a></li>
                  <li><a ngbDropdownItem class="dropdown-item padding_additional" (click)="hideElmtProfile()"
                         routerLink="{{langStatus}}customer-support">{{'customer support chat' | translate}}</a></li>
                  <li><a ngbDropdownItem class="dropdown-item padding_additional"
                         (click)="logout(); hideElmtProfile()">{{'logout' | translate}}</a></li>
                  <li><a ngbDropdownItem class="dropdown-item padding_additional"
                         (click)="logoutConfirm(); hideElmtProfile()">{{'logout from other devices' | translate}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="contact_btn btnn btn align-self-center" *ngIf="!isLogin && !guestUser"
               (click)="openModel(loginViaMobile)">
            <div id="loginButton" class="login_btn_header">
              <i class="fa fa-user"></i>{{'Login' | translate}}
            </div>
          </div>
          <div class="contact_btn btnn btn align-self-center displayNone" *ngIf="guestUser || productUUID"
               (click)="openModel(loginViaMobile)">
            <div id="guestUserLogin" class="login_btn_header">
              <i class="fa fa-user"></i>{{'Verify Order' | translate}}
            </div>
          </div>
        </div>
        <nav class="navbar navbar-expand-lg navbar-light" *ngIf="!guestUser">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav custom_nav">
              <li class="nav-item">
                <a class="nav-link"
                   routerLink="{{langStatus}}chat-with-astrologer">{{'Chat with Astrologer' | translate}}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link"
                   routerLink="{{langStatus}}talk-to-astrologer">{{'Talk to Astrologer' | translate}}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="{{langStatus}}e-pooja">{{'Book a Pooja' | translate}}
                  <span class="newftrcolr"> {{'New' | translate}}</span></a>
              </li>
              <li class="nav-item">
                <a class="nav-link"
                   routerLink="{{langStatus}}shop">{{'Astromall' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="langStatus == '/' || langStatus == 'en/'">
                <a class="nav-link" href="https://astrotalk.com/astrology-blog/category/english">Blog</a>
              </li>
              <li class="nav-item" *ngIf="langStatus == 'hi/'">
                <a class="nav-link"
                   href="https://astrotalk.com/astrology-blog/category/hindi">{{'Blog' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="langStatus == 'kn/'">
                <a class="nav-link"
                   href="https://astrotalk.com/astrology-blog/category/kannada">{{'Blog' | translate}}</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</section>

<nav class="navbar navbar-expand-lg navbar-light header_section_mobile_view mobile_view_header">
  <div class="container">
    <div class="header_top_baar">
      <img *ngIf="pageName != '' && pageName != '/'" (click)="goBack()" class="back-arrow"
           src="https://astrotalk.s3.amazonaws.com/assets/images/noun-arrow-back.webp" alt="Back Arrow">
      <button aria-label="navbar" id="sideMenuButton" class="navbar-toggler custom_toggler" type="button"
              data-bs-toggle="modal" data-bs-target="#sideMenu"
              *ngIf="(pageName !== '/payment-success' && pageName !== '/payment-fail' && !isWebView && !guestUser)">
        <span *ngIf="isLogin" (click)="checkBalence()" class="navbar-toggler-icon"></span>
        <span *ngIf="!isLogin" class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-brand custom_brand" routerLink="{{langStatus}}"
           *ngIf="pageName !== '/payment-success' && pageName !== '/payment-fail' && !guestUser">
        <div class="logo_img">
          <img src="https://astrotalk.s3.amazonaws.com/assets/images/logo_with-shadi-com.webp" alt="Astrotalk"
               height="25" width="120">
        </div>
      </div>
      <div class="navbar-brand custom_brand"
           *ngIf="pageName == '/payment-success' || pageName == '/payment-fail' || guestUser">
        <div class="logo_img">
          <img src="https://astrotalk.s3.amazonaws.com/assets/images/logo_with-shadi-com.webp" alt="Astrotalk"
               height="25" width="120">
        </div>
      </div>
      <div class="header_buttons" *ngIf="pageName !== '/payment-success' && pageName !== '/payment-fail' && !guestUser">
        <div class="section_language" data-bs-toggle="modal" data-bs-target="#langModal">
          <img alt="select-language" width="38" height="38" class="img-fluid"
               src="https://d1gcna0o0ldu5v.cloudfront.net/fit-in/38x38/assets/images/language_icon.webp">
        </div>
        <!-- Modal language-->
        <div class="modal fade lanuage_modal_mobile" id="langModal" tabindex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body content_language_setting">
                <button type="button" class="btn-close close_language_popup" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                <h5>{{'Choose your language' | translate}}</h5>
                <div class="language_btns_mbl row">
                  <div class="eng_btn_mbl" [class.active]="switchLang == 'en' || switchLang == ''"
                       (click)="languageChange()" data-bs-dismiss="modal">
                    <h6>ENG</h6>
                    <h6>English</h6>
                  </div>
                  <div class="eng_btn_mbl" [class.active]="switchLang == 'hi'" (click)="languageChange('hi')"
                       data-bs-dismiss="modal">
                    <h6>हिंदी</h6>
                    <h6>Hindi</h6>
                  </div>
                  <div class="eng_btn_mbl" [class.active]="switchLang == 'kn'" (click)="languageChange('kn')"
                       data-bs-dismiss="modal">
                    <h6>ಕನ್ನಡ</h6>
                    <h6>Kannada</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a routerLink="{{langStatus}}customer-support" class="customer_support_icon" *ngIf="isLogin && !guestUser">
          <img alt="support_icon" width="25" height="25"
               src="https://d1gcna0o0ldu5v.cloudfront.net/fit-in/25x25/assets/images/mobile_modal_header/webp_images/support_icon.webp">
        </a>
        <div class="btn mobile-login-btn" *ngIf="!isLogin" (click)="openModel(loginViaMobile)">
          <div id="loginButton" class="login_btn_header"><i class="fa fa-user"></i>{{'Login' | translate}}</div>
        </div>
      </div>
      <a href="https://my.shaadi.com/my-shaadi?close=true">
        <img class="close-button" src="https://astrotalk.s3.amazonaws.com/assets/images/noun-close.webp" alt="Close"></a>
    </div>
    <div class="modal fade web_menu_list" id="sideMenu" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="user_profile_part" *ngIf="isLogin">
              <a routerLink="/{{langStatus}}profile-settings" class="user_profile_image" (click)="closeMenu()">
                <img loading="lazy" width="100" height="100" (error)="changeSource($event)" alt="astrologer_user"
                     [errorImage]="'https://aws.astrotalk.com/assets/images/astro-background-gray.webp'"
                     [defaultImage]="'https://aws.astrotalk.com/assets/images/lazy_loading.webp'"
                     [lazyLoad]="userData.profile_pic?userData.profile_pic:cdnDomain + '/assets/images/profile_pic.webp'"
                     [src]="userData.profile_pic?userData.profile_pic:cdnDomain + '/assets/images/profile_pic.webp'">
                <i class="fa fa-camera edit_profile_pic_icon" aria-hidden="true"></i>
              </a>
              <div class="user_profile_name">{{userData.name}}
                <span *ngIf="userData?.lastName && userData.lastName != 'null'">{{userData.lastName}}</span>
              </div>
              <div class="user_mobile_number" *ngIf="userData.verifiedCountryCode && userData.verifiedMobile">
                {{userData.verifiedCountryCode}} {{userData.verifiedMobile}}</div>
            </div>
            <div class="user_profile_part" *ngIf="!isLogin" (click)="closeMenu()">
              <div class="user_profile_image" (click)="openModel(loginViaMobile)">
                <img [lazyLoad]="'https://aws.astrotalk.com/assets/images/app_logo_pdf.webp'" alt="login" height="100"
                     [defaultImage]="'https://aws.astrotalk.com/assets/images/lazy_loading.webp'" loading="lazy"
                     width="100">
              </div>
            </div>
            <div class="navbar-nav mobile-scroll links_and_Details_menu">
              <a routerLink="{{langStatus}}freekundli" (click)="closeMenu()"
                 class="nav-link">{{'Free Kundli' | translate}}</a>
              <div class="dropdown">
                <span class="nav-link dropdown-toggle" id="consultDropdown" role="button" data-bs-toggle="dropdown"
                      aria-expanded="false">{{'consult astrologer' | translate}}
                </span>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a (click)="closeMenu()" class="dropdown-item" routerLink="{{langStatus}}chat-with-astrologer">
                    {{'Chat with Astrologer' | translate}}</a></li>
                  <li><a (click)="closeMenu()" class="dropdown-item" routerLink="{{langStatus}}talk-to-astrologer">
                    {{'Talk to Astrologer' | translate}}</a></li>
                </ul>
              </div>
              <div class="dropdown">
                <span class="nav-link dropdown-toggle" id="horoscopeDropdown" role="button" data-bs-toggle="dropdown"
                      aria-expanded="false">{{'Horoscopes' | translate}}</span>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown"
                    *ngIf="langStatus == '/' || langStatus == 'en/'">
                  <li><a routerLink="/horoscope-2024" (click)="closeMenu()" class="dropdown-item">Horoscope 2024</a>
                  </li>
                  <li><a routerLink="/horoscope-2023" (click)="closeMenu()" class="dropdown-item">Horoscope 2023</a>
                  </li>
                  <li><a routerLink="/horoscope/todays-horoscope" (click)="closeMenu()"
                         class="dropdown-item">Today's Horoscope</a>
                  </li>
                  <li><a routerLink="/todays-love-horoscope" (click)="closeMenu()"
                         class="dropdown-item">Today's Love Horoscope</a>
                  </li>
                  <li><a routerLink="/horoscope/weekly-horoscope" (click)="closeMenu()"
                         class="dropdown-item">Weekly Horoscope</a>
                  </li>
                  <li><a routerLink="/horoscope/monthly-horoscope" (click)="closeMenu()"
                         class="dropdown-item">Monthly Horoscope</a>
                  </li>
                  <li><a routerLink="/horoscope/yearly-horoscope" (click)="closeMenu()"
                         class="dropdown-item">Yearly Horoscope</a>
                  </li>
                  <li><a routerLink="/horoscope/tomorrow-horoscope" (click)="closeMenu()"
                         class="dropdown-item">Tomorrow's Horoscope</a>
                  </li>
                  <li><a routerLink="/horoscope/yesterday-horoscope" (click)="closeMenu()"
                         class="dropdown-item">Yesterday's Horoscope</a>
                  </li>
                  <li><a routerLink="chinese-astrology" (click)="closeMenu()"
                         class="dropdown-item">Chinese Horoscope</a>
                  </li>
                </ul>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="langStatus == 'hi/'">
                  <li><a routerLink="/{{langStatus}}rashifal-2024" (click)="closeMenu()"
                         class="dropdown-item">{{'Horoscope' | translate}} 2024</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal-2023" (click)="closeMenu()"
                         class="dropdown-item">{{'Horoscope' | translate}} 2023</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/aaj-ka-rashifal" (click)="closeMenu()"
                         class="dropdown-item">{{'Today\'s Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}aaj-ka-love-rashifal" (click)="closeMenu()"
                         class="dropdown-item">{{'Today\'s Love Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/saptahik-rashifal" (click)="closeMenu()"
                         class="dropdown-item">{{'Weekly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/masik-rashifal" (click)="closeMenu()"
                         class="dropdown-item">{{'Monthly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/varshik-rashifal" (click)="closeMenu()"
                         class="dropdown-item">{{'Yearly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/tomorrow-rashifal" (click)="closeMenu()"
                         class="dropdown-item">{{'Tomorrow\'s Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashifal/yesterday-rashifal" (click)="closeMenu()"
                         class="dropdown-item">{{'Yesterday\'s Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}chinese-rashifal-2022" (click)="closeMenu()"
                         class="dropdown-item">{{'Chinese Horoscope' | translate}} 2022</a>
                  </li>
                </ul>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="langStatus == 'kn/'">
                  <li><a routerLink="/{{langStatus}}varshika-rashi-bhavishya-2022/" (click)="closeMenu()"
                         class="dropdown-item">{{'Horoscope' | translate}} 2022</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/indina-rashi-bhavishya" (click)="closeMenu()"
                         class="dropdown-item">{{'Today\'s'}} {{'Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}indina-preeti-rashi-bhavishya" (click)="closeMenu()"
                         class="dropdown-item">{{'Today\'s' | translate}} {{'Love' | translate}}
                    {{'Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/vaara-rashi-bhavishya" (click)="closeMenu()"
                         class="dropdown-item">{{'Weekly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/maasika-rashi-bhavishya" (click)="closeMenu()"
                         class="dropdown-item">{{'Monthly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/varshika-rashi-bhavishya" (click)="closeMenu()"
                         class="dropdown-item">{{'Yearly Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/tomorrow-rashi-bhavishya" (click)="closeMenu()"
                         class="dropdown-item">{{'Tomorrow\'s' | translate}} {{'Horoscope' | translate}}</a>
                  </li>
                  <li><a routerLink="/{{langStatus}}rashi-bhavishya/yesterday-rashi-bhavishya" (click)="closeMenu()"
                         class="dropdown-item">{{'Yesterday\'s' | translate}} {{'Horoscope' | translate}}</a>
                  </li>
                </ul>
              </div>
              <a routerLink="/{{langStatus}}matchmaking" (click)="closeMenu()"
                 class="nav-link">{{'Kundli Matching' | translate}}</a>
              <a rel="noopener" (click)="closeMenu()" *ngIf="langStatus == '/' || langStatus == 'en/'" class="nav-link"
                 href="https://astrotalk.com/astrology-blog/category/english" target="_blank">Blog</a>
              <a rel="noopener" (click)="closeMenu()" *ngIf="langStatus == 'hi/'" class="nav-link"
                 href="https://astrotalk.com/astrology-blog/category/hindi" target="_blank">{{'Blog' | translate}}</a>
              <a rel="noopener" (click)="closeMenu()" *ngIf="langStatus == 'kn/'" class="nav-link"
                 href="https://astrotalk.com/astrology-blog/category/kannada" target="_blank">{{'Blog' | translate}}</a>
              <a routerLink="/{{langStatus}}e-pooja" (click)="closeMenu()"
                 class="nav-link e-pooja-link">{{'Book a Pooja' | translate}}
                <span class="newftrcolr">{{'New' | translate}}</span>
              </a>
              <a (click)="closeMenu()" class="nav-link" routerLink="/{{langStatus}}shop">{{'Astromall' | translate}}</a>
              <a *ngIf="isLogin && !guestUser" (click)="closeMenu()" class="nav-link"
                 routerLink="/{{langStatus}}order-history">{{'order history' | translate}}</a>
              <a *ngIf="isLogin && !guestUser" (click)="closeMenu()" class="nav-link"
                 routerLink="/{{langStatus}}customer-support">{{'customer support chat' | translate}}</a>
              <a (click)="closeMenu()" class="nav-link"
                 routerLink="/{{langStatus}}notification">{{'notification' | translate}}
              </a>
              <a *ngIf="isLogin && !guestUser" (click)="closeMenu()" class="nav-link"
                 routerLink="/{{langStatus}}my-wallet">{{'Wallet Transactions' | translate}}
                <span class="wallet_amount_mbl_view">{{balence | rateSign}}</span>
              </a>
              <a class="nav-link normalCaseText" routerLink="/{{langStatus}}signup"
                 target="_blank">{{'Sign up as Astrologer' | translate}}
              </a>
              <a *ngIf="isLogin && !guestUser" class="nav-link"
                 (click)="logout(); closeMenu()">{{'logout' | translate}}
              </a>
              <a *ngIf="guestUser || productUUID" class="nav-link" id="guestUserLogin"
                 (click)="openModel(loginViaMobile); closeMenu()">{{'Verify Order' | translate}}
              </a>
              <a *ngIf="!isLogin" id="loginBtn" class="nav-link" (click)="openModel(loginViaMobile);
               closeMenu()">{{'Login' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- Login new phase -->
<ng-template #loginViaMobile let-modal class="modal fade loginModalOtp" id="exampleModal" tabindex="-1"
             aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-body">
    <i class="fa fa-times close_mobile_registration_popup" data-bs-dismiss="modal" aria-label="Close"
       (click)="closeModal(false, gotOtp)"></i>
    <!--    ENTER MOBILE NUMBER NORMAL LOGIN FLOW-->
    <div class="continue_with_number_section" id="continue_mbl_nmbr" *ngIf="loginFlow == 'MOBILE'"
         [ngClass]="{'dNone': gotOtp != 1, 'displayNone': gotOtp == 2, 'disNone': gotOtp == 3}">
      <form #loginOtpForm="ngForm" (ngSubmit)="loginViaOtp(loginOtpForm)" autocomplete="off" ngNativeValidate>
        <h1 class="main_heading_mobile_number_registration">{{'Continue with Phone' | translate}}</h1>
        <div class="padding_desktop_view">
          <h6 class="subheading_for_notification">
            <span *ngIf="loginObj.countryCode == '+91'">{{'You will receive a 4 digit code' | translate}}</span>
            <span *ngIf="loginObj.countryCode != '+91'">{{'You will receive a otp on mobile' | translate}}</span>
            <br> {{'for verification' | translate}}</h6>
          <div class="mobile_number_enter_section">
            <h6>{{'Enter your phone number' | translate}}</h6>
            <div class="box_enter_mobile_number">
              <div class="country_btn_number_registration">
                <img alt="flag" class="mat-img"
                     src="https://aws.astrotalk.com/assets/images/{{country.code | lowercase}}.webp">
              </div>
              <mat-select [placeholder]="country.dial_code  + ' ' + country.name" name="countryCodes"
                          class="form-control newLoginMat" [(ngModel)]="country" required
                          [ngClass]="{'errorClass': errorCountryTrue}" (ngModelChange)="selectedCountry()">
                <mat-option>
                  <ngx-mat-select-search
                    [disableInitialFocus]="isMobile" class="searchMobile"
                    [(ngModel)]="mobileNoSearch" name="mobileNoSearch"
                    placeholderLabel="{{'Search Country...' | translate}}" i18n-placeholderLabel
                    noEntriesFoundLabel="No match found"
                    i18n-noEntriesFoundLabel>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let con of countries | search:mobileNoSearch:true" #matOption [value]="con">
                  <img alt="flag" class="mat-img"
                       src="https://aws.astrotalk.com/assets/images/{{con.code | lowercase}}.webp"> {{con.dial_code}}
                  {{con.name | translate}}
                </mat-option>
              </mat-select>
              <input type="text" name="mobNo" class="form-control" id="mobileNo" [(ngModel)]="loginObj.mobile" required
                     autocomplete="off" pattern="{{(loginObj.countryCode == '+91')?'[1-9]{1}[0-9]{9}':''}}"
                     (keypress)="mobileNumberValidation(loginObj.mobile, $event)" (ngModelChange)="selectedCountry()"
                     placeholder="{{'Enter mobile no.' | translate}}" [ngClass]="{'errorClass': errorMobileTrue}"
                     oninput="this.value=this.value.replace(/[^\d]/,'')" inputmode="numeric">
            </div>
            <div class="errorClassTextOtp" [ngClass]="{'errorSpace': otpSentError || mobileFirstZero}"
                 *ngIf="otpSentError || mobileFirstZero;">
              {{otpSentErrorMsg ? otpSentErrorMsg : mobileFirstZeroMsg}}
            </div>
            <button type="submit" [disabled]="otpLoader" class="get_otp_btn"
                    [ngClass]="{'get_otp_dynamic_margin': otpSentError || mobileFirstZero}">{{'GET OTP' | translate}}
              <i class="fa" [class.fa-arrow-right]="!otpLoader" [class.fa-spinner]="otpLoader"
                 [class.fa-spin]="otpLoader"></i>
            </button>
            <div class="policy-text"
                 *ngIf="langStatus == '/' || langStatus == 'en/'">{{'By Signing up, you agree to our' | translate}}
              <a rel="noopener" href="https://astrotalk.com/terms-and-conditions"
                 target="_blank">{{'Terms of Use' | translate}}</a> {{'and' | translate}}
              <a rel="noopener" href="https://astrotalk.com/privacy-policies"
                 target="_blank">{{'Privacy Policy' | translate}}</a>
            </div>
            <div class="policy-text" *ngIf="langStatus == 'hi/'">साइन अप करके, आप हमारी
              <a rel="noopener" href="https://astrotalk.com/hi/terms-and-conditions" target="_blank"> उपयोग की शर्तों</a>
              और<a rel="noopener" href="https://astrotalk.com/hi/privacy-policies" target="_blank"> गोपनीयता नीति</a>
              से सहमत होते हैं
            </div>
          </div>
        </div>
      </form>
    </div>

    <!--    ENTER MOBILE NUMBER GOOGLE LOGIN FLOW-->
    <div class="continue_with_number_section" *ngIf="loginFlow == 'EMAIL' && googleMobileRequired"
         [ngClass]="{'dNone': gotOtp != 1, 'displayNone': gotOtp == 2, 'disNone': gotOtp == 3}">
      <form #loginOtpForm="ngForm" (ngSubmit)="mobileNoGoogleLogin(loginOtpForm)" autocomplete="off" ngNativeValidate>
        <div>
          <div class="astrotalk-logo-google">
            <img src="https://d1gcna0o0ldu5v.cloudfront.net/fit-in/320x410/assets/images/login_banner.webp" height="410"
                 width="320" alt="login_banner"></div>
          <h2 class="astrotalk-name">Astrotalk</h2>
          <h3 class="free-chat-title">First Chat with Astrologer is FREE!</h3>
          <div class="mobile_number_enter_section bg-yellow">
            <div class="box_enter_mobile_number bgwhite">
              <div class="country_btn_number_registration">
                <img alt="flag" class="mat-img"
                     src="https://aws.astrotalk.com/assets/images/{{country.code | lowercase}}.webp">
              </div>
              <mat-select [placeholder]="country.dial_code  + ' ' + country.name" name="countryCodes"
                          class="form-control newLoginMat" [(ngModel)]="country" required
                          [ngClass]="{'errorClass': errorCountryTrue}" (ngModelChange)="selectedCountry()">
                <mat-option>
                  <ngx-mat-select-search [disableInitialFocus]="isMobile" class="searchMobile"
                                         [(ngModel)]="mobileNoSearch" name="mobileNoSearch"
                                         placeholderLabel="{{'Search Country...' | translate}}" i18n-placeholderLabel
                                         noEntriesFoundLabel="No match found"
                                         i18n-noEntriesFoundLabel></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let con of countries | search:mobileNoSearch:true" #matOption [value]="con">
                  <img alt="flag" class="mat-img"
                       src="https://aws.astrotalk.com/assets/images/{{con.code | lowercase}}.webp">{{con.dial_code}}
                  {{con.name | translate}}</mat-option>
              </mat-select>
              <input type="text" name="mobNo" class="form-control" id="mobileNoGoogle" [(ngModel)]="loginObj.mobile"
                     autocomplete="off" pattern="{{(loginObj.countryCode == '+91')?'[1-9]{1}[0-9]{9}':''}}" required
                     (keypress)="mobileNumberValidation(loginObj.mobile, $event)" (ngModelChange)="selectedCountry()"
                     placeholder="{{'Enter mobile no.' | translate}}" [ngClass]="{'errorClass': errorMobileTrue}"
                     oninput="this.value=this.value.replace(/[^\d]/,'')">
            </div>
            <div class="errorClassTextOtp" [ngClass]="{'errorSpace': googleLoginError || mobileFirstZero}"
                 *ngIf="googleLoginError || mobileFirstZero;">
              {{googleLoginErrorMsg ? googleLoginErrorMsg : mobileFirstZeroMsg}}
            </div>
            <!--            <div id="firebase-otp-recaptcha"></div>-->
            <button type="submit" [disabled]="googleLoader" class="get_otp_btn googleMobileNumber"
                    [ngClass]="{'get_otp_dynamic_margin': googleLoginError || mobileFirstZero}">{{'Proceed To Login' | translate}}
              <i class="fa" [class.fa-arrow-right]="!googleLoader" [class.fa-spinner]="googleLoader"
                 [class.fa-spin]="googleLoader"></i></button>
            <div class="policy-text"
                 *ngIf="langStatus == '/' || langStatus == 'en/'">{{'By Signing up, you agree to our' | translate}}
              <a rel="noopener" href="https://astrotalk.com/terms-and-conditions"
                 target="_blank">{{'Terms of Use' | translate}}</a> {{'and' | translate}}
              <a rel="noopener" href="https://astrotalk.com/privacy-policies"
                 target="_blank">{{'Privacy Policy' | translate}}</a>
            </div>
            <div class="policy-text" *ngIf="langStatus == 'hi/'">साइन अप करके, आप हमारी
              <a rel="noopener" href="https://astrotalk.com/hi/terms-and-conditions" target="_blank"> उपयोग की शर्तों</a>
              और<a rel="noopener" href="https://astrotalk.com/hi/privacy-policies" target="_blank"> गोपनीयता नीति</a>
              से सहमत होते हैं
            </div>
          </div>
        </div>
      </form>
    </div>
    <div id="firebase-otp-recaptcha"></div>

    <!--    LOGIN FLOW GOOGLE MAIN MAIN PAGE-->
    <div class="continue_with_number_section" *ngIf="loginFlow == 'EMAIL' && !googleMobileRequired"
         [ngClass]="{'dNone': gotOtp != 1, 'displayNone': gotOtp == 2, 'disNone': gotOtp == 3}">
      <h1 class="main_heading_mobile_number_registration">{{'Continue with Google' | translate}}</h1>
      <div class="upper-section-google-login">
        <span class="image-astrotalk">
          <img src="https://d1gcna0o0ldu5v.cloudfront.net/fit-in/320x410/assets/images/login_banner.webp"
               height="410" width="320" alt="login_banner"></span>
        <h5 class="heading-google-login">Astrotalk <span>Chat with Astrologer</span></h5>
      </div>
      <div class="padding_desktop_view_google login-by-google">
        <div class="errorClassTextOtp errorSpaceGoogle" *ngIf="googleLoginErrorMsg">{{googleLoginErrorMsg}}</div>
        <div class="google_section">
          <button type="button" id="goog" class="google-signin-button" [disabled]="googleLoader"
                  (click)="signInWithGoogle()">
            <img alt="google" height="36" width="36" class="w18"
                 src="https://d1gcna0o0ldu5v.cloudfront.net/fit-in/320x410/assets/images/google_logo.svg">
            Login with Google <i *ngIf="googleLoader" class="fa fa-spin fa-spinner"></i>
          </button>
        </div>
        <div class="policy-text" *ngIf="langStatus == '/' || langStatus == 'en/'">By Signing up, you agree to our
          <a rel="noopener" href="https://astrotalk.com/terms-and-conditions" target="_blank">Terms of Use </a>and
          <a rel="noopener" href="https://astrotalk.com/privacy-policies" target="_blank">Privacy Policy</a>
        </div>
        <div class="policy-text" *ngIf="langStatus == 'hi/'">साइन अप करके, आप हमारी
          <a rel="noopener" href="https://astrotalk.com/hi/terms-and-conditions" target="_blank"> उपयोग की शर्तों</a>
          और<a rel="noopener" href="https://astrotalk.com/hi/privacy-policies" target="_blank"> गोपनीयता नीति</a>
          से सहमत होते हैं
        </div>
      </div>
    </div>
    <!--    ENTER OTP -->
    <div [ngClass]="{'dNone': gotOtp == 1, 'displayNone': gotOtp != 2, 'disNone': gotOtp == 3}" id="verify_mbl_nmbr">
      <span class="fa fa-arrow-left backArrowOtp" *ngIf="!timeLeft || !timeLeftForeign" (click)="backToPhone()"></span>
      <h1 class="main_heading_mobile_number_registration">{{'Verify Phone' | translate}}</h1>
      <div class="padding_desktop_view">
        <h6 class="otp_sent_number">{{'OTP sent to' | translate}}
          <span class="mobile-number">{{loginObj.countryCode}}-{{loginObj.mobile}}</span>
        </h6>
        <div class="otp_enter_section">
          <form (ngSubmit)="submitOtp()" autocomplete="off">
            <div class="otp_enter_row">
              <input type="text" name="salary1" [maxLength]="1" (keyup)="focusEvent($event, fir)" [(ngModel)]="fir"
                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     autocomplete="off" inputmode="numeric" [ngClass]="{'errorClass': otpError}" id="giveOtp"/>
              <input type="text" name="salary2" [maxLength]="1" (keyup)="focusEvent($event, sec)" [(ngModel)]="sec"
                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     autocomplete="off" inputmode="numeric" [ngClass]="{'errorClass': otpError}"/>
              <input type="text" name="salary3" [maxLength]="1" (keyup)="focusEvent($event, thi)" [(ngModel)]="thi"
                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     autocomplete="off" inputmode="numeric" [ngClass]="{'errorClass': otpError}"/>
              <input type="text" name="salary4" [maxLength]="1" (keyup)="focusEvent($event, fou)" [(ngModel)]="fou"
                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     autocomplete="off" inputmode="numeric" [ngClass]="{'errorClass': otpError}"/>

              <input type="text" name="salary5" [maxLength]="1" (keyup)="focusEvent($event, fiv)" [(ngModel)]="fiv"
                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     autocomplete="off" inputmode="numeric" [ngClass]="{'errorClass': otpError}"
                     *ngIf="loginObj.countryCode != '+91' && !isTwilioActive"/>
              <input type="text" name="salary6" [maxLength]="1" (keyup)="focusEvent($event, six)" [(ngModel)]="six"
                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     autocomplete="off" inputmode="numeric" [ngClass]="{'errorClass': otpError}"
                     *ngIf="loginObj.countryCode != '+91' && !isTwilioActive"/>
            </div>
            <div class="errorClassTextOtp" [ngClass]="{'mb-3': otpError}" *ngIf="otpError;">
              {{otpMismatchError ? otpMismatchError : ''}}
            </div>
            <button type="submit" class="opt_submit_btn" [ngClass]="{'mt-3': !otpError}"
                    [disabled]="!otpSubmitButton || otpVerifyLoader">{{'LOGIN' | translate}}
              <i class="fa fa-spinner fa-spin" *ngIf="otpVerifyLoader"></i>
            </button>
          </form>
          <div class="resend-otp-row" *ngIf="loginObj.countryCode == '+91'">
            <div class="resend-otp-text">
              <h6 class="resend_otp_avialable" *ngIf="timeLeft">{{'Resend OTP available in' | translate}}
                <span class="text-danger">{{timeLeft}}s</span></h6>
              <h6 class="resend_otp_avialable color-green" *ngIf="!timeLeft">{{'Resend OTP available' | translate}}</h6>
            </div>
            <div class="recent_otp_sms_call">
              <button type="button" class="bnts_recent_otp" (click)="resendVerifyMobileOtpLogin();"
                      [disabled]="(resentMobile || timeLeft)">{{'Resend OTP' | translate}}
                <i class="fa fa-spinner fa-spin" *ngIf="resentMobile"></i>
              </button>
            </div>
          </div>
          <div class="resend-otp-row" *ngIf="loginObj.countryCode != '+91' && !otpSeconds">
            <div class="resend-otp-text">
              <h6 class="resend_otp_avialable" *ngIf="timeLeftForeign">{{'Resend OTP available in' | translate}}
                <span class="text-danger">{{timeLeftForeign}}s</span></h6>
              <h6 class="resend_otp_avialable color-green"
                  *ngIf="!timeLeftForeign">{{'Resend OTP available' | translate}}</h6>
            </div>
            <div class="recent_otp_sms_call">
              <button type="button" class="bnts_recent_otp" [disabled]="(resentMobile || timeLeftForeign)"
                      (click)="sendOtpLoginViaFirebase(loginObj.countryCode + loginObj.mobile, true);">
                <i class="fa fa-spinner fa-spin" *ngIf="resentMobile"></i> {{'Resend OTP' | translate}}
              </button>
            </div>
          </div>
          <div class="resend-otp-row" *ngIf="loginObj.countryCode != '+91' && otpSeconds">
            <div class="resend-otp-text">
              <h6 class="resend_otp_avialable" *ngIf="timeLeft">{{'Resend OTP available in' | translate}}
                <span class="text-danger">{{timeLeft}}s</span></h6>
              <h6 class="resend_otp_avialable color-green" *ngIf="!timeLeft">{{'Resend OTP available' | translate}}</h6>
            </div>
            <div class="recent_otp_sms_call">
              <button type="button" class="bnts_recent_otp" [disabled]="(resentMobile || timeLeft)"
                      (click)="resendVerifyMobileOtpLogin();">
                <i class="fa fa-spinner fa-spin" *ngIf="resentMobile"></i> {{'Resend OTP' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    ENTER YOUR FIRST NAME AS NEW USER-->
    <div [ngClass]="{'dNone': gotOtp == 1, 'displayNone': gotOtp == 2, 'disNon': gotOtp != 3}">
      <h1 class="main_heading_mobile_number_registration">{{'Welcome To Astrotalk' | translate}}</h1>
      <div class="padding_first_name_view">
        <div class="col-12 form-group">
          <label for="firstNameNewUser">{{'What\'s your first name?' | translate}}</label>
          <input type="text" placeholder="{{'First Name' | translate}}" class="form-control" required
                 name="firstNameNewUser" id="firstNameNewUser" [(ngModel)]="firstNameNewUser">
        </div>
        <div class="recent_otp_sms_call">
          <button type="submit" class="bnts_recent_otp text-center submit-your-name"
                  (click)="submitLocalData()">{{'Submit' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- logout from all other device -->
<ng-template #otherDeviceLogout let-data>
  <div mat-dialog-content [class.text-bold]="data.warning">{{data.message | translate}}</div>
  <mat-dialog-actions *ngIf="data.warning" class="justify-content-end">
    <button type="button" mat-dialog-close class="logout btn_cht cancelBtn">{{'Cancel' | translate}}</button>&nbsp;&nbsp;
    <button type="button" mat-dialog-close class="logout btn_cht deleteBtn"
            (click)="logoutOtherDevices()">{{'Logout' | translate}}</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #bonusTemplate let-data>
  <div class="position-relative">
    <button type="button" class="btn-close position-absolute top-0 end-0" mat-button mat-dialog-close></button>
    <h5 mat-dialog-title class="text-center">{{data.heading}}</h5>
    <div class="bonus-animation">{{data.message}}  {{creditBonusPrice | rateSign}}  {{data.remainingMsg}}</div>
    <mat-dialog-actions align="end" *ngIf="data.warning"></mat-dialog-actions>
  </div>
</ng-template>
