import {Pipe, PipeTransform} from '@angular/core';
import {filter} from 'lodash-es';

@Pipe({
  name: 'keySearch'
})
export class KeySearchPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (searchText.length >= 3) {
      if (searchText && searchText.trim().length > 0 && items[0].name) {
        items =  filter(items, row => (row.name.toLowerCase().includes(String(searchText).toLowerCase())));
      } else if (searchText && searchText.trim().length > 0 && items[0].title) {
        items =  filter(items, row => (row.title.toLowerCase().includes(String(searchText).toLowerCase())));
      }
    }
    return items;
  }

}
