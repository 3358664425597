import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  transform(input: any, cr = false, onlyValue = false, onlySuffix = false, ...args: any): any {
    let exp;
    const suffixes = ['k', 'm', 'b', 't', 'p', 'e'];
    const isNegativeValues = input < 0;
    if (Number.isNaN(input) || (input < 1000 && input >= 0) || !this.isNumeric(input) || (input < 0 && input > -1000)) {
      if (!!args && this.isNumeric(input) && !(input < 0) && input != 0) {
        return input.toFixed(args);
      } else {
        return input;
      }
    }

    if (!isNegativeValues && !cr) {
      exp = Math.floor(Math.log(input) / Math.log(1000));
      return (input / Math.pow(1000, exp)).toFixed(1) + suffixes[exp - 1];
    } else if (!isNegativeValues && cr && !onlyValue && !onlySuffix) {
      exp = Math.floor(Math.log(input) / Math.log(1000));
      return (input / Math.pow(1000, exp)).toFixed(1) + ((suffixes[exp - 1] != 'm') ? suffixes[exp - 1] : ' Million');
    } else if (!isNegativeValues && cr && onlyValue && !onlySuffix) {
      exp = Math.floor(Math.log(input) / Math.log(1000));
      return (input / Math.pow(1000, exp)).toFixed(1);
    } else if (!isNegativeValues && cr && !onlyValue && onlySuffix) {
      exp = Math.floor(Math.log(input) / Math.log(1000));
      return ((suffixes[exp - 1] != 'm') ? suffixes[exp - 1] : ' Million');
    } else {
      input = input * -1;
      exp = Math.floor(Math.log(input) / Math.log(1000));
      return (input * -1 / Math.pow(1000, exp)).toFixed(1) + suffixes[exp - 1];
    }
  }

  isNumeric(value): boolean {
    if (value < 0) value = value * -1;
    if (/^-{0,1}\d+$/.test(value)) {
      return true;
    } else if (/^\d+\.\d+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }
}
