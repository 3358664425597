import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value.length > 0) {
      return value.replace(/\n/g, '<br/>');
    }
    return value;
  }

}
