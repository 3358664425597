import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangGuard implements CanActivate {

  allowedLanguages: string[] = ['en', 'hi', 'kn'];

  constructor(private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const lang = next.params['lang'];
    const isValid = this.validateLang(lang);
    if (isValid) {
      return true; // Language is valid, navigation is allowed
    } else {
      const urlWithoutLang = state.url.replace(`/${lang}`, '');
      this.router.navigateByUrl(urlWithoutLang);
      return false;
    }
  }

  private validateLang(lang: string): boolean {
    if (this.allowedLanguages.includes(lang)) {
      return true;
    } else {
      return false;
    }
  }
}
