import { Pipe, PipeTransform } from '@angular/core';
import {filter} from 'lodash-es';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(array: any[], query: string, mobile: boolean = false): any {
    let dataItems = array;
    query = (query) ? query.toLowerCase() : '';
    if (mobile) {
      if (query && query.trim().length > 0){
        dataItems =  filter(dataItems, row => {
          if (row.name.toLowerCase().indexOf(query) > -1) {
            return true;
          }else if (row.dial_code.toLowerCase().indexOf(query) > -1) {
            return true;
          }else{
            return false;
          }
        });
      }
    }else{
      if (query && query.trim().length > 0 && dataItems[0].name) {
        dataItems =  filter(dataItems, row => (row.name.toLowerCase().indexOf(query) > -1));
      } else if (query && query.trim().length > 0 && dataItems[0].title) {
        dataItems =  filter(dataItems, row => (row.title.toLowerCase().indexOf(query) > -1));
      }else if (query && query.trim().length > 0 && dataItems[0]) {
        dataItems =  filter(dataItems, row => (row.toLowerCase().indexOf(query) > -1));
      }
    }


    if (dataItems.length > 0){
      return dataItems;
    }else{
      return null;
    }
  }

}
