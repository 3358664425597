const devServers = ['dev1', 'dev2', 'dev3', 'dev4', 'dev5', 'dev6', 'dev7', 'dev8', 'dev9'];
let dev = 'preprod1'; // live, preprod1, preprod2, preprod3;
// const userDomain = 'https://toi.astrotalk.com/';
// const userDomain = 'https://angular.astrotalk.in/';
// const userDomain = 'http://localhost:4200/';
const userDomain = 'http://staging-shaadi.astrotalk.com/';

var env: any;
if (devServers.includes(dev)) {
  env = {
    production: false,
    apiDomain: 'https://' + dev + '.api.astrotalk.in/AstroTalk/',
    kundliApi: 'https://' + dev + '.api.astrotalk.in/v1/',
    apiDomainSupport: 'https://' + dev + '.api.astrotalk.in/AstroChat/',
    chatApi: 'https://' + dev + '.api.astrotalk.in/AstrotalkChat/',
    astroMallApi: 'https://' + dev + '.api.astrotalk.in/AstroMall/',
    astroTvApi: 'https://' + dev + '.api.astrotalk.in/AstrotalkLive/',
    apiDomainForList: 'https://' + dev + '.api.astrotalk.in/AstroTalk/',
    LOCATIONAPIDOMAIN: 'https://api.locationiq.com/v1/',
    tokenName: 'dev_token',
    domain: userDomain,
    SECONDPLACES: 'pk.e8a0a377dd1df522d510716ba61eaeab',
    CODEYETI_RAZOR_PAY: 'rzp_test_K2FmWkyeoXIGD3',
    MASKYETI_RAZOR_PAY: 'rzp_test_mnLQsJF2nkRjsg',
    ASTROTALK_RAZOR_PAY: 'rzp_test_8z4JnWLwdNHC3e',
    trueCallerKey: 'jq1d26d8dbbb204bc48688bba6822e2365761',
    trueCallerName: 'Testing',
    PaypalID: 'AV5wmzFoZ5-3qTUNt2J_sHwSrBcKexfpb7Iv0w2rGtRSjzVUv1uGwb6d5QgHgI-oZx6zVGHOLnVKPdJf',
    PaypalEnv: 'sandbox',
    PAYTMMID: 'aGuuxv80528402551811',
    PAYTMWEBSITE: 'WEBSTAGING',
    INDUSTRY_TYPE_ID: 'Retail',
    CHANNEL_ID: 'WEB',
    payULink: 'https://test.payu.in/_payment',
    payUKey: '8TDnxS',
    firebase: {
      apiKey: 'AIzaSyCwst9CLNKuA5UvPuxWdRV6nd-_fE8TF6E',
      authDomain: 'astroyeti-4bc02.firebaseapp.com',
      databaseURL: 'https://astroyeti-4bc02.firebaseio.com',
      projectId: 'astroyeti-4bc02',
      storageBucket: 'astroyeti-4bc02.appspot.com',
      messagingSenderId: '135983970470',
      appId: '1:135983970470:web:704107c27bf32e2a351c67',
      measurementId: 'G-MB47Q5ZT36'
    },
    PORT: 3002,
    CDN_DOMAIN: 'http://aws.astrotalk.com'
  };
} else if (dev == 'preprod1') {
  env = {
    production: false,
    apiDomain: 'https://preprod1.api.astrotalk.in/AstroTalk/', // --preprod1
    kundliApi: 'https://preprod1.api.astrotalk.in/v1/', // --preprod1
    chatApi: 'https://preprod1.api.astrotalk.in/AstrotalkChat/', // --preprod1
    apiDomainSupport: 'https://preprod1.api.astrotalk.in/AstroChat/', // --preprod1
    astroMallApi: 'https://preprod1.api.astrotalk.in/AstroMall/', // --preprod1
    astroTvApi: 'https://preprod1.api.astrotalk.in/AstrotalkLive/', // --preprod1
    apiDomainForList: 'https://preprod1.api.astrotalk.in/AstroTalk/', // --preprod1
    LOCATIONAPIDOMAIN: 'https://api.locationiq.com/v1/',
    tokenName: 'jwt_token',
    domain: userDomain,
    SECONDPLACES: 'pk.e8a0a377dd1df522d510716ba61eaeab',
    CODEYETI_RAZOR_PAY: 'rzp_live_nbFIyWp9PWCqNl',
    MASKYETI_RAZOR_PAY: 'rzp_live_idcMSOkCdvdxCB',
    ASTROTALK_RAZOR_PAY: 'rzp_live_Mw6ZYXea2k4yyj',
    trueCallerKey: 'WQSEab6e063f5fe3c4884b10401f4ce45147a',
    trueCallerName: 'Astrotalk',
    PaypalID: 'AXcfQe-af8zaTrrfPYHwXDcVZ1CwkamtBa-VXBwbqP4cJBhni3_34Zy8t4mxyA3sjudNpXexeqe50XcP',
    PaypalEnv: 'production',
    PAYTMMID: 'CODEYE84459150770958',
    PAYTMWEBSITE: 'WEBPROD',
    INDUSTRY_TYPE_ID: 'Retail109',
    CHANNEL_ID: 'WAP',
    payULink: 'https://secure.payu.in/_payment',
    payUKey: 'g1tT0E',
    firebase: {
      apiKey: 'AIzaSyCwst9CLNKuA5UvPuxWdRV6nd-_fE8TF6E',
      authDomain: 'astroyeti-4bc02.firebaseapp.com',
      databaseURL: 'https://astroyeti-4bc02.firebaseio.com',
      projectId: 'astroyeti-4bc02',
      storageBucket: 'astroyeti-4bc02.appspot.com',
      messagingSenderId: '135983970470',
      appId: '1:135983970470:web:704107c27bf32e2a351c67',
      measurementId: 'G-MB47Q5ZT36'
    },
    PORT: 4000,
    CDN_DOMAIN: 'http://aws.astrotalk.com',
  };
} else if (dev == 'preprod2') {
  env = {
    production: false,
    apiDomain: 'https://preprod2.api.astrotalk.in/AstroTalk/', // --preprod2
    kundliApi: 'https://preprod2.api.astrotalk.in/v1/', // --preprod2
    chatApi: 'https://preprod2.api.astrotalk.in/AstrotalkChat/', // --preprod2
    apiDomainSupport: 'https://preprod2.api.astrotalk.in/AstroChat/', // --preprod2
    astroMallApi: 'https://preprod2.api.astrotalk.in/AstroMall/', // --preprod2
    astroTvApi: 'https://preprod2.api.astrotalk.in/AstrotalkLive/', // --preprod2
    apiDomainForList: 'https://preprod2.api.astrotalk.in/AstroTalk/', // --preprod2
    LOCATIONAPIDOMAIN: 'https://api.locationiq.com/v1/',
    tokenName: 'jwt_token',
    domain: userDomain,
    SECONDPLACES: 'pk.e8a0a377dd1df522d510716ba61eaeab',
    CODEYETI_RAZOR_PAY: 'rzp_live_nbFIyWp9PWCqNl',
    MASKYETI_RAZOR_PAY: 'rzp_live_idcMSOkCdvdxCB',
    ASTROTALK_RAZOR_PAY: 'rzp_live_Mw6ZYXea2k4yyj',
    trueCallerKey: 'WQSEab6e063f5fe3c4884b10401f4ce45147a',
    trueCallerName: 'Astrotalk',
    PaypalID: 'AXcfQe-af8zaTrrfPYHwXDcVZ1CwkamtBa-VXBwbqP4cJBhni3_34Zy8t4mxyA3sjudNpXexeqe50XcP',
    PaypalEnv: 'production',
    PAYTMMID: 'CODEYE84459150770958',
    PAYTMWEBSITE: 'WEBPROD',
    INDUSTRY_TYPE_ID: 'Retail109',
    CHANNEL_ID: 'WAP',
    payULink: 'https://secure.payu.in/_payment',
    payUKey: 'g1tT0E',
    firebase: {
      apiKey: 'AIzaSyCwst9CLNKuA5UvPuxWdRV6nd-_fE8TF6E',
      authDomain: 'astroyeti-4bc02.firebaseapp.com',
      databaseURL: 'https://astroyeti-4bc02.firebaseio.com',
      projectId: 'astroyeti-4bc02',
      storageBucket: 'astroyeti-4bc02.appspot.com',
      messagingSenderId: '135983970470',
      appId: '1:135983970470:web:704107c27bf32e2a351c67',
      measurementId: 'G-MB47Q5ZT36'
    },
    PORT: 4000,
    CDN_DOMAIN: 'http://aws.astrotalk.com',
  };
} else if (dev == 'preprod3') {
  env = {
    production: false,
    apiDomain: 'https://preprod3.api.astrotalk.in/AstroTalk/', // --preprod3
    kundliApi: 'https://preprod3.api.astrotalk.in/v1/', // --preprod3
    chatApi: 'https://preprod3.api.astrotalk.in/AstrotalkChat/', // --preprod3
    apiDomainSupport: 'https://preprod3.api.astrotalk.in/AstroChat/', // --preprod3
    astroMallApi: 'https://preprod3.api.astrotalk.in/AstroMall/', // --preprod3
    astroTvApi: 'https://preprod3.api.astrotalk.in/AstrotalkLive/', // --preprod3
    apiDomainForList: 'https://preprod3.api.astrotalk.in/AstroTalk/', // --preprod3
    LOCATIONAPIDOMAIN: 'https://api.locationiq.com/v1/',
    tokenName: 'jwt_token',
    domain: userDomain,
    SECONDPLACES: 'pk.e8a0a377dd1df522d510716ba61eaeab',
    CODEYETI_RAZOR_PAY: 'rzp_live_nbFIyWp9PWCqNl',
    MASKYETI_RAZOR_PAY: 'rzp_live_idcMSOkCdvdxCB',
    ASTROTALK_RAZOR_PAY: 'rzp_live_Mw6ZYXea2k4yyj',
    trueCallerKey: 'WQSEab6e063f5fe3c4884b10401f4ce45147a',
    trueCallerName: 'Astrotalk',
    PaypalID: 'AXcfQe-af8zaTrrfPYHwXDcVZ1CwkamtBa-VXBwbqP4cJBhni3_34Zy8t4mxyA3sjudNpXexeqe50XcP',
    PaypalEnv: 'production',
    PAYTMMID: 'CODEYE84459150770958',
    PAYTMWEBSITE: 'WEBPROD',
    INDUSTRY_TYPE_ID: 'Retail109',
    CHANNEL_ID: 'WAP',
    payULink: 'https://secure.payu.in/_payment',
    payUKey: 'g1tT0E',
    firebase: {
      apiKey: 'AIzaSyCwst9CLNKuA5UvPuxWdRV6nd-_fE8TF6E',
      authDomain: 'astroyeti-4bc02.firebaseapp.com',
      databaseURL: 'https://astroyeti-4bc02.firebaseio.com',
      projectId: 'astroyeti-4bc02',
      storageBucket: 'astroyeti-4bc02.appspot.com',
      messagingSenderId: '135983970470',
      appId: '1:135983970470:web:704107c27bf32e2a351c67',
      measurementId: 'G-MB47Q5ZT36'
    },
    PORT: 4000,
    CDN_DOMAIN: 'http://aws.astrotalk.com',
  };
} else if (dev == 'live') {
  env = {
    production: false,
    apiDomain: 'https://api.prod.astrotalk.in/AstroTalk/',
    apiDomainForList: 'https://api.consultant.list.astrotalk.com/AstroTalk/',
    chatApi: 'https://api.paidchat.astrotalk.com/AstrotalkChat/',
    apiDomainSupport: 'https://api.supportchat.astrotalk.com/AstroChat/',
    kundliApi: 'https://api.kundali.astrotalk.com/v1/',
    astroMallApi: 'https://api.astromall.astrotalk.com/AstroMall/',
    astroTvApi: 'https://api.live.astrotalk.com/AstrotalkLive/',
    LOCATIONAPIDOMAIN: 'https://api.locationiq.com/v1/',
    tokenName: 'dev_token',
    domain: userDomain,
    SECONDPLACES: 'pk.e8a0a377dd1df522d510716ba61eaeab',
    CODEYETI_RAZOR_PAY: 'rzp_live_nbFIyWp9PWCqNl',
    MASKYETI_RAZOR_PAY: 'rzp_live_idcMSOkCdvdxCB',
    ASTROTALK_RAZOR_PAY: 'rzp_live_Mw6ZYXea2k4yyj',
    trueCallerKey: 'WQSEab6e063f5fe3c4884b10401f4ce45147a',
    trueCallerName: 'Astrotalk',
    PaypalID: 'AXcfQe-af8zaTrrfPYHwXDcVZ1CwkamtBa-VXBwbqP4cJBhni3_34Zy8t4mxyA3sjudNpXexeqe50XcP',
    PaypalEnv: 'production',
    PAYTMMID: 'CODEYE84459150770958',
    PAYTMWEBSITE: 'WEBPROD',
    INDUSTRY_TYPE_ID: 'Retail109',
    CHANNEL_ID: 'WAP',
    payULink: 'https://secure.payu.in/_payment',
    payUKey: 'g1tT0E',
    firebase: {
      apiKey: 'AIzaSyCwst9CLNKuA5UvPuxWdRV6nd-_fE8TF6E',
      authDomain: 'astroyeti-4bc02.firebaseapp.com',
      databaseURL: 'https://astroyeti-4bc02.firebaseio.com',
      projectId: 'astroyeti-4bc02',
      storageBucket: 'astroyeti-4bc02.appspot.com',
      messagingSenderId: '135983970470',
      appId: '1:135983970470:web:704107c27bf32e2a351c67',
      measurementId: 'G-MB47Q5ZT36'
    },
    PORT: 4000,
    CDN_DOMAIN: 'http://aws.astrotalk.com',
  };
}
export const environment = env;
