import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../global/common-file/common.service';

@Pipe({
  name: 'rate'
})
export class RatePipe implements PipeTransform {
  constructor(private common: CommonService) {
  }
  transform(value, toFixed = 0): string {
    let conversion = this.common.getWithExpiry('conRate');
    if (conversion) {
      let rate = conversion.conversionFactor;
      if (conversion.isoCode == 'INR') {
        return (parseFloat(value) / rate).toFixed(toFixed).toString();
      }else{
        return (parseFloat(value) / rate).toFixed(2).toString();
      }
    } else{
      if (this.common.timeZone() == 'Asia/Kolkata') {
        return parseFloat(value).toFixed(toFixed).toString();
      }else{
        return (parseFloat(value) * 0.77).toFixed(2).toString();
      }
    }
  }

}
