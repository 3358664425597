import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private doc) {
  }

  setCanonicalURL(url?: string) {
    const can = this.doc.querySelector("link[rel='canonical']");
    if (can) {
      this.doc.head.removeChild(can);
    }
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    this.doc.head.appendChild(link);
  }

}
