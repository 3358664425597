import {Pipe, PipeTransform} from '@angular/core';
import {CommonService} from '../global/common-file/common.service';

@Pipe({
  name: 'stringToHtml'
})
export class StringToHtmlPipe implements PipeTransform {
  constructor(private common: CommonService) {
  }

  transform(value: string, arg1?: any): string {
    const values = value.split(',');
    if (values.length > 0) {
      return `
      <div class="kundli-share-message">
       <div class="${this.getClass()} logo-name">${values[0]?.charAt(0)?.toUpperCase()}</div>
       <div class="details-kundli">
        <div class="top-details">
         <span class="name-user-kundli" *ngIf="${values[0]}">${values[0]}</span>
          <a class="view-kundli-btn" target="_blank"
           href="https://astrotalk.com/kundli-generate/${this.common.customEncode(this.common.customEncode(arg1))}">View kundli</a>
         </div>
         <div class="date-address">
            <span *ngIf="${values[1]}">${values[1]}</span><span *ngIf="${values[2]}"> | ${values[2]}</span></div>
         <div class="date-address">
            <span *ngIf="${values[3]}">${values[3]}</span><span *ngIf="${values[4]}">, ${values[4]}</span><span *ngIf="${values[5]}">, ${values[5]}</span></div>
        </div>
       </div>`;
    } else {
      return value;
    }
  }

  getClass(): string {
    const cls = Math.floor((Math.random() * 100));
    if ((cls % 2) == 0) {
      return 'colorBrown';
    } else if ((cls % 3) == 0) {
      return 'colorPink';
    } else if ((cls % 4) == 0) {
      return 'colorRed';
    } else if ((cls % 5) == 0) {
      return 'colorGreen';
    } else if ((cls % 6) == 0) {
      return 'colorTeal';
    } else {
      return 'colorBlue';
    }
  }

}
