import {Inject, Injectable, PLATFORM_ID, Optional} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {AppConstant} from './constant';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import jwt_decode from 'jwt-decode';
import {Request} from 'express';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {tap} from 'rxjs/operators';
declare let window: any;
class LocalStorage implements Storage {
  [name: string]: any;

  readonly length: number;

  clear(): void {
  }

  getItem(key: string): string | null {
    return undefined;
  }

  key(index: number): string | null {
    return undefined;
  }

  removeItem(key: string): void {
  }

  setItem(key: string, value: string): void {
  }
}

@Injectable()
export class CommonService {
  private storage: Storage;
  private isBrowser = false;
  private previousUrl: string;
  private urlHistory: string;
  isBrowserService = new BehaviorSubject<boolean>(null);
  langStatus = new BehaviorSubject('');
  pageName = new BehaviorSubject('');
  browserTimeZone;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(REQUEST) protected request: Request,
    @Inject(DOCUMENT) private document: Document,
    private _http: HttpClient,
    private _router: Router,
    private toastr: ToastrService,
    private titleService: Title,
    private metaService: Meta) {
    this.storage = new LocalStorage();
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
        this.storage = localStorage;
      } catch (e) {
      }
    }

    const token = (this.getToken()) ? true : false;
    if (token) {
      if (this.isTokenExpired()) {
        this.isLogin.next(false);
      } else {
        this.isLogin.next(true);
      }
    } else {
      this.isLogin.next(false);
    }
  }

  base64: any;
  manualFullPageLoader = false;
  appId = 3;
  APP_ID_WEB = 4;
  APP_ID_WEB_MOBILE = 3;
  businessId = 1;
  userId: number;
  appVersion = '1.19.09.23';
  languageId: any = {
    'en/': 1,
    'hi/': 2,
    'ta/': 3,
    'pa/': 4,
    'ma/': 5,
    'gu/': 6,
    'ba/': 7,
    'fr/': 8,
    'od/': 9,
    'te/': 10,
    'kn/': 11,
    'ml/': 12,
    'sk/': 13,
    'as/': 14,
    'gr/': 15,
    'sp/': 17,
    'mr/': 18,
    'mn/': 19,
    'ur/': 20,
    'sn/': 21,
    'km/': 22,
    'bd/': 23,
    'np/': 24,
    'ko/': 25,
    'mt/': 26,
    'ar/': 27,
    'bj/': 28,
    'dt/': 29,
  };
  serviceId = 3;
  REPORT_SERVICEID = 2;
  MALL_SERVICEID = 5;
  CALL_SERVICEID = 3;
  CHAT_SERVICEID = 4;
  basicErr = 'Something went wrong, please try again later.';
  public isLogin = new BehaviorSubject<boolean>(null);

  // rateApi = AppConstant.apiUrl + AppConstant.rateApi;
  fileUploadApi = AppConstant.apiUrl + AppConstant.fileUploadApi;
  getLoginStaticValue = AppConstant.apiUrl + AppConstant.loginValueCheck;
  sendGtagToken = AppConstant.apiUrl + AppConstant.sendGtagToken;

  setLoginValue(newValue): void {
    this.isLogin.next(newValue);
  }

  getLoginValue(): Observable<boolean> {
    return this.isLogin;
  }

  setPreviousUrl = (url) => {
    this.previousUrl = url;
  }

  getPreviousUrl = () => {
    return (typeof this.previousUrl !== 'undefined') ? this.previousUrl : '/';
  }

  setUrlHistory = (url) => {
    this.urlHistory = url;
  }

  getUrlHistory = () => {
    return (typeof this.urlHistory !== 'undefined') ? this.urlHistory : '/';
  }

  removeBrowserState() {
    history.pushState(null, null, location.href);
    window.onpopstate = () => {
      history.go(1);
    };
  }

  getServiceId(service?: string): number {
    if (service === 'call') {
      return this.CALL_SERVICEID;
    } else if (service === 'chat') {
      return this.CHAT_SERVICEID;
    } else if (service === 'report') {
      return this.REPORT_SERVICEID;
    } else if (service === 'mall') {
      return this.MALL_SERVICEID;
    } else {
      return this.serviceId;
    }
  }

  getAppId() {
    if (this.checkDeviceIsMobile()) {
      return this.APP_ID_WEB_MOBILE;
    } else {
      return this.APP_ID_WEB;
    }
  }

  getLanguageId(lang) {
    return (this.languageId[lang]) ? this.languageId[lang] : 1;
  }

  getBusinessId() {
    return this.businessId;
  }

  getAppVersion() {
    return this.appVersion;
  }

  getItem(name): string {
    return this.storage.getItem(name);
  }

  setItem(name, value: string): void {
    this.storage.setItem(name, value);
  }

  removeItem(name: string): void {
    this.storage.removeItem(name);
  }

  getToken(): string {
    return this.storage.getItem(AppConstant.token);
  }

  setToken(token: string): void {
    this.storage.setItem(AppConstant.token, token);
  }

  setIp(ipAddress: string): void {
    this.storage.setItem('ip', ipAddress);
  }

  getIp(): string {
    return this.storage.getItem('ip');
  }

  setUserData(data): void {
    if (data.isForeign) {
      this.storage.setItem('timeZone', 'foreign');
    } else {
      this.setItem('timeZone', 'Asia/Kolkata');
    }
    this.storage.setItem('userData', JSON.stringify(data));
  }

  getUserData(key = null): any {
    const jsonData = this.storage.getItem('userData');
    if (jsonData && jsonData != '') {
      const userData = JSON.parse(jsonData);
      if (key) {
        return (userData && userData[key]) ? userData[key] : '';
      }
      return userData;
    }
    return '';
  }

  logout() {
    this.setLocalTimeZone();
    this.storage.removeItem(AppConstant.token);
    this.storage.removeItem('userData');
    this.storage.removeItem('isToTakeVerifiedNo');
    this.storage.removeItem('hasLanguage');
    this.storage.removeItem('isToTakeVerifiedNo_firebase');
    this.storage.removeItem('chatCallTip');
    this.storage.removeItem('chatCall2ndTip');
    this.storage.removeItem('fireBaseToken');
    this.storage.removeItem('oldLogin');
    this.storage.removeItem('liveEventUrl');
    this.storage.removeItem('webview');
    this.storage.removeItem('partner');
    this.storage.removeItem('poOffer');
    this.storage.removeItem('guestUser');
    this.storage.removeItem('generateDetails');
    this.setLoginValue(false);
    this.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const conversion = this.getWithExpiry('conRate');
    if (this.browserTimeZone == 'Asia/Calcutta' && conversion.isoCode != 'INR') {
      this.storage.removeItem('conRate');
    }
  }

  showFullPageLoader() {
    if (this.isBrowser) {
      this.manualFullPageLoader = true;
    }
  }

  hideFullPageLoader() {
    if (this.isBrowser) {
      this.manualFullPageLoader = false;
    }
  }

  getFullPageLoader() {
    return this.manualFullPageLoader;
  }

  canApiCall = (): boolean => {
    return this.checkDeviceIsBrowser();
  }

  checkDeviceIsBrowser = (): boolean => {
    if (this.isBrowser) {
      return true;
    }
    return false;
  }

  checkDeviceIsMobile = () => {
    if (this.isBrowser) {
      const ua = navigator.userAgent;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        if (window.innerWidth <= 576) {
          return true;
        } else {
          return false;
        }
      } else if (/Chrome/i.test(ua)) {
        return false;
      } else {
        return false;
      }
    } else {
      const ua = this.request.headers['user-agent'];
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        return true;
      } else if (/Chrome/i.test(ua)) {
        return false;
      } else {
        return false;
      }
    }
  }

  getMobileOperatingSystem = () => {
    if (this.isBrowser) {
      const userAgent = navigator.userAgent || navigator.vendor || window?.opera;
      if (/windows phone/i.test(userAgent)) {
        return 'Windows';
      } else if (/android/i.test(userAgent)) {
        return 'Android';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window?.MSStream) {
        return 'iOS';
      } else if (userAgent.indexOf('Mac OS X') != -1) {
        return 'Mac';
      } else {
        return 'browser';
      }
    } else {
      const userAgent = this.request.headers['user-agent'] || '';
      if (/windows phone/i.test(userAgent)) {
        return 'Windows';
      } else if (/android/i.test(userAgent)) {
        return 'Android';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !/MSIE|Edge/.test(userAgent)) {
        return 'iOS';
      } else if (userAgent.indexOf('Mac OS X') != -1) {
        return 'Mac';
      } else {
        return 'browser';
      }
    }
  }

  timeZone = () => {
    const timeZone = this.getItem('timeZone');
    if ((typeof (timeZone) == 'undefined') || timeZone == '') {
      return 'Asia/Kolkata';
    }
    return timeZone;
  }

  setApiTimeZone = (isForeign: boolean): boolean => {
    if (isForeign) {
      this.setItem('timeZone', 'Foreign');
    } else {
      this.setItem('timeZone', 'Asia/Kolkata');
    }
    return true;
  }

  toastrInvoke(type: string, msg: string = '') {
    if (this.isBrowser) {
      if (type === 'error') {
        if (msg) {
          return this.toastr.error(msg);
        } else {
          return this.toastr.error(this.basicErr);
        }
      } else if (type === 'success') {
        this.toastr.success(msg);
      } else if (type === 'warning') {
        this.toastr.warning(msg);
      } else if (type === 'info') {
        this.toastr.info(msg);
      }
    }
  }

  async setLocalTimeZone() {
    const time = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (time == 'Asia/Calcutta' || time == 'Asia/Kolkata') {
      this.setItem('timeZone', 'Asia/Kolkata');
    } else {
      this.setItem('timeZone', 'foreign');
    }
  }

  public fileUpload = (body) => {
    const token = this.getToken();
    const header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + (token).toString())
        .set('id', (this.getUserData('id')).toString())
    };
    return this._http.post(this.fileUploadApi, body, header);
  }

  public rateOfConversion = (params = null, setHeader = false) => {
    let url = AppConstant.apiUrl + AppConstant.getCurrency;
    const token = this.getToken();
    let header = {};
    if (setHeader && token) {
      header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', 'Bearer ' + (token).toString())
          .set('id', this.getUserData('id').toString())
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this._http.get(url, header).pipe(
      tap(
        {
          next: (data) => this.log(data),
          error: (error) => this.logError(error)
        }
      )
    );
  }

  public getStaticValueForLogin = (id) => {
    return this._http.get(this.getLoginStaticValue + '?id=' + id);
  }

  public seoTags = (page): any => {
    const url = AppConstant.apiUrl + AppConstant.seoDataApi + '?url=' + AppConstant.seoBaseUrl + page;
    this._http.get(url).subscribe((response: any) => {
      if (response.status == 'success') {
        this.titleService.setTitle(response.data?.metaTitle);
        this.metaService.updateTag({name: 'description', content: response.data?.metaDescription});
        this.metaService.updateTag({name: 'keywords', content: response.data?.metaKeyword});
        return response.data;
      }
    }, () => {
      return false;
    });
    return false;
  }

  public seoTagAndMeta = (page) => {
    page = page.replace(new RegExp('\\b' + 'en/' + '\\b'), '');
    if (page.indexOf('/') == 0) {
      page = page.substring(1);
    }
    const url = AppConstant.apiUrl + AppConstant.seoDataApi + '?url=' + AppConstant.seoBaseUrl + page;
    return this._http.get(url);
  }

  public setTitle = (title) => {
    this.titleService.setTitle(title);
  }

  public updateTag = (name, content) => {
    this.metaService.updateTag({name, content});
  }

  public addTag = (name, content) => {
    this.metaService.addTag({name, content});
  }

  public getBrowserTimeZoneCountry() {
    var userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (userTimeZone == 'Asia/Calcutta') {
      userTimeZone = 'Asia/Kolkata';
    } else if (userTimeZone == 'Asia/Katmandu') {
      userTimeZone = 'Asia/Kathmandu';
    }
    var country = this.getMobileCode().find((country) => country.timeZone === userTimeZone);
    if (!country) {
      country = {name: 'India', dial_code: '+91', code: 'IN', timeZone: 'Asia/Kolkata'};
    }

    return country;
  }

  public getMobileCode = () => {
    return [
      {
        name: 'Afghanistan',
        dial_code: '+93',
        code: 'AF',
        timeZone: 'Asia/Kabul'
      },
      {
        name: 'Aland Islands',
        dial_code: '+358',
        code: 'AX',
        timeZone: 'Europe/Mariehamn'
      },
      {
        name: 'Albania',
        dial_code: '+355',
        code: 'AL',
        timeZone: 'Europe/Tirane'
      },
      {
        name: 'Algeria',
        dial_code: '+213',
        code: 'DZ',
        timeZone: 'Africa/Algiers'
      },
      {
        name: 'American Samoa',
        dial_code: '+1 684',
        code: 'AS',
        timeZone: 'Pacific/Pago_Pago'
      },
      {
        name: 'Andorra',
        dial_code: '+376',
        code: 'AD',
        timeZone: 'Europe/Andorra'
      },
      {
        name: 'Angola',
        dial_code: '+244',
        code: 'AO',
        timeZone: 'Africa/Luanda'
      },
      {
        name: 'Anguilla',
        dial_code: '+1 264',
        code: 'AI',
        timeZone: 'America/Anguilla'
      },
      {
        name: 'Antarctica',
        dial_code: '+672',
        code: 'AQ',
        timeZone: 'Antarctica/Palmer'
      },
      {
        name: 'Antigua and Barbuda',
        dial_code: '+1268',
        code: 'AG',
        timeZone: 'America/Antigua'
      },
      {
        name: 'Argentina',
        dial_code: '+54',
        code: 'AR',
        timeZone: 'America/Argentina/Buenos_Aires'
      },
      {
        name: 'Armenia',
        dial_code: '+374',
        code: 'AM',
        timeZone: 'Asia/Yerevan'
      },
      {
        name: 'Aruba',
        dial_code: '+297',
        code: 'AW',
        timeZone: 'America/Aruba'
      },
      {
        name: 'Australia',
        dial_code: '+61',
        code: 'AU',
        timeZone: 'Australia/Sydney'
      },
      {
        name: 'Austria',
        dial_code: '+43',
        code: 'AT',
        timeZone: 'Europe/Vienna'
      },
      {
        name: 'Azerbaijan',
        dial_code: '+994',
        code: 'AZ',
        timeZone: 'Asia/Baku'
      },
      {
        name: 'Bahamas',
        dial_code: '+1 242',
        code: 'BS',
        timeZone: 'America/Nassau'
      },
      {
        name: 'Bahrain',
        dial_code: '+973',
        code: 'BH',
        timeZone: 'Asia/Bahrain'
      },
      {
        name: 'Bangladesh',
        dial_code: '+880',
        code: 'BD',
        timeZone: 'Asia/Dhaka'
      },
      {
        name: 'Barbados',
        dial_code: '+1 246',
        code: 'BB',
        timeZone: 'America/Barbados'
      },
      {
        name: 'Belarus',
        dial_code: '+375',
        code: 'BY',
        timeZone: 'Europe/Minsk'
      },
      {
        name: 'Belgium',
        dial_code: '+32',
        code: 'BE',
        timeZone: 'Europe/Brussels'
      },
      {
        name: 'Belize',
        dial_code: '+501',
        code: 'BZ',
        timeZone: 'America/Belize'
      },
      {
        name: 'Benin',
        dial_code: '+229',
        code: 'BJ',
        timeZone: 'Africa/Porto-Novo'
      },
      {
        name: 'Bermuda',
        dial_code: '+1 441',
        code: 'BM',
        timeZone: 'Atlantic/Bermuda'
      },
      {
        name: 'Bhutan',
        dial_code: '+975',
        code: 'BT',
        timeZone: 'Asia/Thimphu'
      },
      {
        name: 'Bolivia, Plurinational State of',
        dial_code: '+591',
        code: 'BO',
        timeZone: 'America/La_Paz'
      },
      {
        name: 'Bosnia and Herzegovina',
        dial_code: '+387',
        code: 'BA',
        timeZone: 'Europe/Sarajevo'
      },
      {
        name: 'Botswana',
        dial_code: '+267',
        code: 'BW',
        timeZone: 'Africa/Gaborone'
      },
      {
        name: 'Brazil',
        dial_code: '+55',
        code: 'BR',
        timeZone: 'America/Sao_Paulo'
      },
      {
        name: 'British Indian Ocean Territory',
        dial_code: '+246',
        code: 'IO',
        timeZone: 'Indian/Chagos'
      },
      {
        name: 'Brunei Darussalam',
        dial_code: '+673',
        code: 'BN',
        timeZone: 'Asia/Brunei'
      },
      {
        name: 'Bulgaria',
        dial_code: '+359',
        code: 'BG',
        timeZone: 'Europe/Sofia'
      },
      {
        name: 'Burkina Faso',
        dial_code: '+226',
        code: 'BF',
        timeZone: 'Africa/Ouagadougou'
      },
      {
        name: 'Burundi',
        dial_code: '+257',
        code: 'BI',
        timeZone: 'Africa/Bujumbura'
      },
      {
        name: 'Cambodia',
        dial_code: '+855',
        code: 'KH',
        timeZone: 'Asia/Phnom_Penh'
      },
      {
        name: 'Cameroon',
        dial_code: '+237',
        code: 'CM',
        timeZone: 'Africa/Douala'
      },
      {
        name: 'Canada',
        dial_code: '+1',
        code: 'CA',
        timeZone: 'America/Toronto'
      },
      {
        name: 'Cape Verde',
        dial_code: '+238',
        code: 'CV',
        timeZone: 'Atlantic/Cape_Verde'
      },
      {
        name: 'Cayman Islands',
        dial_code: '+1 345',
        code: 'KY',
        timeZone: 'America/Cayman'
      },
      {
        name: 'Central African Republic',
        dial_code: '+236',
        code: 'CF',
        timeZone: 'Africa/Bangui'
      },
      {
        name: 'Chad',
        dial_code: '+235',
        code: 'TD',
        timeZone: 'Africa/Ndjamena'
      },
      {
        name: 'Chile',
        dial_code: '+56',
        code: 'CL',
        timeZone: 'America/Santiago'
      },
      {
        name: 'China',
        dial_code: '+86',
        code: 'CN',
        timeZone: 'Asia/Shanghai'
      },
      {
        name: 'Christmas Island',
        dial_code: '+61',
        code: 'CX',
        timeZone: 'Indian/Christmas'
      },
      {
        name: 'Cocos (Keeling) Islands',
        dial_code: '+61',
        code: 'CC',
        timeZone: 'Indian/Cocos'
      },
      {
        name: 'Colombia',
        dial_code: '+57',
        code: 'CO',
        timeZone: 'America/Bogota'
      },
      {
        name: 'Comoros',
        dial_code: '+269',
        code: 'KM',
        timeZone: 'Indian/Comoro'
      },
      {
        name: 'Congo',
        dial_code: '+242',
        code: 'CG',
        timeZone: 'Africa/Brazzaville'
      },
      {
        name: 'Congo, The Democratic Republic of the',
        dial_code: '+243',
        code: 'CD',
        timeZone: 'Africa/Kinshasa'
      },
      {
        name: 'Cook Islands',
        dial_code: '+682',
        code: 'CK',
        timeZone: 'Pacific/Rarotonga'
      },
      {
        name: 'Costa Rica',
        dial_code: '+506',
        code: 'CR',
        timeZone: 'America/Costa_Rica'
      },
      {
        name: 'Cote d\'Ivoire',
        dial_code: '+225',
        code: 'CI',
        timeZone: 'Africa/Abidjan'
      },
      {
        name: 'Croatia',
        dial_code: '+385',
        code: 'HR',
        timeZone: 'Europe/Zagreb'
      },
      {
        name: 'Cuba',
        dial_code: '+53',
        code: 'CU',
        timeZone: 'America/Havana'
      },
      {
        name: 'Cyprus',
        dial_code: '+357',
        code: 'CY',
        timeZone: 'Asia/Nicosia'
      },
      {
        name: 'Czech Republic',
        dial_code: '+420',
        code: 'CZ',
        timeZone: 'Europe/Prague'
      },
      {
        name: 'Denmark',
        dial_code: '+45',
        code: 'DK',
        timeZone: 'Europe/Copenhagen'
      },
      {
        name: 'Djibouti',
        dial_code: '+253',
        code: 'DJ',
        timeZone: 'Africa/Djibouti'
      },
      {
        name: 'Dominica',
        dial_code: '+1 767',
        code: 'DM',
        timeZone: 'America/Dominica'
      },
      {
        name: 'Dominican Republic',
        dial_code: '+1 809',
        code: 'DO',
        timeZone: 'America/Santo_Domingo'
      },
      {
        name: 'Ecuador',
        dial_code: '+593',
        code: 'EC',
        timeZone: 'America/Guayaquil'
      },
      {
        name: 'Egypt',
        dial_code: '+20',
        code: 'EG',
        timeZone: 'Africa/Cairo'
      },
      {
        name: 'El Salvador',
        dial_code: '+503',
        code: 'SV',
        timeZone: 'America/El_Salvador'
      },
      {
        name: 'Equatorial Guinea',
        dial_code: '+240',
        code: 'GQ',
        timeZone: 'Africa/Malabo'
      },
      {
        name: 'Eritrea',
        dial_code: '+291',
        code: 'ER',
        timeZone: 'Africa/Asmara'
      },
      {
        name: 'Estonia',
        dial_code: '+372',
        code: 'EE',
        timeZone: 'Europe/Tallinn'
      },
      {
        name: 'Ethiopia',
        dial_code: '+251',
        code: 'ET',
        timeZone: 'Africa/Addis_Ababa'
      },
      {
        name: 'Falkland Islands (Malvinas)',
        dial_code: '+500',
        code: 'FK',
        timeZone: 'Atlantic/Stanley'
      },
      {
        name: 'Faroe Islands',
        dial_code: '+298',
        code: 'FO',
        timeZone: 'Atlantic/Faroe'
      },
      {
        name: 'Fiji',
        dial_code: '+679',
        code: 'FJ',
        timeZone: 'Pacific/Fiji'
      },
      {
        name: 'Finland',
        dial_code: '+358',
        code: 'FI',
        timeZone: 'Europe/Helsinki'
      },
      {
        name: 'France',
        dial_code: '+33',
        code: 'FR',
        timeZone: 'Europe/Paris'
      },
      {
        name: 'French Guiana',
        dial_code: '+594',
        code: 'GF',
        timeZone: 'America/Cayenne'
      },
      {
        name: 'French Polynesia',
        dial_code: '+689',
        code: 'PF',
        timeZone: 'Pacific/Tahiti'
      },
      {
        name: 'Gabon',
        dial_code: '+241',
        code: 'GA',
        timeZone: 'Africa/Libreville'
      },
      {
        name: 'Gambia',
        dial_code: '+220',
        code: 'GM',
        timeZone: 'Africa/Banjul'
      },
      {
        name: 'Georgia',
        dial_code: '+995',
        code: 'GE',
        timeZone: 'Asia/Tbilisi'
      },
      {
        name: 'Germany',
        dial_code: '+49',
        code: 'DE',
        timeZone: 'Europe/Berlin'
      },
      {
        name: 'Ghana',
        dial_code: '+233',
        code: 'GH',
        timeZone: 'Africa/Accra'
      },
      {
        name: 'Gibraltar',
        dial_code: '+350',
        code: 'GI',
        timeZone: 'Europe/Gibraltar'
      },
      {
        name: 'Greece',
        dial_code: '+30',
        code: 'GR',
        timeZone: 'Europe/Athens'
      },
      {
        name: 'Greenland',
        dial_code: '+299',
        code: 'GL',
        timeZone: 'America/Godthab'
      },
      {
        name: 'Grenada',
        dial_code: '+1 473',
        code: 'GD',
        timeZone: 'America/Grenada'
      },
      {
        name: 'Guadeloupe',
        dial_code: '+590',
        code: 'GP',
        timeZone: 'America/Guadeloupe'
      },
      {
        name: 'Guam',
        dial_code: '+1 671',
        code: 'GU',
        timeZone: 'Pacific/Guam'
      },
      {
        name: 'Guatemala',
        dial_code: '+502',
        code: 'GT',
        timeZone: 'America/Guatemala'
      },
      {
        name: 'Guernsey',
        dial_code: '+44',
        code: 'GG',
        timeZone: 'Europe/Guernsey'
      },
      {
        name: 'Guinea',
        dial_code: '+224',
        code: 'GN',
        timeZone: 'Africa/Conakry'
      },
      {
        name: 'Guinea-Bissau',
        dial_code: '+245',
        code: 'GW',
        timeZone: 'Africa/Bissau'
      },
      {
        name: 'Guyana',
        dial_code: '+595',
        code: 'GY',
        timeZone: 'America/Guyana'
      },
      {
        name: 'Haiti',
        dial_code: '+509',
        code: 'HT',
        timeZone: 'America/Port-au-Prince'
      },
      {
        name: 'Holy See (Vatican City State)',
        dial_code: '+379',
        code: 'VA',
        timeZone: 'Europe/Vatican'
      },
      {
        name: 'Honduras',
        dial_code: '+504',
        code: 'HN',
        timeZone: 'America/Tegucigalpa'
      },
      {
        name: 'Hong Kong',
        dial_code: '+852',
        code: 'HK',
        timeZone: 'Asia/Hong_Kong'
      },
      {
        name: 'Hungary',
        dial_code: '+36',
        code: 'HU',
        timeZone: 'Europe/Budapest'
      },
      {
        name: 'Iceland',
        dial_code: '+354',
        code: 'IS',
        timeZone: 'Atlantic/Reykjavik'
      },
      {
        name: 'India',
        dial_code: '+91',
        code: 'IN',
        timeZone: 'Asia/Kolkata'
      },
      {
        name: 'Indonesia',
        dial_code: '+62',
        code: 'ID',
        timeZone: 'Asia/Jakarta'
      },
      {
        name: 'Iran, Islamic Republic of',
        dial_code: '+98',
        code: 'IR',
        timeZone: 'Asia/Tehran'
      },
      {
        name: 'Iraq',
        dial_code: '+964',
        code: 'IQ',
        timeZone: 'Asia/Baghdad'
      },
      {
        name: 'Ireland',
        dial_code: '+353',
        code: 'IE',
        timeZone: 'Europe/Dublin'
      },
      {
        name: 'Isle of Man',
        dial_code: '+44',
        code: 'IM',
        timeZone: 'Europe/Isle_of_Man'
      },
      {
        name: 'Israel',
        dial_code: '+972',
        code: 'IL',
        timeZone: 'Asia/Jerusalem'
      },
      {
        name: 'Italy',
        dial_code: '+39',
        code: 'IT',
        timeZone: 'Europe/Rome'
      },
      {
        name: 'Jamaica',
        dial_code: '+1 876',
        code: 'JM',
        timeZone: 'America/Jamaica'
      },
      {
        name: 'Japan',
        dial_code: '+81',
        code: 'JP',
        timeZone: 'Asia/Tokyo'
      },
      {
        name: 'Jersey',
        dial_code: '+44',
        code: 'JE',
        timeZone: 'Europe/Jersey'
      },
      {
        name: 'Jordan',
        dial_code: '+962',
        code: 'JO',
        timeZone: 'Asia/Amman'
      },
      {
        name: 'Kazakhstan',
        dial_code: '+7 7',
        code: 'KZ',
        timeZone: 'Asia/Almaty'
      },
      {
        name: 'Kenya',
        dial_code: '+254',
        code: 'KE',
        timeZone: 'Africa/Nairobi'
      },
      {
        name: 'Kiribati',
        dial_code: '+686',
        code: 'KI',
        timeZone: 'Pacific/Tarawa'
      },
      {
        name: 'Korea, Democratic People\'s Republic of',
        dial_code: '+850',
        code: 'KP',
        timeZone: 'Asia/Pyongyang'
      },
      {
        name: 'Korea, Republic of',
        dial_code: '+82',
        code: 'KR',
        timeZone: 'Asia/Seoul'
      },
      {
        name: 'Kuwait',
        dial_code: '+965',
        code: 'KW',
        timeZone: 'Asia/Kuwait'
      },
      {
        name: 'Kyrgyzstan',
        dial_code: '+996',
        code: 'KG',
        timeZone: 'Asia/Bishkek'
      },
      {
        name: 'Lao People\'s Democratic Republic',
        dial_code: '+856',
        code: 'LA',
        timeZone: 'Asia/Vientiane'
      },
      {
        name: 'Latvia',
        dial_code: '+371',
        code: 'LV',
        timeZone: 'Europe/Riga'
      },
      {
        name: 'Lebanon',
        dial_code: '+961',
        code: 'LB',
        timeZone: 'Asia/Beirut'
      },
      {
        name: 'Lesotho',
        dial_code: '+266',
        code: 'LS',
        timeZone: 'Africa/Maseru'
      },
      {
        name: 'Liberia',
        dial_code: '+231',
        code: 'LR',
        timeZone: 'Africa/Monrovia'
      },
      {
        name: 'Libyan Arab Jamahiriya',
        dial_code: '+218',
        code: 'LY',
        timeZone: 'Africa/Tripoli'
      },
      {
        name: 'Liechtenstein',
        dial_code: '+423',
        code: 'LI',
        timeZone: 'Europe/Vaduz'
      },
      {
        name: 'Lithuania',
        dial_code: '+370',
        code: 'LT',
        timeZone: 'Europe/Vilnius'
      },
      {
        name: 'Luxembourg',
        dial_code: '+352',
        code: 'LU',
        timeZone: 'Europe/Luxembourg'
      },
      {
        name: 'Macao',
        dial_code: '+853',
        code: 'MO',
        timeZone: 'Asia/Macau'
      },
      {
        name: 'Macedonia, The Former Yugoslav Republic of',
        dial_code: '+389',
        code: 'MK',
        timeZone: 'Europe/Skopje'
      },
      {
        name: 'Madagascar',
        dial_code: '+261',
        code: 'MG',
        timeZone: 'Indian/Antananarivo'
      },
      {
        name: 'Malawi',
        dial_code: '+265',
        code: 'MW',
        timeZone: 'Africa/Blantyre'
      },
      {
        name: 'Malaysia',
        dial_code: '+60',
        code: 'MY',
        timeZone: 'Asia/Kuala_Lumpur'
      },
      {
        name: 'Maldives',
        dial_code: '+960',
        code: 'MV',
        timeZone: 'Indian/Maldives'
      },
      {
        name: 'Mali',
        dial_code: '+223',
        code: 'ML',
        timeZone: 'Africa/Bamako'
      },
      {
        name: 'Malta',
        dial_code: '+356',
        code: 'MT',
        timeZone: 'Europe/Malta'
      },
      {
        name: 'Marshall Islands',
        dial_code: '+692',
        code: 'MH',
        timeZone: 'Pacific/Majuro'
      },
      {
        name: 'Martinique',
        dial_code: '+596',
        code: 'MQ',
        timeZone: 'America/Martinique'
      },
      {
        name: 'Mauritania',
        dial_code: '+222',
        code: 'MR',
        timeZone: 'Africa/Nouakchott'
      },
      {
        name: 'Mauritius',
        dial_code: '+230',
        code: 'MU',
        timeZone: 'Indian/Mauritius'
      },
      {
        name: 'Mayotte',
        dial_code: '+262',
        code: 'YT',
        timeZone: 'Indian/Mayotte'
      },
      {
        name: 'Mexico',
        dial_code: '+52',
        code: 'MX',
        timeZone: 'America/Mexico_City'
      },
      {
        name: 'Micronesia, Federated States of',
        dial_code: '+691',
        code: 'FM',
        timeZone: 'Pacific/Chuuk'
      },
      {
        name: 'Moldova, Republic of',
        dial_code: '+373',
        code: 'MD',
        timeZone: 'Europe/Chisinau'
      },
      {
        name: 'Monaco',
        dial_code: '+377',
        code: 'MC',
        timeZone: 'Europe/Monaco'
      },
      {
        name: 'Mongolia',
        dial_code: '+976',
        code: 'MN',
        timeZone: 'Asia/Ulaanbaatar'
      },
      {
        name: 'Montenegro',
        dial_code: '+382',
        code: 'ME',
        timeZone: 'Europe/Podgorica'
      },
      {
        name: 'Montserrat',
        dial_code: '+1664',
        code: 'MS',
        timeZone: 'America/Montserrat'
      },
      {
        name: 'Morocco',
        dial_code: '+212',
        code: 'MA',
        timeZone: 'Africa/Casablanca'
      },
      {
        name: 'Mozambique',
        dial_code: '+258',
        code: 'MZ',
        timeZone: 'Africa/Maputo'
      },
      {
        name: 'Myanmar',
        dial_code: '+95',
        code: 'MM',
        timeZone: 'Asia/Yangon'
      },
      {
        name: 'Namibia',
        dial_code: '+264',
        code: 'NA',
        timeZone: 'Africa/Windhoek'
      },
      {
        name: 'Nauru',
        dial_code: '+674',
        code: 'NR',
        timeZone: 'Pacific/Nauru'
      },
      {
        name: 'Nepal',
        dial_code: '+977',
        code: 'NP',
        timeZone: 'Asia/Kathmandu'
      },
      {
        name: 'Netherlands',
        dial_code: '+31',
        code: 'NL',
        timeZone: 'Europe/Amsterdam'
      },
      {
        name: 'New Caledonia',
        dial_code: '+687',
        code: 'NC',
        timeZone: 'Pacific/Noumea'
      },
      {
        name: 'New Zealand',
        dial_code: '+64',
        code: 'NZ',
        timeZone: 'Pacific/Auckland'
      },
      {
        name: 'Nicaragua',
        dial_code: '+505',
        code: 'NI',
        timeZone: 'America/Managua'
      },
      {
        name: 'Niger',
        dial_code: '+227',
        code: 'NE',
        timeZone: 'Africa/Niamey'
      },
      {
        name: 'Nigeria',
        dial_code: '+234',
        code: 'NG',
        timeZone: 'Africa/Lagos'
      },
      {
        name: 'Niue',
        dial_code: '+683',
        code: 'NU',
        timeZone: 'Pacific/Niue'
      },
      {
        name: 'Norfolk Island',
        dial_code: '+672',
        code: 'NF',
        timeZone: 'Pacific/Norfolk'
      },
      {
        name: 'Northern Mariana Islands',
        dial_code: '+1 670',
        code: 'MP',
        timeZone: 'Pacific/Saipan'
      },
      {
        name: 'Norway',
        dial_code: '+47',
        code: 'NO',
        timeZone: 'Europe/Oslo'
      },
      {
        name: 'Oman',
        dial_code: '+968',
        code: 'OM',
        timeZone: 'Asia/Muscat'
      },
      {
        name: 'Pakistan',
        dial_code: '+92',
        code: 'PK',
        timeZone: 'Asia/Karachi'
      },
      {
        name: 'Palau',
        dial_code: '+680',
        code: 'PW',
        timeZone: 'Pacific/Palau'
      },
      {
        name: 'Palestinian Territory, Occupied',
        dial_code: '+970',
        code: 'PS',
        timeZone: 'Asia/Gaza'
      },
      {
        name: 'Panama',
        dial_code: '+507',
        code: 'PA',
        timeZone: 'America/Panama'
      },
      {
        name: 'Papua New Guinea',
        dial_code: '+675',
        code: 'PG',
        timeZone: 'Pacific/Port_Moresby'
      },
      {
        name: 'Paraguay',
        dial_code: '+595',
        code: 'PY',
        timeZone: 'America/Asuncion'
      },
      {
        name: 'Peru',
        dial_code: '+51',
        code: 'PE',
        timeZone: 'America/Lima'
      },
      {
        name: 'Philippines',
        dial_code: '+63',
        code: 'PH',
        timeZone: 'Asia/Manila'
      },
      {
        name: 'Pitcairn',
        dial_code: '+870',
        code: 'PN',
        timeZone: 'Pacific/Pitcairn'
      },
      {
        name: 'Poland',
        dial_code: '+48',
        code: 'PL',
        timeZone: 'Europe/Warsaw'
      },
      {
        name: 'Portugal',
        dial_code: '+351',
        code: 'PT',
        timeZone: 'Europe/Lisbon'
      },
      {
        name: 'Puerto Rico',
        dial_code: '+1 787',
        code: 'PR',
        timeZone: 'America/Puerto_Rico'
      },
      {
        name: 'Qatar',
        dial_code: '+974',
        code: 'QA',
        timeZone: 'Asia/Qatar'
      },
      {
        name: 'Réunion',
        dial_code: '+262',
        code: 'RE',
        timeZone: 'Indian/Reunion'
      },
      {
        name: 'Romania',
        dial_code: '+40',
        code: 'RO',
        timeZone: 'Europe/Bucharest'
      },
      {
        name: 'Russian Federation',
        dial_code: '+7',
        code: 'RU',
        timeZone: 'Europe/Moscow'
      },
      {
        name: 'Rwanda',
        dial_code: '+250',
        code: 'RW',
        timeZone: 'Africa/Kigali'
      },
      {
        name: 'Saint Barthélemy',
        dial_code: '+590',
        code: 'BL',
        timeZone: 'America/St_Barthelemy'
      },
      {
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
        dial_code: '+290',
        code: 'SH',
        timeZone: 'Atlantic/St_Helena'
      },
      {
        name: 'Saint Kitts and Nevis',
        dial_code: '+1 869',
        code: 'KN',
        timeZone: 'America/St_Kitts'
      },
      {
        name: 'Saint Lucia',
        dial_code: '+1 758',
        code: 'LC',
        timeZone: 'America/St_Lucia'
      },
      {
        name: 'Saint Martin',
        dial_code: '+590',
        code: 'MF',
        timeZone: 'America/Marigot'
      },
      {
        name: 'Saint Pierre and Miquelon',
        dial_code: '+508',
        code: 'PM',
        timeZone: 'America/Miquelon'
      },
      {
        name: 'Saint Vincent and the Grenadines',
        dial_code: '+1 784',
        code: 'VC',
        timeZone: 'America/St_Vincent'
      },
      {
        name: 'Samoa',
        dial_code: '+685',
        code: 'WS',
        timeZone: 'Pacific/Apia'
      },
      {
        name: 'San Marino',
        dial_code: '+378',
        code: 'SM',
        timeZone: 'Europe/San_Marino'
      },
      {
        name: 'Sao Tome and Principe',
        dial_code: '+239',
        code: 'ST',
        timeZone: 'Africa/Sao_Tome'
      },
      {
        name: 'Saudi Arabia',
        dial_code: '+966',
        code: 'SA',
        timeZone: 'Asia/Riyadh'
      },
      {
        name: 'Senegal',
        dial_code: '+221',
        code: 'SN',
        timeZone: 'Africa/Dakar'
      },
      {
        name: 'Serbia',
        dial_code: '+381',
        code: 'RS',
        timeZone: 'Europe/Belgrade'
      },
      {
        name: 'Seychelles',
        dial_code: '+248',
        code: 'SC',
        timeZone: 'Indian/Mahe'
      },
      {
        name: 'Sierra Leone',
        dial_code: '+232',
        code: 'SL',
        timeZone: 'Africa/Freetown'
      },
      {
        name: 'Singapore',
        dial_code: '+65',
        code: 'SG',
        timeZone: 'Asia/Singapore'
      },
      {
        name: 'Sint Maarten',
        dial_code: '+1 721',
        code: 'SX',
        timeZone: 'America/Lower_Princes'
      },
      {
        name: 'Slovakia',
        dial_code: '+421',
        code: 'SK',
        timeZone: 'Europe/Bratislava'
      },
      {
        name: 'Slovenia',
        dial_code: '+386',
        code: 'SI',
        timeZone: 'Europe/Ljubljana'
      },
      {
        name: 'Solomon Islands',
        dial_code: '+677',
        code: 'SB',
        timeZone: 'Pacific/Honiara'
      },
      {
        name: 'Somalia',
        dial_code: '+252',
        code: 'SO',
        timeZone: 'Africa/Mogadishu'
      },
      {
        name: 'South Africa',
        dial_code: '+27',
        code: 'ZA',
        timeZone: 'Africa/Johannesburg'
      },
      {
        name: 'South Sudan',
        dial_code: '+211',
        code: 'SS',
        timeZone: 'Africa/Juba'
      },
      {
        name: 'Spain',
        dial_code: '+34',
        code: 'ES',
        timeZone: 'Europe/Madrid'
      },
      {
        name: 'Sri Lanka',
        dial_code: '+94',
        code: 'LK',
        timeZone: 'Asia/Colombo'
      },
      {
        name: 'Sudan',
        dial_code: '+249',
        code: 'SD',
        timeZone: 'Africa/Khartoum'
      },
      {
        name: 'Suriname',
        dial_code: '+597',
        code: 'SR',
        timeZone: 'America/Paramaribo'
      },
      {
        name: 'Svalbard and Jan Mayen',
        dial_code: '+47',
        code: 'SJ',
        timeZone: 'Arctic/Longyearbyen'
      },
      {
        name: 'Swaziland',
        dial_code: '+268',
        code: 'SZ',
        timeZone: 'Africa/Mbabane'
      },
      {
        name: 'Sweden',
        dial_code: '+46',
        code: 'SE',
        timeZone: 'Europe/Stockholm'
      },
      {
        name: 'Switzerland',
        dial_code: '+41',
        code: 'CH',
        timeZone: 'Europe/Zurich'
      },
      {
        name: 'Syrian Arab Republic',
        dial_code: '+963',
        code: 'SY',
        timeZone: 'Asia/Damascus'
      },
      {
        name: 'Taiwan, Province of China',
        dial_code: '+886',
        code: 'TW',
        timeZone: 'Asia/Taipei'
      },
      {
        name: 'Tajikistan',
        dial_code: '+992',
        code: 'TJ',
        timeZone: 'Asia/Dushanbe'
      },
      {
        name: 'Tanzania, United Republic of',
        dial_code: '+255',
        code: 'TZ',
        timeZone: 'Africa/Dar_es_Salaam'
      },
      {
        name: 'Thailand',
        dial_code: '+66',
        code: 'TH',
        timeZone: 'Asia/Bangkok'
      },
      {
        name: 'Timor-Leste',
        dial_code: '+670',
        code: 'TL',
        timeZone: 'Asia/Dili'
      },
      {
        name: 'Togo',
        dial_code: '+228',
        code: 'TG',
        timeZone: 'Africa/Lome'
      },
      {
        name: 'Tokelau',
        dial_code: '+690',
        code: 'TK',
        timeZone: 'Pacific/Fakaofo'
      },
      {
        name: 'Tonga',
        dial_code: '+676',
        code: 'TO',
        timeZone: 'Pacific/Tongatapu'
      },
      {
        name: 'Trinidad and Tobago',
        dial_code: '+1 868',
        code: 'TT',
        timeZone: 'America/Port_of_Spain'
      },
      {
        name: 'Tunisia',
        dial_code: '+216',
        code: 'TN',
        timeZone: 'Africa/Tunis'
      },
      {
        name: 'Turkey',
        dial_code: '+90',
        code: 'TR',
        timeZone: 'Europe/Istanbul'
      },
      {
        name: 'Turkmenistan',
        dial_code: '+993',
        code: 'TM',
        timeZone: 'Asia/Ashgabat'
      },
      {
        name: 'Turks and Caicos Islands',
        dial_code: '+1 649',
        code: 'TC',
        timeZone: 'America/Grand_Turk'
      },
      {
        name: 'Tuvalu',
        dial_code: '+688',
        code: 'TV',
        timeZone: 'Pacific/Funafuti'
      },
      {
        name: 'Uganda',
        dial_code: '+256',
        code: 'UG',
        timeZone: 'Africa/Kampala'
      },
      {
        name: 'Ukraine',
        dial_code: '+380',
        code: 'UA',
        timeZone: 'Europe/Kiev'
      },
      {
        name: 'United Arab Emirates',
        dial_code: '+971',
        code: 'AE',
        timeZone: 'Asia/Dubai'
      },
      {
        name: 'United Kingdom',
        dial_code: '+44',
        code: 'GB',
        timeZone: 'Europe/London'
      },
      {
        name: 'United States',
        dial_code: '+1',
        code: 'US',
        timeZone: 'America/New_York'
      },
      {
        name: 'Uruguay',
        dial_code: '+598',
        code: 'UY',
        timeZone: 'America/Montevideo'
      },
      {
        name: 'Uzbekistan',
        dial_code: '+998',
        code: 'UZ',
        timeZone: 'Asia/Tashkent'
      },
      {
        name: 'Vanuatu',
        dial_code: '+678',
        code: 'VU',
        timeZone: 'Pacific/Efate'
      },
      {
        name: 'Venezuela, Bolivarian Republic of',
        dial_code: '+58',
        code: 'VE',
        timeZone: 'America/Caracas'
      },
      {
        name: 'Viet Nam',
        dial_code: '+84',
        code: 'VN',
        timeZone: 'Asia/Ho_Chi_Minh'
      },
      {
        name: 'Virgin Islands, British',
        dial_code: '+1 284',
        code: 'VG',
        timeZone: 'America/Tortola'
      },
      {
        name: 'Virgin Islands, U.S.',
        dial_code: '+1 340',
        code: 'VI',
        timeZone: 'America/St_Thomas'
      },
      {
        name: 'Wallis and Futuna',
        dial_code: '+681',
        code: 'WF',
        timeZone: 'Pacific/Wallis'
      },
      {
        name: 'Western Sahara',
        dial_code: '+212',
        code: 'EH',
        timeZone: 'Africa/El_Aaiun'
      },
      {
        name: 'Yemen',
        dial_code: '+967',
        code: 'YE',
        timeZone: 'Asia/Aden'
      },
      {
        name: 'Zambia',
        dial_code: '+260',
        code: 'ZM',
        timeZone: 'Africa/Lusaka'
      },
      {
        name: 'Zimbabwe',
        dial_code: '+263',
        code: 'ZW',
        timeZone: 'Africa/Harare'
      }
    ];
  }

  public getCountries = () => {
    return [
      {value: 'AF', key: 'Afghanistan'}
      , {value: 'AX', key: 'Aland Islands'}
      , {value: 'AL', key: 'Albania'}
      , {value: 'DZ', key: 'Algeria'}
      , {value: 'AS', key: 'American Samoa'}
      , {value: 'AD', key: 'Andorra'}
      , {value: 'AO', key: 'Angola'}
      , {value: 'AI', key: 'Anguilla'}
      , {value: 'AQ', key: 'Antarctica'}
      , {value: 'AG', key: 'Antigua and Barbuda'}
      , {value: 'AR', key: 'Argentina'}
      , {value: 'AM', key: 'Armenia'}
      , {value: 'AW', key: 'Aruba'}
      , {value: 'AU', key: 'Australia'}
      , {value: 'AT', key: 'Austria'}
      , {value: 'AZ', key: 'Azerbaijan'}
      , {value: 'BS', key: 'Bahamas'}
      , {value: 'BH', key: 'Bahrain'}
      , {value: 'BD', key: 'Bangladesh'}
      , {value: 'BB', key: 'Barbados'}
      , {value: 'BY', key: 'Belarus'}
      , {value: 'BE', key: 'Belgium'}
      , {value: 'BZ', key: 'Belize'}
      , {value: 'BJ', key: 'Benin'}
      , {value: 'BM', key: 'Bermuda'}
      , {value: 'BT', key: 'Bhutan'}
      , {value: 'BO', key: 'Bolivia'}
      , {value: 'BQ', key: 'Bonaire'}
      , {value: 'BA', key: 'Bosnia and Herzegovina'}
      , {value: 'BW', key: 'Botswana'}
      , {value: 'BV', key: 'Bouvet Island'}
      , {value: 'BR', key: 'Brazil'}
      , {value: 'IO', key: 'British Indian Ocean Territory'}
      , {value: 'BN', key: 'Brunei Darussalam'}
      , {value: 'BG', key: 'Bulgaria'}
      , {value: 'BF', key: 'Burkina fao'}
      , {value: 'BI', key: 'Burundi'}
      , {value: 'KH', key: 'Cambodia'}
      , {value: 'CM', key: 'Cameroon'}
      , {value: 'CA', key: 'Canada'}
      , {value: 'CV', key: 'Cape Verde'}
      , {value: 'KY', key: 'Cayman Islands'}
      , {value: 'CF', key: 'Central African Republic'}
      , {value: 'TD', key: 'Chad'}
      , {value: 'CL', key: 'Chile'}
      , {value: 'CN', key: 'China'}
      , {value: 'CX', key: 'Christmas Island'}
      , {value: 'CC', key: 'Cocos (Keeling) Islands'}
      , {value: 'CO', key: 'Colombia'}
      , {value: 'KM', key: 'Comoros'}
      , {value: 'CG', key: 'Congo'}
      , {value: 'CD', key: 'Congo'}
      , {value: 'CK', key: 'Cook Islands'}
      , {value: 'CR', key: 'Costa Rica'}
      , {value: 'CI', key: 'Côte d\'Ivoire'}
      , {value: 'HR', key: 'Croatia'}
      , {value: 'CU', key: 'Cuba'}
      , {value: 'CW', key: 'Curaçao'}
      , {value: 'CY', key: 'Cyprus'}
      , {value: 'CZ', key: 'Czech Republic'}
      , {value: 'DK', key: 'Denmark'}
      , {value: 'DJ', key: 'Djibouti'}
      , {value: 'DM', key: 'Dominica'}
      , {value: 'DO', key: 'Dominican Republic'}
      , {value: 'EC', key: 'Ecuador'}
      , {value: 'EG', key: 'Egypt'}
      , {value: 'SV', key: 'El Salvador'}
      , {value: 'GQ', key: 'Equatorial Guinea'}
      , {value: 'ER', key: 'Eritrea'}
      , {value: 'EE', key: 'Estonia'}
      , {value: 'ET', key: 'Ethiopia'}
      , {value: 'FK', key: 'Falkland Islands (Malvinas)'}
      , {value: 'FO', key: 'Faroe Islands'}
      , {value: 'FJ', key: 'Fiji'}
      , {value: 'FI', key: 'Finland'}
      , {value: 'FR', key: 'France'}
      , {value: 'GF', key: 'French Guiana'}
      , {value: 'PF', key: 'French Polynesia'}
      , {value: 'TF', key: 'French Southern Territories'}
      , {value: 'GA', key: 'Gabon'}
      , {value: 'GM', key: 'Gambia'}
      , {value: 'GE', key: 'Georgia'}
      , {value: 'DE', key: 'Germany'}
      , {value: 'GH', key: 'Ghana'}
      , {value: 'GI', key: 'Gibraltar'}
      , {value: 'GR', key: 'Greece'}
      , {value: 'GL', key: 'Greenland'}
      , {value: 'GD', key: 'Grenada'}
      , {value: 'GP', key: 'Guadeloupe'}
      , {value: 'GU', key: 'Guam'}
      , {value: 'GT', key: 'Guatemala'}
      , {value: 'GG', key: 'Guernsey'}
      , {value: 'GN', key: 'Guinea'}
      , {value: 'GW', key: 'Guinea-Bissau'}
      , {value: 'GY', key: 'Guyana'}
      , {value: 'HT', key: 'Haiti'}
      , {value: 'HM', key: 'Heard Island and McDonald Islands'}
      , {value: 'VA', key: 'Holy See (Vatican City State)'}
      , {value: 'HN', key: 'Honduras'}
      , {value: 'HK', key: 'Hong Kong'}
      , {value: 'HU', key: 'Hungary'}
      , {value: 'IS', key: 'Iceland'}
      , {value: 'IN', key: 'India'}
      , {value: 'ID', key: 'Indonesia'}
      , {value: 'IR', key: 'Iran'}
      , {value: 'IQ', key: 'Iraq'}
      , {value: 'IE', key: 'Ireland'}
      , {value: 'IM', key: 'Isle of Man'}
      , {value: 'IL', key: 'Israel'}
      , {value: 'IT', key: 'Italy'}
      , {value: 'JM', key: 'Jamaica'}
      , {value: 'JP', key: 'Japan'}
      , {value: 'JE', key: 'Jersey'}
      , {value: 'JO', key: 'Jordan'}
      , {value: 'KZ', key: 'Kazakhstan'}
      , {value: 'KE', key: 'Kenya'}
      , {value: 'KI', key: 'Kiribati'}
      , {value: 'KP', key: 'North Korea'}
      , {value: 'KR', key: 'South Korea'}
      , {value: 'KW', key: 'Kuwait'}
      , {value: 'KG', key: 'Kyrgyzstan'}
      , {value: 'LA', key: 'Lao Republic'}
      , {value: 'LV', key: 'Latvia'}
      , {value: 'LB', key: 'Lebanon'}
      , {value: 'LS', key: 'Lesotho'}
      , {value: 'LR', key: 'Liberia'}
      , {value: 'LY', key: 'Libya'}
      , {value: 'LI', key: 'Liechtenstein'}
      , {value: 'LT', key: 'Lithuania'}
      , {value: 'LU', key: 'Luxembourg'}
      , {value: 'MO', key: 'Macao'}
      , {value: 'MK', key: 'Macedonia'}
      , {value: 'MG', key: 'Madagascar'}
      , {value: 'MW', key: 'Malawi'}
      , {value: 'MY', key: 'Malaysia'}
      , {value: 'MV', key: 'Maldives'}
      , {value: 'ML', key: 'Mali'}
      , {value: 'MT', key: 'Malta'}
      , {value: 'MH', key: 'Marshall Islands'}
      , {value: 'MQ', key: 'Martinique'}
      , {value: 'MR', key: 'Mauritania'}
      , {value: 'MU', key: 'Mauritius'}
      , {value: 'YT', key: 'Mayotte'}
      , {value: 'MX', key: 'Mexico'}
      , {value: 'FM', key: 'Micronesia'}
      , {value: 'MD', key: 'Moldova'}
      , {value: 'MC', key: 'Monaco'}
      , {value: 'MN', key: 'Mongolia'}
      , {value: 'ME', key: 'Montenegro'}
      , {value: 'MS', key: 'Montserrat'}
      , {value: 'MA', key: 'Morocco'}
      , {value: 'MZ', key: 'Mozambique'}
      , {value: 'MM', key: 'Myanmar'}
      , {value: 'NA', key: 'Namibia'}
      , {value: 'NR', key: 'Nauru'}
      , {value: 'NP', key: 'Nepal'}
      , {value: 'NL', key: 'Netherlands'}
      , {value: 'NC', key: 'New Caledonia'}
      , {value: 'NZ', key: 'New Zealand'}
      , {value: 'NI', key: 'Nicaragua'}
      , {value: 'NE', key: 'Niger'}
      , {value: 'NG', key: 'Nigeria'}
      , {value: 'NU', key: 'Niue'}
      , {value: 'NF', key: 'Norfolk Island'}
      , {value: 'MP', key: 'Northern Mariana Islands'}
      , {value: 'NO', key: 'Norway'}
      , {value: 'OM', key: 'Oman'}
      , {value: 'PK', key: 'Pakistan'}
      , {value: 'PW', key: 'Palau'}
      , {value: 'PS', key: 'State of Palestine'}
      , {value: 'PA', key: 'Panama'}
      , {value: 'PG', key: 'Papua New Guinea'}
      , {value: 'PY', key: 'Paraguay'}
      , {value: 'PE', key: 'Peru'}
      , {value: 'PH', key: 'Philippines'}
      , {value: 'PN', key: 'Pitcairn'}
      , {value: 'PL', key: 'Poland'}
      , {value: 'PT', key: 'Portugal'}
      , {value: 'PR', key: 'Puerto Rico'}
      , {value: 'QA', key: 'Qatar'}
      , {value: 'RE', key: 'Réunion'}
      , {value: 'RO', key: 'Romania'}
      , {value: 'RU', key: 'Russian Federation'}
      , {value: 'RW', key: 'Rwanda'}
      , {value: 'BL', key: 'Saint Barthélemy'}
      , {value: 'SH', key: 'Saint Helena'}
      , {value: 'KN', key: 'Saint Kitts and Nevis'}
      , {value: 'LC', key: 'Saint Lucia'}
      , {value: 'MF', key: 'Saint Martin (French part)'}
      , {value: 'PM', key: 'Saint Pierre and Miquelon'}
      , {value: 'VC', key: 'Saint Vincent and the Grenadines'}
      , {value: 'WS', key: 'Samoa'}
      , {value: 'SM', key: 'San Marino'}
      , {value: 'ST', key: 'Sao Tome and Principe'}
      , {value: 'SA', key: 'Saudi Arabia'}
      , {value: 'SN', key: 'Senegal'}
      , {value: 'RS', key: 'Serbia'}
      , {value: 'SC', key: 'Seychelles'}
      , {value: 'SL', key: 'Sierra Leone'}
      , {value: 'SG', key: 'Singapore'}
      , {value: 'SX', key: 'Sint Maarten (Dutch part)'}
      , {value: 'SK', key: 'Slovakia'}
      , {value: 'SI', key: 'Slovenia'}
      , {value: 'SB', key: 'Solomon Islands'}
      , {value: 'SO', key: 'Somalia'}
      , {value: 'ZA', key: 'South Africa'}
      , {value: 'GS', key: 'South Georgia and the South Sandwich Islands'}
      , {value: 'SS', key: 'South Sudan'}
      , {value: 'ES', key: 'Spain'}
      , {value: 'LK', key: 'Sri Lanka'}
      , {value: 'SD', key: 'Sudan'}
      , {value: 'SR', key: 'Suriname'}
      , {value: 'SJ', key: 'Svalbard and Jan Mayen'}
      , {value: 'SZ', key: 'Swaziland'}
      , {value: 'SE', key: 'Sweden'}
      , {value: 'CH', key: 'Switzerland'}
      , {value: 'SY', key: 'Syria'}
      , {value: 'TW', key: 'Taiwan'}
      , {value: 'TJ', key: 'Tajikistan'}
      , {value: 'TZ', key: 'Tanzania'}
      , {value: 'TH', key: 'Thailand'}
      , {value: 'TL', key: 'Timor-Leste'}
      , {value: 'TG', key: 'Togo'}
      , {value: 'TK', key: 'Tokelau'}
      , {value: 'TO', key: 'Tonga'}
      , {value: 'TT', key: 'Trinidad and Tobago'}
      , {value: 'TN', key: 'Tunisia'}
      , {value: 'TR', key: 'Turkey'}
      , {value: 'TM', key: 'Turkmenistan'}
      , {value: 'TC', key: 'Turks and Caicos Islands'}
      , {value: 'TV', key: 'Tuvalu'}
      , {value: 'UG', key: 'Uganda'}
      , {value: 'UA', key: 'Ukraine'}
      , {value: 'AE', key: 'United Arab Emirates'}
      , {value: 'GB', key: 'United Kingdom'}
      , {value: 'US', key: 'United States'}
      , {value: 'UM', key: 'United States Minor Outlying Islands'}
      , {value: 'UY', key: 'Uruguay'}
      , {value: 'UZ', key: 'Uzbekistan'}
      , {value: 'VU', key: 'Vanuatu'}
      , {value: 'VE', key: 'Venezuela'}
      , {value: 'VN', key: 'Vietnam'}
      , {value: 'VG', key: 'Virgin Islands, British'}
      , {value: 'VI', key: 'Virgin Islands, U.S.'}
      , {value: 'WF', key: 'Wallis and Futuna'}
      , {value: 'EH', key: 'Western Sahara'}
      , {value: 'YE', key: 'Yemen'}
      , {value: 'ZM', key: 'Zambia'}
      , {value: 'ZW', key: 'Zimbabwe'}];
  }

  customEncode = (value) => {
    return btoa(unescape(encodeURIComponent(value)));
  }

  customDecode = (value) => {
    try {
      return decodeURIComponent(escape(atob(value)));
    } catch (e) {
      if (this.isBrowser) {
        this.toastr.error('Something went wrong with url, please go back and try again!', 'URL tempering!');
      }
    }
  }

  public static getMobileCodeByCountryCode = (countryCode) => {
    let countrycallingcode = '';
    if (countryCode == 'AD') {
      countrycallingcode = '+376';
    }
    if (countryCode == 'AE') {
      countrycallingcode = '+971';
    }
    if (countryCode == 'AF') {
      countrycallingcode = '+93';
    }
    if (countryCode == 'AG') {
      countrycallingcode = '+1268';
    }
    if (countryCode == 'AI') {
      countrycallingcode = '+1264';
    }
    if (countryCode == 'AL') {
      countrycallingcode = '+355';
    }
    if (countryCode == 'AM') {
      countrycallingcode = '+374';
    }
    if (countryCode == 'AN') {
      countrycallingcode = '+599';
    }
    if (countryCode == 'AO') {
      countrycallingcode = '+244';
    }
    if (countryCode == 'AQ') {
      countrycallingcode = '+672';
    }
    if (countryCode == 'AR') {
      countrycallingcode = '+54';
    }
    if (countryCode == 'AS') {
      countrycallingcode = '+1684';
    }
    if (countryCode == 'AT') {
      countrycallingcode = '+43';
    }
    if (countryCode == 'AU') {
      countrycallingcode = '+61';
    }
    if (countryCode == 'AW') {
      countrycallingcode = '+297';
    }
    if (countryCode == 'AZ') {
      countrycallingcode = '+994';
    }
    if (countryCode == 'BA') {
      countrycallingcode = '+387';
    }
    if (countryCode == 'BB') {
      countrycallingcode = '+1246';
    }
    if (countryCode == 'BD') {
      countrycallingcode = '+880';
    }
    if (countryCode == 'BE') {
      countrycallingcode = '+32';
    }
    if (countryCode == 'BF') {
      countrycallingcode = '+226';
    }
    if (countryCode == 'BG') {
      countrycallingcode = '+359';
    }
    if (countryCode == 'BH') {
      countrycallingcode = '+973';
    }
    if (countryCode == 'BI') {
      countrycallingcode = '+257';
    }
    if (countryCode == 'BJ') {
      countrycallingcode = '+229';
    }
    if (countryCode == 'BL') {
      countrycallingcode = '+590';
    }
    if (countryCode == 'BM') {
      countrycallingcode = '+1441';
    }
    if (countryCode == 'BN') {
      countrycallingcode = '+673';
    }
    if (countryCode == 'BO') {
      countrycallingcode = '+591';
    }
    if (countryCode == 'BR') {
      countrycallingcode = '+55';
    }
    if (countryCode == 'BS') {
      countrycallingcode = '+1242';
    }
    if (countryCode == 'BT') {
      countrycallingcode = '+975';
    }
    if (countryCode == 'BW') {
      countrycallingcode = '+267';
    }
    if (countryCode == 'BY') {
      countrycallingcode = '+375';
    }
    if (countryCode == 'BZ') {
      countrycallingcode = '+501';
    }
    if (countryCode == 'CA') {
      countrycallingcode = '+1';
    }
    if (countryCode == 'CC') {
      countrycallingcode = '+61';
    }
    if (countryCode == 'CD') {
      countrycallingcode = '+243';
    }
    if (countryCode == 'CF') {
      countrycallingcode = '+236';
    }
    if (countryCode == 'CG') {
      countrycallingcode = '+242';
    }
    if (countryCode == 'CH') {
      countrycallingcode = '+41';
    }
    if (countryCode == 'CI') {
      countrycallingcode = '+225';
    }
    if (countryCode == 'CK') {
      countrycallingcode = '+682';
    }
    if (countryCode == 'CL') {
      countrycallingcode = '+56';
    }
    if (countryCode == 'CM') {
      countrycallingcode = '+237';
    }
    if (countryCode == 'CN') {
      countrycallingcode = '+86';
    }
    if (countryCode == 'CO') {
      countrycallingcode = '+57';
    }
    if (countryCode == 'CR') {
      countrycallingcode = '+506';
    }
    if (countryCode == 'CU') {
      countrycallingcode = '+53';
    }
    if (countryCode == 'CV') {
      countrycallingcode = '+238';
    }
    if (countryCode == 'CX') {
      countrycallingcode = '+61';
    }
    if (countryCode == 'CY') {
      countrycallingcode = '+357';
    }
    if (countryCode == 'CZ') {
      countrycallingcode = '+420';
    }
    if (countryCode == 'DE') {
      countrycallingcode = '+49';
    }
    if (countryCode == 'DJ') {
      countrycallingcode = '+253';
    }
    if (countryCode == 'DK') {
      countrycallingcode = '+45';
    }
    if (countryCode == 'DM') {
      countrycallingcode = '+1767';
    }
    if (countryCode == 'DO') {
      countrycallingcode = '+1809';
    }
    if (countryCode == 'DZ') {
      countrycallingcode = '+213';
    }
    if (countryCode == 'EC') {
      countrycallingcode = '+593';
    }
    if (countryCode == 'EE') {
      countrycallingcode = '+372';
    }
    if (countryCode == 'EG') {
      countrycallingcode = '+20';
    }
    if (countryCode == 'ER') {
      countrycallingcode = '+291';
    }
    if (countryCode == 'ES') {
      countrycallingcode = '+34';
    }
    if (countryCode == 'ET') {
      countrycallingcode = '+251';
    }
    if (countryCode == 'FI') {
      countrycallingcode = '+358';
    }
    if (countryCode == 'FJ') {
      countrycallingcode = '+679';
    }
    if (countryCode == 'FK') {
      countrycallingcode = '+500';
    }
    if (countryCode == 'FM') {
      countrycallingcode = '+691';
    }
    if (countryCode == 'FO') {
      countrycallingcode = '+298';
    }
    if (countryCode == 'FR') {
      countrycallingcode = '+33';
    }
    if (countryCode == 'GA') {
      countrycallingcode = '+241';
    }
    if (countryCode == 'GB') {
      countrycallingcode = '+44';
    }
    if (countryCode == 'GD') {
      countrycallingcode = '+1473';
    }
    if (countryCode == 'GE') {
      countrycallingcode = '+995';
    }
    if (countryCode == 'GH') {
      countrycallingcode = '+233';
    }
    if (countryCode == 'GI') {
      countrycallingcode = '+350';
    }
    if (countryCode == 'GL') {
      countrycallingcode = '+299';
    }
    if (countryCode == 'GM') {
      countrycallingcode = '+220';
    }
    if (countryCode == 'GN') {
      countrycallingcode = '+224';
    }
    if (countryCode == 'GQ') {
      countrycallingcode = '+240';
    }
    if (countryCode == 'GR') {
      countrycallingcode = '+30';
    }
    if (countryCode == 'GT') {
      countrycallingcode = '+502';
    }
    if (countryCode == 'GU') {
      countrycallingcode = '+1671';
    }
    if (countryCode == 'GW') {
      countrycallingcode = '+245';
    }
    if (countryCode == 'GY') {
      countrycallingcode = '+592';
    }
    if (countryCode == 'HK') {
      countrycallingcode = '+852';
    }
    if (countryCode == 'HN') {
      countrycallingcode = '+504';
    }
    if (countryCode == 'HR') {
      countrycallingcode = '+385';
    }
    if (countryCode == 'HT') {
      countrycallingcode = '+509';
    }
    if (countryCode == 'HU') {
      countrycallingcode = '+36';
    }
    if (countryCode == 'ID') {
      countrycallingcode = '+62';
    }
    if (countryCode == 'IE') {
      countrycallingcode = '+353';
    }
    if (countryCode == 'IL') {
      countrycallingcode = '+972';
    }
    if (countryCode == 'IM') {
      countrycallingcode = '+44';
    }
    if (countryCode == 'IN') {
      countrycallingcode = '+91';
    }
    if (countryCode == 'IQ') {
      countrycallingcode = '+964';
    }
    if (countryCode == 'IR') {
      countrycallingcode = '+98';
    }
    if (countryCode == 'IS') {
      countrycallingcode = '+354';
    }
    if (countryCode == 'IT') {
      countrycallingcode = '+39';
    }
    if (countryCode == 'JM') {
      countrycallingcode = '+1876';
    }
    if (countryCode == 'JO') {
      countrycallingcode = '+962';
    }
    if (countryCode == 'JP') {
      countrycallingcode = '+81';
    }
    if (countryCode == 'KE') {
      countrycallingcode = '+254';
    }
    if (countryCode == 'KG') {
      countrycallingcode = '+996';
    }
    if (countryCode == 'KH') {
      countrycallingcode = '+855';
    }
    if (countryCode == 'KI') {
      countrycallingcode = '+686';
    }
    if (countryCode == 'KM') {
      countrycallingcode = '+269';
    }
    if (countryCode == 'KN') {
      countrycallingcode = '+1869';
    }
    if (countryCode == 'KP') {
      countrycallingcode = '+850';
    }
    if (countryCode == 'KR') {
      countrycallingcode = '+82';
    }
    if (countryCode == 'KW') {
      countrycallingcode = '+965';
    }
    if (countryCode == 'KY') {
      countrycallingcode = '+1345';
    }
    if (countryCode == 'KZ') {
      countrycallingcode = '+7';
    }
    if (countryCode == 'LA') {
      countrycallingcode = '+856';
    }
    if (countryCode == 'LB') {
      countrycallingcode = '+961';
    }
    if (countryCode == 'LC') {
      countrycallingcode = '+1758';
    }
    if (countryCode == 'LI') {
      countrycallingcode = '+423';
    }
    if (countryCode == 'LK') {
      countrycallingcode = '+94';
    }
    if (countryCode == 'LR') {
      countrycallingcode = '+231';
    }
    if (countryCode == 'LS') {
      countrycallingcode = '+266';
    }
    if (countryCode == 'LT') {
      countrycallingcode = '+370';
    }
    if (countryCode == 'LU') {
      countrycallingcode = '+352';
    }
    if (countryCode == 'LV') {
      countrycallingcode = '+371';
    }
    if (countryCode == 'LY') {
      countrycallingcode = '+218';
    }
    if (countryCode == 'MA') {
      countrycallingcode = '+212';
    }
    if (countryCode == 'MC') {
      countrycallingcode = '+377';
    }
    if (countryCode == 'MD') {
      countrycallingcode = '+373';
    }
    if (countryCode == 'ME') {
      countrycallingcode = '+382';
    }
    if (countryCode == 'MF') {
      countrycallingcode = '+1599';
    }
    if (countryCode == 'MG') {
      countrycallingcode = '+261';
    }
    if (countryCode == 'MH') {
      countrycallingcode = '+692';
    }
    if (countryCode == 'MK') {
      countrycallingcode = '+389';
    }
    if (countryCode == 'ML') {
      countrycallingcode = '+223';
    }
    if (countryCode == 'MM') {
      countrycallingcode = '+95';
    }
    if (countryCode == 'MN') {
      countrycallingcode = '+976';
    }
    if (countryCode == 'MO') {
      countrycallingcode = '+853';
    }
    if (countryCode == 'MP') {
      countrycallingcode = '+1670';
    }
    if (countryCode == 'MR') {
      countrycallingcode = '+222';
    }
    if (countryCode == 'MS') {
      countrycallingcode = '+1664';
    }
    if (countryCode == 'MT') {
      countrycallingcode = '+356';
    }
    if (countryCode == 'MU') {
      countrycallingcode = '+230';
    }
    if (countryCode == 'MV') {
      countrycallingcode = '+960';
    }
    if (countryCode == 'MW') {
      countrycallingcode = '+265';
    }
    if (countryCode == 'MX') {
      countrycallingcode = '+52';
    }
    if (countryCode == 'MY') {
      countrycallingcode = '+60';
    }
    if (countryCode == 'MZ') {
      countrycallingcode = '+258';
    }
    if (countryCode == 'NA') {
      countrycallingcode = '+264';
    }
    if (countryCode == 'NC') {
      countrycallingcode = '+687';
    }
    if (countryCode == 'NE') {
      countrycallingcode = '+227';
    }
    if (countryCode == 'NG') {
      countrycallingcode = '+234';
    }
    if (countryCode == 'NI') {
      countrycallingcode = '+505';
    }
    if (countryCode == 'NL') {
      countrycallingcode = '+31';
    }
    if (countryCode == 'NO') {
      countrycallingcode = '+47';
    }
    if (countryCode == 'NP') {
      countrycallingcode = '+977';
    }
    if (countryCode == 'NR') {
      countrycallingcode = '+674';
    }
    if (countryCode == 'NU') {
      countrycallingcode = '+683';
    }
    if (countryCode == 'NZ') {
      countrycallingcode = '+64';
    }
    if (countryCode == 'OM') {
      countrycallingcode = '+968';
    }
    if (countryCode == 'PA') {
      countrycallingcode = '+507';
    }
    if (countryCode == 'PE') {
      countrycallingcode = '+51';
    }
    if (countryCode == 'PF') {
      countrycallingcode = '+689';
    }
    if (countryCode == 'PG') {
      countrycallingcode = '+675';
    }
    if (countryCode == 'PH') {
      countrycallingcode = '+63';
    }
    if (countryCode == 'PK') {
      countrycallingcode = '+92';
    }
    if (countryCode == 'PL') {
      countrycallingcode = '+48';
    }
    if (countryCode == 'PM') {
      countrycallingcode = '+508';
    }
    if (countryCode == 'PN') {
      countrycallingcode = '+870';
    }
    if (countryCode == 'PR') {
      countrycallingcode = '+1';
    }
    if (countryCode == 'PT') {
      countrycallingcode = '+351';
    }
    if (countryCode == 'PW') {
      countrycallingcode = '+680';
    }
    if (countryCode == 'PY') {
      countrycallingcode = '+595';
    }
    if (countryCode == 'QA') {
      countrycallingcode = '+974';
    }
    if (countryCode == 'RO') {
      countrycallingcode = '+40';
    }
    if (countryCode == 'RS') {
      countrycallingcode = '+381';
    }
    if (countryCode == 'RU') {
      countrycallingcode = '+7';
    }
    if (countryCode == 'RW') {
      countrycallingcode = '+250';
    }
    if (countryCode == 'SA') {
      countrycallingcode = '+966';
    }
    if (countryCode == 'SB') {
      countrycallingcode = '+677';
    }
    if (countryCode == 'SC') {
      countrycallingcode = '+248';
    }
    if (countryCode == 'SD') {
      countrycallingcode = '+249';
    }
    if (countryCode == 'SE') {
      countrycallingcode = '+46';
    }
    if (countryCode == 'SG') {
      countrycallingcode = '+65';
    }
    if (countryCode == 'SH') {
      countrycallingcode = '+290';
    }
    if (countryCode == 'SI') {
      countrycallingcode = '+386';
    }
    if (countryCode == 'SK') {
      countrycallingcode = '+421';
    }
    if (countryCode == 'SL') {
      countrycallingcode = '+232';
    }
    if (countryCode == 'SM') {
      countrycallingcode = '+378';
    }
    if (countryCode == 'SN') {
      countrycallingcode = '+221';
    }
    if (countryCode == 'SO') {
      countrycallingcode = '+252';
    }
    if (countryCode == 'SR') {
      countrycallingcode = '+597';
    }
    if (countryCode == 'ST') {
      countrycallingcode = '+239';
    }
    if (countryCode == 'SV') {
      countrycallingcode = '+503';
    }
    if (countryCode == 'SY') {
      countrycallingcode = '+963';
    }
    if (countryCode == 'SZ') {
      countrycallingcode = '+268';
    }
    if (countryCode == 'TC') {
      countrycallingcode = '+1649';
    }
    if (countryCode == 'TD') {
      countrycallingcode = '+235';
    }
    if (countryCode == 'TG') {
      countrycallingcode = '+228';
    }
    if (countryCode == 'TH') {
      countrycallingcode = '+66';
    }
    if (countryCode == 'TJ') {
      countrycallingcode = '+992';
    }
    if (countryCode == 'TK') {
      countrycallingcode = '+690';
    }
    if (countryCode == 'TL') {
      countrycallingcode = '+670';
    }
    if (countryCode == 'TM') {
      countrycallingcode = '+993';
    }
    if (countryCode == 'TN') {
      countrycallingcode = '+216';
    }
    if (countryCode == 'TO') {
      countrycallingcode = '+676';
    }
    if (countryCode == 'TR') {
      countrycallingcode = '+90';
    }
    if (countryCode == 'TT') {
      countrycallingcode = '+1868';
    }
    if (countryCode == 'TV') {
      countrycallingcode = '+688';
    }
    if (countryCode == 'TW') {
      countrycallingcode = '+886';
    }
    if (countryCode == 'TZ') {
      countrycallingcode = '+255';
    }
    if (countryCode == 'UA') {
      countrycallingcode = '+380';
    }
    if (countryCode == 'UG') {
      countrycallingcode = '+256';
    }
    if (countryCode == 'US') {
      countrycallingcode = '+1';
    }
    if (countryCode == 'UY') {
      countrycallingcode = '+598';
    }
    if (countryCode == 'UZ') {
      countrycallingcode = '+998';
    }
    if (countryCode == 'VA') {
      countrycallingcode = '+39';
    }
    if (countryCode == 'VC') {
      countrycallingcode = '+1784';
    }
    if (countryCode == 'VE') {
      countrycallingcode = '+58';
    }
    if (countryCode == 'VG') {
      countrycallingcode = '+1284';
    }
    if (countryCode == 'VI') {
      countrycallingcode = '+1340';
    }
    if (countryCode == 'VN') {
      countrycallingcode = '+84';
    }
    if (countryCode == 'VU') {
      countrycallingcode = '+678';
    }
    if (countryCode == 'WF') {
      countrycallingcode = '+681';
    }
    if (countryCode == 'WS') {
      countrycallingcode = '+685';
    }
    if (countryCode == 'XK') {
      countrycallingcode = '+381';
    }
    if (countryCode == 'YE') {
      countrycallingcode = '+967';
    }
    if (countryCode == 'YT') {
      countrycallingcode = '+262';
    }
    if (countryCode == 'ZA') {
      countrycallingcode = '+27';
    }
    if (countryCode == 'ZM') {
      countrycallingcode = '+260';
    }
    if (countryCode == 'ZW') {
      countrycallingcode = '+263';
    }
    if (countrycallingcode == '') {
      countrycallingcode = '+00';
    }
    return countrycallingcode;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded['exp'] === undefined) return null;
    const date = new Date(0);
    date.setUTCSeconds(decoded['exp'].toString());
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  dateFormat = (dateString = null) => {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    if (dateString) {
      const date = new Date(dateString);
      const month = monthNames[date.getMonth()]; // (date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
      const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
      const year = date.getFullYear();
      return day + '-' + month + '-' + year;
    } else {
      const date = new Date();
      const month = monthNames[date.getMonth()]; // (date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
      const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
      const year = date.getFullYear();
      return day + '-' + month + '-' + year;
    }
  }

  dateFormatWithSlash = (dateString = null) => {
    const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    if (dateString) {
      const date = new Date(dateString);
      const month = monthNames[date.getMonth()]; // (date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
      const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
      const year = date.getFullYear();
      return day + '/' + month + '/' + year;
    } else {
      const date = new Date();
      const month = monthNames[date.getMonth()]; // (date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
      const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
      const year = date.getFullYear();
      return day + '/' + month + '/' + year;
    }
  }

  fetchObjectFromArray(arrayName, key, value) {
    if (arrayName && value) {
      for (var elem of arrayName) {
        if (elem[key] == value) {
          return elem;
        }
      }
    }
  }

  customEncoding = (string: string, decode: boolean = false) => {
    const Base64 = {
      _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
      encode: function (input) {
        let output = '';
        let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        let i = 0;
        input = Base64._utf8_encode(input);
        while (i < input.length) {
          chr1 = input.charCodeAt(i++);
          chr2 = input.charCodeAt(i++);
          chr3 = input.charCodeAt(i++);
          enc1 = chr1 >> 2;
          enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
          enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
          enc4 = chr3 & 63;
          if (isNaN(chr2)) {
            enc3 = enc4 = 64;
          } else if (isNaN(chr3)) {
            enc4 = 64;
          }
          output = output +
            Base64._keyStr.charAt(enc1) + Base64._keyStr.charAt(enc2) +
            Base64._keyStr.charAt(enc3) + Base64._keyStr.charAt(enc4);
        }
        return output;
      },
      decode: function (input) {
        let output = '';
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');
        while (i < input.length) {
          enc1 = Base64._keyStr.indexOf(input.charAt(i++));
          enc2 = Base64._keyStr.indexOf(input.charAt(i++));
          enc3 = Base64._keyStr.indexOf(input.charAt(i++));
          enc4 = Base64._keyStr.indexOf(input.charAt(i++));
          chr1 = (enc1 << 2) | (enc2 >> 4);
          chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
          chr3 = ((enc3 & 3) << 6) | enc4;
          output = output + String.fromCharCode(chr1);
          if (enc3 != 64 && chr2 != 0) {
            output = output + String.fromCharCode(chr2);
          }
          if (enc4 != 64 && chr3 != 0) {
            output = output + String.fromCharCode(chr3);
          }
        }
        output = Base64._utf8_decode(output);
        return output;
      },

// private method for UTF-8 encoding
      _utf8_encode: function (string) {
        string = string.toString().replace(/\r\n/g, '\n');
        let utftext = '';

        for (let n = 0; n < string.length; n++) {
          const c = string.charCodeAt(n);
          if (c < 128) {
            utftext += String.fromCharCode(c);
          } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        }
        return utftext;
      },

// private method for UTF-8 decoding
      _utf8_decode: function (utftext) {
        let string = '';
        let i = 0;
        let c, c2, c3;

        while (i < utftext.length) {

          c = utftext.charCodeAt(i);

          if (c < 128) {
            string += String.fromCharCode(c);
            i++;
          } else if ((c > 191) && (c < 224)) {
            c2 = utftext.charCodeAt(i + 1);
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
          } else {
            c2 = utftext.charCodeAt(i + 1);
            c3 = utftext.charCodeAt(i + 2);
            string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
            i += 3;
          }
        }
        return string;
      }
    };
    if (decode) {
      return Base64.decode(string);
    } else {
      return Base64.encode(string);
    }
  }

  getHoroscopeName = (lang, horo) => {
    const horoscope = [
      'aries', 'taurus', 'gemini', 'cancer', 'leo', 'virgo', 'libra', 'scorpio', 'sagittarius', 'capricorn', 'aquarius', 'pisces',
      'mesh', 'vrishabh', 'mithun', 'kark', 'singh', 'kanya', 'tula', 'vrishchik', 'dhanu', 'makar', 'kumbh', 'meen',
      'mesha', 'vrushabha', 'mithuna', 'karka', 'simha', 'kanyaa', 'tulaa', 'vrushchika', 'dhanuu', 'makara', 'kumbha', 'meena'
    ];
    if (((lang == 'hi/' || lang == 'hi') && horoscope.indexOf(horo)) || ((lang == 'hi/' || lang == 'hi') && horoscope.indexOf(horo) == 0)) {
      if (horoscope.indexOf(horo) < 12) {
        return horoscope[horoscope.indexOf(horo) + 12];
      } else if (horoscope.indexOf(horo) >= 12 && horoscope.indexOf(horo) < 24) {
        return horoscope[horoscope.indexOf(horo) - 12];
      } else if (horoscope.indexOf(horo) >= 24) {
        return horoscope[horoscope.indexOf(horo) - 12];
      }
    } else if (((lang == 'kn/' || lang == 'kn') && horoscope.indexOf(horo)) || ((lang == 'kn/' || lang == 'kn') && horoscope.indexOf(horo) == 0)) {
      if (horoscope.indexOf(horo) < 12 || horoscope.indexOf(horo) == 0) {
        return horoscope[horoscope.indexOf(horo) + 24];
      } else if (horoscope.indexOf(horo) >= 12 && horoscope.indexOf(horo) < 24) {
        return horoscope[horoscope.indexOf(horo) + 12];
      } else if (horoscope.indexOf(horo) >= 24) {
        return horoscope[horoscope.indexOf(horo) - 24];
      }
    } else if (lang == '/' || lang == 'en/' || lang == '') {
      if (horoscope.indexOf(horo) < 12) {
        return horoscope[horoscope.indexOf(horo) + 12];
      } else if (horoscope.indexOf(horo) >= 12 && horoscope.indexOf(horo) < 24) {
        return horoscope[horoscope.indexOf(horo) - 12];
      } else if (horoscope.indexOf(horo) >= 24) {
        return horoscope[horoscope.indexOf(horo) - 24];
      }
    }
  }

  getVastuName = (lang, vastu) => {
    const vastuWise = [
      'vastu-for-home', 'vastu-for-plots', 'vastu-for-new-house', 'vastu-colors-for-house', 'vastu-for-commercial-properties', 'vastu-for-career-success', 'vastu-for-health', 'vastu-for-wealth', 'vastu-for-love', 'vastu-for-married-life', 'vastu-for-plants', 'vastu-for-happiness', 'vastu-for-positive-energy', 'vastu-for-peace', 'vastu-for-flats', 'vastu-for-entrance-and-main-door', 'vastu-for-bathroom', 'vastu-for-balcony', 'vastu-for-stairs', 'vastu-tips-to-bring-good-luck', 'vastu-for-paintings', 'vastu-for-children-room', 'vastu-for-kitchen', 'vastu-for-bedroom', 'bedroom-colours-according-to-vastu', 'bathroom-colours-according-to-vastu', 'colours-for-kitchen-according-to-vastu-shastra', 'vastu-for-guest-room', 'vastu-for-living-room', 'vastu-for-study-room', 'vastu-for-pooja-room', 'vastu-for-dining-room', 'vastu-products', 'vastu-for-store-room', 'vastu-for-north-facing-house', 'vastu-for-west-facing-house', 'vastu-for-east-facing-house', 'vastu-for-love-and-relationships', 'sleeping-direction-vastu-shastra', 'vastu-for-doors-and-windows', 'vastu-for-household-appliances',
      'ghar-ka-vastu', 'plot-kharidne-ke-liye-vastu', 'naye-ghar-ke-liye-vastu', 'ghar-ke-rang-ke-liye-vastu', 'vastu-for-commercial-properties-in-hindi', 'vastu-for-career-success-in-hindi', 'swasthya-aur-vastu', 'dhan-aur-vastu', 'pyar-aur-vastu', 'vaivahik-jeevan-aur-vastu', 'vastu-for-plants-in-hindi', 'vastu-for-happiness-in-hindi', 'sakaratmak-urja-aur-vastu', 'gharelu-shati-ke-liye-vastu', 'vastu-for-flats-in-hindi', 'mukhya-dwar-aur-pravesh-dwar-ke-liye-vastu-tips', 'vastu-for-bathroom-in-hindi', 'vastu-for-balcony-in-hindi', 'sidhiyon-ke-liye-vastu-tips', 'vastu-tips-to-bring-good-luck-in-hindi', 'vastu-for-paintings-in-hindi', 'bacchon-ke-room-ke-liye-vastu-tips', 'kitchen-ke-liye-vastu', 'bedroom-ke-liye-vastu', 'bedroom-ke-rang-ke-liye-vastu', 'bathroom-ke-rang-ke-liye-vastu', 'kitchen-ke-rang-ke-liye-vastu', 'vastu-for-guest-room-in-hindi', 'living-room-ke-liye-vastu', 'study-room-ke-liye-vastu-tips', 'puja-ghar-ke-liye-vastu-tips', 'vastu-for-dining-room-in-hindi', 'vastu-products-in-hindi', 'vastu-for-store-room-in-hindi', 'vastu-for-north-facing-house', 'vastu-for-west-facing-house', 'vastu-for-east-facing-house', 'vastu-for-love-and-relationships', 'sleeping-direction-vastu-shastra', 'vastu-for-doors-and-windows', 'vastu-for-household-appliances-in-hindi'
    ];
    if (lang == 'hi') {
      if (vastuWise.indexOf(vastu) < 41) {
        return vastuWise[vastuWise.indexOf(vastu) + 41];
      } else {
        return vastuWise[vastuWise.indexOf(vastu)];
      }
    } else {
      if (vastuWise.indexOf(vastu) >= 41) {
        return vastuWise[vastuWise.indexOf(vastu) - 41];
      } else {
        return vastuWise[vastuWise.indexOf(vastu)];
      }
    }
  }

  getDailyName = (lang, time) => {
    const timeWise = [
      'daily', 'weekly', 'monthly', 'yearly', 'todays', 'tomorrow', 'yesterday',
      'dainik', 'saptahik', 'masik', 'varshik', 'aaj-ka', 'tomorrow', 'yesterday',
      'dainandina', 'vaara', 'maasika', 'varshika', 'indina', 'tomorrow', 'yesterday',
    ];
    if (lang == 'hi') {
      if (time.includes('-horoscope')) {
        return timeWise[timeWise.indexOf(time.replace('-horoscope', '')) + 7];
      } else if (time.includes('-rashifal')) {
        return timeWise[timeWise.indexOf(time.replace('-rashifal', '')) - 7];
      } else if (time.includes('-rashi-bhavishya')) {
        return timeWise[timeWise.indexOf(time.replace('-rashi-bhavishya', '')) - 7];
      } else {
        if (timeWise.indexOf(time) < 7) {
          return timeWise[timeWise.indexOf(time) + 7];
        } else if (timeWise.indexOf(time) >= 7 && timeWise.indexOf(time) < 14) {
          return timeWise[timeWise.indexOf(time) - 7];
        } else if (timeWise.indexOf(time) >= 14) {
          return timeWise[timeWise.indexOf(time) - 7];
        }
      }
    } else if (lang == 'kn') {
      if (time.includes('-horoscope')) {
        return timeWise[timeWise.indexOf(time.replace('-horoscope', '')) + 14];
      } else if (time.includes('-rashifal')) {
        return timeWise[timeWise.indexOf(time.replace('-rashifal', '')) + 7];
      } else if (time.includes('-rashi-bhavishya')) {
        return timeWise[timeWise.indexOf(time.replace('-rashi-bhavishya', '')) - 14];
      } else {
        if (timeWise.indexOf(time) < 7) {
          return timeWise[timeWise.indexOf(time) + 14];
        } else if (timeWise.indexOf(time) >= 7 && timeWise.indexOf(time) < 14) {
          return timeWise[timeWise.indexOf(time) + 7];
        } else if (timeWise.indexOf(time) >= 14) {
          return timeWise[timeWise.indexOf(time) - 14];
        }
      }
    } else if (lang == '' || lang == 'en') {
      if (time.includes('-horoscope')) {
        return timeWise[timeWise.indexOf(time.replace('-horoscope', '')) + 7];
      } else if (time.includes('-rashifal')) {
        return timeWise[timeWise.indexOf(time.replace('-rashifal', '')) - 7];
      } else if (time.includes('-rashi-bhavishya')) {
        return timeWise[timeWise.indexOf(time.replace('-rashi-bhavishya', '')) - 14];
      } else {
        if (timeWise.indexOf(time) < 7) {
          return timeWise[timeWise.indexOf(time) + 7];
        } else if (timeWise.indexOf(time) >= 7 && timeWise.indexOf(time) < 14) {
          return timeWise[timeWise.indexOf(time) - 7];
        } else if (timeWise.indexOf(time) >= 14) {
          return timeWise[timeWise.indexOf(time) - 14];
        }
      }
    }
  }

  getNumeroType = (lang, type) => {
    const typeWise = [
      'tamil', 'pythagorean', 'chaldean', 'kabbalah', 'numerology-number-1', 'numerology-number-2', 'numerology-number-3', 'numerology-number-4', 'numerology-number-5', 'numerology-number-6', 'numerology-number-7', 'numerology-number-8', 'numerology-number-9', 'life-path', 'master-numbers', 'sun-numbers-numerology', 'karmic-debt', 'name-numerology', 'marriage-and-numerology', 'finance-and-numerology', 'career-and-numerology', 'food-and-numerology',
      'tamilu-sankhyashastra', 'pythagorean-sankhyashastra', 'chaldean-sankhyashastra', 'kabbalah-sankhyashastra', 'sankhyashastra-sankhye-1', 'sankhyashastra-sankhye-2', 'sankhyashastra-sankhye-3', 'sankhyashastra-sankhye-4', 'sankhyashastra-sankhye-5', 'sankhyashastra-sankhye-6', 'sankhyashastra-sankhye-7', 'sankhyashastra-sankhye-8', 'sankhyashastra-sankhye-9', 'jeewana-marga-sankhyegalu', 'master-sankhyegalu', 'surya-sankhye', 'karma-runa-sankhyegalu', 'hesaru-sankhyashastra', 'maduve-mattu-sankhyashastra', 'hanakasu-mattu-sankhyashastra', 'vrutti-mattu-sankhyashastra', 'ahara-mattu-sankhyashastra',
    ];
    if (lang == 'kn') {
      if (typeWise.indexOf(type) < 22) {
        return typeWise[typeWise.indexOf(type) + 22];
      }
    } else if (lang == 'hi') {
      if (typeWise.indexOf(type) >= 22) {
        return typeWise[typeWise.indexOf(type) - 22];
      } else {
        return typeWise[typeWise.indexOf(type)];
      }
    } else if (lang == '' || lang == 'en' || lang == '/') {
      if (typeWise.indexOf(type) >= 22) {
        return typeWise[typeWise.indexOf(type) - 22];
      } else {
        return typeWise[typeWise.indexOf(type)];
      }
    }
  }


  loadStyle(styleLink: string, elementName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const themeLink = this.document.getElementById(elementName) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleLink;
    } else {
      const style = this.document.createElement('link');
      style.id = `${elementName}`;
      style.rel = 'stylesheet';
      style.href = `${styleLink}`;
      head.appendChild(style);
    }
  }

  loadSchema(schema: any, id = 'schemaDynamic', type = 'application/ld+json') {
    const body = this.document.getElementsByTagName('body')[0];
    const dynamicSchema = this.document.getElementById(id) as HTMLLinkElement;
    if (dynamicSchema) {
      dynamicSchema.innerHTML = schema;
    } else {
      const style = this.document.createElement('script');
      style.id = id;
      style.type = type;
      style.innerHTML = `${schema}`;
      body.appendChild(style);
    }
  }

  loadScript(scriptLink: any, id) {
    const body = this.document.getElementsByTagName('body')[0];
    const dynamicScript = this.document.getElementById(id) as HTMLScriptElement;
    if (dynamicScript) {
      dynamicScript.src = scriptLink;
    } else {
      const script = this.document.createElement('script');
      script.id = id;
      script.type = 'application/javascript';
      script.src = `${scriptLink}`;
      body.appendChild(script);
    }
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });
  }

  setWithExpiry(key, value, ttl) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    this.setItem(key, JSON.stringify(item));
  }

  getWithExpiry(key) {
    const itemStr = this.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      this.storage.removeItem(key);
      return null;
    }
    return item.value;
  }

  getDataFromJson(path) {
    return this._http.get(AppConstant.frontDomain + path);
  }

  logError(error: HttpErrorResponse) {
    if (error.status == 401) {
      if (this.checkDeviceIsBrowser()) {
        this.logout();
        return false;
      }
    }
    return throwError('error your token is expired');
  }

  log(data) {
    return data;
  }

  getDeviceId() {
    return null;
  }

  isEnvironmentProd() {
    return AppConstant.production;
  }


  public callGtagOnLogin = (body) => {
    const token = this.getToken();
    const header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + (token).toString())
        .set('id', (this.getUserData('id')).toString())
    };
    return this._http.post(this.sendGtagToken, body, header);
  }

}
