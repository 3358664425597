import {Injectable} from '@angular/core';
import {HttpWrapperService} from '../global/common-file/http-wrapper.service';
import {AppConstant} from '../global/common-file/constant';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private getNotificationTypeList = AppConstant.apiUrl + AppConstant.getNotificationType;
  private updateNotificationTypeItem = AppConstant.apiUrl + AppConstant.updateNotificationType;
  private getUserLanguages = AppConstant.apiUrl + AppConstant.getUserLanguages;
  private getUserCurrency = AppConstant.apiUrl + AppConstant.getAllCurrency;
  private setCurrencyApi = AppConstant.apiUrl + AppConstant.setCurrency;
  private deleteAccount = AppConstant.apiUrl + AppConstant.deleteAccount;
  private resetPasswordApi = AppConstant.apiUrl + AppConstant.resetPasswordApi;
  // private userRegistrationSource = AppConstant.apiUrl + AppConstant.userRegistrationSource;
  // private userRegistrationSourceInputDetails = AppConstant.apiUrl + AppConstant.userRegistrationSourceInputDetails;
  // private loginViaOtp = AppConstant.apiUrl + AppConstant.otpLogin;
  private loginViaOtp4Digit = AppConstant.apiUrl + AppConstant.otpLogin4Digit;
  private otpDetails = AppConstant.apiUrl + AppConstant.otpDetails;
  private walletBalenceApi = AppConstant.apiUrl + AppConstant.walletBalenceApi;
  private getSuggestion = AppConstant.supportApiUrl + AppConstant.getCities;
  // private getSuggestion = AppConstant.kundliUrl + AppConstant.getCities;
  private getSecondPlace = AppConstant.locationApiDomain + AppConstant.getSecondPlace;
  private addNewCity = AppConstant.supportApiUrl + AppConstant.addNewCity;
  // private addNewCity = AppConstant.kundliUrl + AppConstant.addNewCity;
  private userProfile = AppConstant.apiUrl + AppConstant.userProfile;
  private updateProfile = AppConstant.apiUrl + AppConstant.updateProfile;
  private truecallerLogin = AppConstant.apiUrl + AppConstant.truecallerLogin;
  // private trueCallerToken = AppConstant.trueCallerToken;
  private loginTrueCallerApi = AppConstant.apiUrl + AppConstant.loginTrueCallerApi;
  private saveAlertLogs = AppConstant.apiUrl + AppConstant.saveAlertLogs;
  private twilioLogin = AppConstant.apiUrl + AppConstant.twilioLogin;
  private loginWithShaadiDotComApi = AppConstant.apiUrl + AppConstant.loginWithShaadiDotComApi;
  private blockedAstrologersList = AppConstant.apiUrl + AppConstant.blockedAstrologersList;
  // private loginApi = AppConstant.apiUrl + AppConstant.login; ------------> use for user name and password
  private blockUnblockAstro = AppConstant.apiUrl + AppConstant.blockAstro;

  constructor(private http: HttpWrapperService) {
  }

  getNotificationType = (params) => {
    return this.http.get(this.getNotificationTypeList, params, true);
  }

  updateNotificationType = (params) => {
    return this.http.postHeaderParams(this.updateNotificationTypeItem, params, true);
  }

  /*getTrueCallerToken = (params) => {
    return this.http.get(this.trueCallerToken, params, true);
  }*/

  /*postTrueCallerToken = (params) => {
    return this.http.postHeaderParams(this.trueCallerToken, params, true);
  }*/

  trueCallerLoginMethod = (params) => {
    return this.http.postParams(this.truecallerLogin, params, true);
  }

  getlanguages = (params) => {
    return this.http.get(this.getUserLanguages, params, true);
  }

  getCurrencies = () => {
    return this.http.get(this.getUserCurrency);
  }

  setCurrency = (params) => {
    return this.http.postParams(this.setCurrencyApi, params, true);
  }

  deleteUserAccount = (params) => {
    return this.http.postHeaderParams(this.deleteAccount, params, true);
  }

  forgotReset = (params) => {
    return this.http.postParams(this.resetPasswordApi, params);
  }

  public getCitySuggestion = (params) => {
    return this.http.get(this.getSuggestion, params, false);
  }

  public getSecondPlaces = (params) => {
    return this.http.get(this.getSecondPlace, params, false);
  }

  public addAddress = (params) => {
    return this.http.postHeaderParams(this.addNewCity, params, false);
  }

  public getUserProfile = (params) => {
    return this.http.get(this.userProfile, params, true);
  }

  public updateUserProfile = (params) => {
    return this.http.postHeaderParams(this.updateProfile, params, true);
  }

  /*loginViaOtpDetails = (params) => {
    return this.http.postParams(this.loginViaOtp, params);
  }*/

  loginVia4DigitOtpDetails = (params) => {
    return this.http.postParams(this.loginViaOtp4Digit, params);
  }

  submitOtp = (params) => {
    return this.http.postHeaderParams(this.otpDetails, params);
  }

  getBalence = (params) => {
    return this.http.get(this.walletBalenceApi, params, true);
  }

  loginTrueCaller = (params) => {
    return this.http.get(this.loginTrueCallerApi, params);
  }

  public saveLogsAlert = (params): any => {
    return this.http.postHeaderParams(this.saveAlertLogs, params);
  }

  public twilioCheck = (params): any => {
    return this.http.get(this.twilioLogin, params);
  }

  loginWithShaadiDotCom = (params) => {
    return this.http.postParams(this.loginWithShaadiDotComApi, params);
  }

  public getBlockAstrologers = (params): any => {
    return this.http.postHeaderParams(this.blockedAstrologersList, params, true);
  }

  public blockAstro = (params) => {
    return this.http.postHeaderParams(this.blockUnblockAstro, params, true);
  }
  /*public login = (body) => { ---------------------------------------> use for user name and password
    return this.http.postParams(this.loginApi, body);
  }*/
}
