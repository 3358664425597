import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {CommonService} from '../global/common-file/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private common: CommonService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.common.checkDeviceIsBrowser()) {
      let token = (this.common.getToken()) ? true : false;
      if (token) {
        if (this.common.isTokenExpired()) {
          this.common.logout();
          this.router.navigate(['/chat-with-astrologer']);
          return false;
        } else {
          return true;
        }
      } else {
        this.common.logout();
        this.router.navigate(['/chat-with-astrologer']);
        return false;
      }
    } else {
      return true;
    }
  }
}
