import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {CommonService} from './common.service';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';

@Injectable()
export class HttpWrapperService {
  token: string = null;
  userData: any = [];
  userId: string = null;

  constructor(private _http: HttpClient, private _common: CommonService, private router: Router) {
  }

  public getWithEmptyAuth(url, params = null) {
    this.token = '';
    let header = {};
    header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', '' + (this.token))
        .set('id', (params.userId.toString()))
        .set('appId', (this._common?.getAppId()).toString())
        .set('businessId', (this._common?.getBusinessId()).toString())
        .set('appVersion', this._common.getAppVersion())
    };
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this._http.get(url, header).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

  public get(url, params = null, setHeader = false) {
    this.token = this._common.getToken();
    let header = {};
    if (setHeader && this.token) {
      header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', this._common.getUserData('id').toString())
          .set('appId', (this._common?.getAppId()).toString())
          .set('businessId', (this._common?.getBusinessId()).toString())
          .set('appVersion', this._common.getAppVersion())
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this._http.get(url, header).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

  public postParams(url, params = null, setHeader = false) {
    this.userData = this._common.getUserData();
    this.token = this._common.getToken();
    let header = {};
    if (setHeader) {
      header = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', (this.userData?.id).toString())
          .set('appId', (this._common?.getAppId()).toString())
          .set('businessId', (this._common?.getBusinessId()).toString())
          .set('appVersion', this._common.getAppVersion())
      };
    }
    const formData = new FormData();
    if (params) {
      for (const param in params) {
        formData.append(param, params[param]);
      }
    }
    return this._http.post(url, formData, header).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

  public postJson(url, params = null, setHeader = false) {
    this.userData = this._common.getUserData();
    this.token = this._common.getToken();
    let header = {};
    if (setHeader) {
      header = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', (this.userData?.id).toString())
          .set('content_type', 'Application/Json')
          .set('appId', (this._common?.getAppId()).toString())
          .set('businessId', (this._common?.getBusinessId()).toString())
          .set('appVersion', this._common.getAppVersion())
      };
    }
    return this._http.post(url, params, header).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

  public post(url, body, httpHeader = false) {
    this.userData = this._common.getUserData();
    this.token = this._common.getToken();
    let header = {};
    if (httpHeader) {
      header = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', (this.userData?.id).toString())
          .set('appId', (this._common?.getAppId()).toString())
          .set('businessId', (this._common?.getBusinessId()).toString())
          .set('appVersion', this._common.getAppVersion())
      };
    }
    return this._http.post(url, body, header).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

  public postPayU(payuUrl, urlEncodedBody) {
    const headers = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(payuUrl, urlEncodedBody, { headers });
  }

  public postHeaderParams(url, params = null, setHeader = false) {
    this.userData = this._common.getUserData();
    this.token = this._common.getToken();
    let header = {};
    if (setHeader) {
      header = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', (this.userData?.id).toString())
          .set('appId', (this._common?.getAppId()).toString())
          .set('businessId', (this._common?.getBusinessId()).toString())
          .set('appVersion', this._common.getAppVersion())
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this._http.post(url, null, header).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

  public postHeaderBodyAndParams(url, params = null, body, setHeader = false) {
    this.userData = this._common.getUserData();
    this.token = this._common.getToken();
    let header = {};
    if (setHeader) {
      header = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', (this.userData?.id).toString())
          .set('appId', (this._common?.getAppId()).toString())
          .set('businessId', (this._common?.getBusinessId()).toString())
          .set('appVersion', this._common.getAppVersion())
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this._http.post(url, body, header).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

  public putParamRequest(url, params = null, setHeader = false) {
    this.userData = this._common.getUserData();
    this.token = this._common.getToken();
    let header = {};
    if (setHeader) {
      header = new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.userData?.id).toString())
        .set('appId', (this._common?.getAppId()).toString())
        .set('businessId', (this._common?.getBusinessId()).toString())
        .set('appVersion', this._common.getAppVersion());
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this._http.request('put', url, {headers: header}).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

  public delete(url, params = null, setHeader = false) {
    this.userData = this._common.getUserData();
    this.token = this._common.getToken();
    let header = {};
    if (setHeader) {
      header = new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.userData?.id).toString())
        .set('appId', (this._common?.getAppId()).toString())
        .set('businessId', (this._common?.getBusinessId()).toString())
        .set('appVersion', this._common.getAppVersion());
    }

    const formData = new FormData();
    if (params) {
      for (const param in params) {
        formData.append(param, params[param]);
      }
    }
    return this._http.request('delete', url, {headers: header, body: formData}).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

  public deleteParams(url, params = null, setHeader = false) {
    this.userData = this._common.getUserData();
    this.token = this._common.getToken();
    let header = {};
    if (setHeader) {
      header = new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.userData?.id).toString())
        .set('appId', (this._common?.getAppId()).toString())
        .set('businessId', (this._common?.getBusinessId()).toString())
        .set('appVersion', this._common.getAppVersion());
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this._http.request('delete', url, {headers: header}).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

  public deleteQueryParam(url, params = null, setHeader = false) {
    this.userData = this._common.getUserData();
    this.token = this._common.getToken();
    let header = {};
    if (setHeader) {
      header = new HttpHeaders()
        .set('Authorization', 'Bearer ' + (this.token).toString())
        .set('id', (this.userData?.id).toString())
        .set('appId', (this._common?.getAppId()).toString())
        .set('businessId', (this._common?.getBusinessId()).toString())
        .set('appVersion', this._common.getAppVersion());
    }

    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this._http.request('delete', url, {headers: header}).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }

/*
  checkVerifyToken = (setHeader = false) => {
    let url = AppConstant.apiUrl + AppConstant.verifyTokenApi;
    const params = {userId: this._common.getUserData('id')};
    this.userData = this._common.getUserData();
    this.token = this._common.getToken();
    let header = {};
    if (setHeader) {
      header = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + (this.token).toString())
          .set('id', (this.userData ? this.userData.id : '').toString())
          .set('appId', (this._common?.getAppId()).toString())
          .set('businessId', (this._common?.getBusinessId()).toString())
          .set('appVersion', this._common.getAppVersion());
      };
    }
    if (params) {
      const param = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      url = url + '?' + param;
    }
    return this._http.post(url, null, header).pipe(
      tap({
        next: (data) => this.log(data),
        error: (error) => this.logError(error)
      })
    );
  }
*/

  logError(error: HttpErrorResponse) {
    if (error.status == 401) {
      if (this._common.checkDeviceIsBrowser()) {
        this.logout();
        return false;
      }
    }
    return throwError('error your token is expired');
  }

  logout() {
    this._common.logout();
    this.router.navigate(['/']);
  }

  log(data) {
    return data;
  }
}
