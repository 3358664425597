import {environment} from '../../../environments/environment';
export const AppConstant = {
  production: environment.production,
  cdn_domain: environment.CDN_DOMAIN,
  token: environment.tokenName,
  apiUrl: environment.apiDomain, // Constant apis url,
  apiUrlForList: environment.apiDomainForList,
  chatApi: environment.chatApi,
  supportApiUrl: environment.apiDomainSupport, // Constant apis url,
  awsLink: 'https://aws.astrotalk.com/',
  awsNewLink: 'https://d1gcna0o0ldu5v.cloudfront.net/fit-in/',
  frontDomain: environment.domain,
  payULink: environment.payULink,
  payUKey: environment.payUKey,
  kundliUrl: environment.kundliApi,
  locationApiDomain: environment.LOCATIONAPIDOMAIN,
  astroMallApi: environment.astroMallApi,
  astroTvUrl: environment.astroTvApi,
  getFilter: 'filter/get',
  resetFilter: 'filter/reset',
  applyFilter: 'filter/create-update',

  googleClintId: '832708213727-nbdvd0u6p3md6eps3bc813vfsdcu7eko.apps.googleusercontent.com',
  facebookClientId: '404642660117345',
  trueCallerKey: environment.trueCallerKey,
  trueCallerName: environment.trueCallerName,

  // rateApi: 'get/conversion/rate',
  loginValueCheck: 'get/static/value',
  sendGtagToken: 'firebase/web/gtag-client-id',

  banners: 'galary/slider/get',
  bannersMobile: 'banner/get',
  latestNews: 'media/partner/get',
  topAstrologers: 'consultant/get/for/homePage',
  testimonialsVideo: 'freeAPI/consultant/get-list/Testimonial',
  testimonials: '/',
  latestBlogs: 'https://astrotalk.com/blogapi/',
  ipAddress: 'https://api.ipify.org/?format=json',
  notifications: 'notification/get/by/user',
  trustScore: 'analytics/flaunt/no',
  contactUs: 'contact/us',

  // Astromall Apis
  astroMallCategories2: 'astromall/get/product-type',
  astroMallproducts2: 'astromall/get/product',
  productsDetails2: 'astromall/get/product',
  productsImages2: 'product-type/get/product/images/V2',
  productSearch: 'product-type/product/search/global',
  intakeProduct: 'product-type/product/intake-form-fields/get',
  productConsultant: 'product-type/product/consultant/get',
  productIntakeOld: 'product-type/order/create',
  productIntake: 'astromall/create/order/v2',
  productLines: 'astromall/get/products/line',
  productQuestions: 'product-question-answer/get',
  productAddOns: 'astromall/get/product/add-on',
  productRatingReviews: 'rating-review/get/forProduct',
  getSavedAddresses: 'address/get',
  saveAddress: 'address/create',
  saveAddress2: 'address/create/v2',
  updateAddress: 'address/update',
  updateAddress2: 'address/update/v2',
  deleteSavedAddress: 'address/delete',
  buyNowAndSave: 'astromall/buy/now/v2',
  selectConsultantID: 'astromall/select/consultant/v3',
  selectAndSaveAddress: 'astromall/select/address/v2',
  selectedDetails: 'astromall/get/order/details/v2',
  rattiPrice: 'astromall/calculate/product/price',
  makePaymentFromWallet: 'universal/orders/submit-order',

  login: 'login/user',
  // mobileOtpApi: 'login/send-otp/v2',
  // mobileOtp4DigitApi: 'login/send-otp/v3',
  mobileOtp4DigitApi: 'v2/login/send-otp',
  verifyMobileOtpApi: 'login/verify-otp/v2',
  verifyTokenApi: 'isTokenExpired',
  isVerifiedMobileApi: 'user/verify-mobile/get',
  saveVerifiedMobileCallApi: 'user/verify-mobile/save',
  saveVerifiedMobilePrimaryApi: 'user/primary/verify-mobile/save',
  regApi: 'login/send-otp/v2/email',
  regOtpApi: 'login/verify-otp/v2/email',
  regFinalApi: 'login/signup/user/email',
  googleLogin: 'login/signup/user/google/v3',
  googleLoginNew: 'v2/login/user-email-mobile',
  loginWithOrWithout: 'login/user/without/login',
  forgotApi: 'user/forgot/password',
  mobileLoginFirebase: 'login/user/mobile-firebase-login/verify',

  myLanguageApi: 'language/get/by/userId',
  updateLanguageApi: 'language-user/add-update',
  deleteLanguageApi: 'language-user/delete',

  timeZoneApi: 'user/get/isForeign',

  callHistoryApi: 'calling/order/get',
  chatHistoryApi: 'chat/order/get-list/v3',
  reportHistoryApi: 'user/get/report',
  callHistoryDetails: 'calling/order/getById',
  mallHistoryPurchasedApi: 'product-type/order/history/get',
  mallHistorySuggestedApi: 'product-type/all-paid-remedies',
  mallHistoryAllApi: 'product-type/get/order/paid-remedies/for/user',
  mallHistorySingleApi: 'product-type/order/history/get/order',
  deleteSingleOrderHistory: 'order/delete/byId',
  deleteAllOrderHistory: 'order/deleteAll/byUserId',
  deleteRatings: 'rating-review/delete/rating',
  getNpsScores: 'nps/score/get',
  addUpdateNpsScores: 'nps/score/addupdate',
  poOfferShow: 'offer/v3/status',
  remedyDetails: 'prescription/getPrescriptionList',
  consultantPrice: 'consultant/get/price',
  checkStatus: 'get/status/for/call-chat/v4',
  continueChatCallApi: 'queue-token/create/chat-continue',
  policyAcceptanceByUser: 'group/chat/update/is-refund-policy-accepted-by-user',
  priceFromHistory: 'freeAPI/consultant/getAllStatusById',

  astrologerListForCallApi: 'freeAPI/consultant/get-list/filter',
  walletBalenceApi: 'wallet/getMoney',
  getRecordingApi: 'calling/order/get-call-recoding',
  getRecordingApiNew: 'calling/order/get-call-recoding/v2',
  notifyConsultantApi: 'notify-user/for/consultant-services',
  checkCallStatusApi: 'get/status/for/call-chat/v3',
  checkNextOnlineTimeApi: 'consultant/get/total/wait/time',
  intakeDataApi: 'get/lastRecord',
  postIntakeDataApi: 'calling/fill-intake-form',
  postIntakeDataChatApi: 'chat/order/fill-intake-form',
  initiateCallApi: 'call-chat/queue/create-token/v2',
  initiateCallApiSession: 'create-token/v2',

  astrologerListCityCountryState: 'freeAPI/consultant/filter/cityCountryState',
  astrologerListFilterSkill: 'freeAPI/consultant/filter/skill',

  fileUploadApi: 'api/v1/horoscope/user/upload-files',
  fireBaseToken: 'user/update/webtoken',
  fireBaseTokenNormal: 'web-without-login/add-web-token',

  waitListApi: 'queue-token/user/waitlist/get',
  cancelWaitListApi: 'call-chat/queue/cancel/token',
  chatOrderApi: 'chat/order/get/status/by/userId/consultantId',
  chatListApi: 'chat/message/get-chat-history/v3',
  lastMessageApi: 'chat/message/v2/get-latest-message/v2',
  sendChatMessage: 'chat/message/send-message/v3',
  chatEndedApi: 'chat/order/completed/v2',
  rattingApi: 'rating-review/create',
  deleteMessageApi: 'chat/message/deleteChatMsg/v2',

  createChatOrderApi: 'chat/order/create',
  chatStatusApi: 'chat/order/get/status/for/user/v2',
  chatAcceptApi: 'chat/order/accept/byUser/v2',
  chatRejectApi: 'chat/order/canceled/byUser/v2',
  unholdWaitlist: 'queue-token/user/hold/unhold',

  // horoscope Module
  getHoroscopeListV2: 'horoscope2/get',
  getHoroscopeListV3: 'horoscope3/get',

  // compatibility Module
  compatibility: 'compatibility/get/byname',

  // palmistry Module
  palmistry: 'palmistry/get',

  // Recharge Module
  deleteSingleTransaction: 'wallet/delete-unDelete/forUser',
  deleteAllTransactions: 'wallet/deleteForUser',
  getReportDetails: 'invoice/generate/invoice/receipt/wallet',
  getPriceList: 'recharge/amount/get/by/userId',
  couponData: 'coupon/isToGiveCoupon',
  paymentGatewayList: 'payment/method/get/by/user',
  startPayment: 'payment-log/submit',
  completePayment: 'payment-log/complete',
  paymentLogs: 'payment-log/get/payment/log',
  deletePaymentLog: 'payment-log/delete',
  automatedGroupChatMsg: 'group/chat/automated/message/add',
  initPaytm: 'payment/gateway/paytm/initiate-payments',

  getMobile: 'user/mobile/get',

  // Astrologer Profile Module
  getAstrologerDetails: 'freeAPI/consultant/get/by/slug/v2',
  consultantPics: 'consultant-gallery/get/allConsultantImages',
  getConsultantReviews: 'rating-review/get/forProfile',
  // checkSimilarConsultant: 'recommendation/get',
  checkSimilarConsultant: 'freeAPI/consultant/recommendation/get',
  longBio: 'freeAPI/consultant/get/longBio',
  infoSimiler: 'get/static/value',
  astrologerAvailibility: 'timetable/get/weekly',
  followStatus: 'live/event/notifyuser/get/status',
  followUpdate: 'live/event/notifyuser/update',
  blockAstro: 'user/block/consultant',
  paidUserAssistant: 'chat-assistance-manager/is/paid/user',
  blockConsultantStatus: 'chat-assistance-manager/get/block/assistance',
  timeSpentOnProfile: 'profile/visitor/save/duration',
  astrologerProducts: 'product-type/get/consultant/products',

  // Kundli Module
  getKundli: 'birthdetail/getSortedList',
  searchHoroscope: 'birthdetail/search',
  getCities: 'cities/allcountries/autocomplete',
  getSecondPlace: 'autocomplete.php',
  addNewCity: 'cities/allcountries/add',
  setFavouriteConsultant: 'user/setFavouriteConsultant',
  saveHoroscope: 'birthdetail/save/planets',
  deleteHoroscope: 'birthdetail/delete',
  kundliMobileGenerated: 'chat/order/get/details/for/kundli',
  kundliMobileGeneratedChat: 'chat/order/get/details/for/kundli/chat',
  dashaComplete: 'dasha_complete',
  yogaDetails: 'find/combination',
  yoginiDetails: 'yogini_dasha_complete',
  ashtakvarga: 'ashtakvarga/complete',
  shareKundliAdd: 'share/kundli/add',
  kundliFromList: 'birthdetail/get',

  // Match Making Module Api
  ashtakoot: 'match_ashtakoot_points',
  matchDashakoot: 'match_dashakoot_points',
  manglikReport: 'match_manglik_report',
  combinedMatch: 'combined/match_making',

  // Register Module
  uploadProfileImage: 'Home/upload_image',
  uploadImage: 'api/v1/horoscope/user/upload-files',
  astroSignUp: 'consultant/v2/registration/create',

  // chinese horoscope
  chineseSignDetails: 'horoscope/chinese',

  // User Profile Module
  getNotificationType: 'notification/get/type',
  updateNotificationType: '/notification/block-unblock',
  getUserLanguages: 'language-user/get',
  deleteAccount: 'admin/delete/user/by/id/v2',
  resetPasswordApi: 'user/reset/password',
  userRegistrationSource: 'user-registration-source/get',
  userRegistrationSourceInputDetails: 'user-registration-source/input-details',
  userProfile: 'user/get/profile',
  updateProfile: 'user/update/user_profile',
  // otpLogin: 'login/user/mobile-otp-login',
  // otpLogin4Digit: 'login/v2/user/mobile-otp-login',
  // otpLogin4Digit: 'login/v3/user/mobile-otp-login',
  otpLogin4Digit: 'v2/login/user/mobile-otp-login',
  otpDetails: 'login/user/mobile-otp-login/verify',
  truecaller: 'truecallersdk://truesdk/web_verify',
  truecallerLogin: 'login/user/mobile-truecaller-login/verify',
  trueCallerToken: 'https://preprod.astrotalk.in/auth/truecaller-token',
  loginTrueCallerApi: 'true-caller/get/AT/token/after-true-caller',
  logoutOtherDevices: 'login/log-out/from-other-devices',
  blockedAstrologersList: 'user/block/list',
  // support chat
  supportChatTicketListApi: 'chat/api/v2/get-chat-list',
  supportChatCreateTicketApi: 'chat/api/v2/create-chat',
  supportChatAllTicketDelete: 'chat/api/delete-all-user-chats',
  supportChatTicketDelete: 'chat/api/v2/delete-chat',
  supportChatActiveId: 'user/get/support/chatId',
  supportChatMessageHistory: 'chat/api/v1/get-chat-history',
  supportChatLatLastMessageApi: 'chat/api/v2/get-latest-message',
  SupportChatSendApi: 'chat/api/v1/send-message',
  supportChatGetRatting: 'chat/rating/review/v1/get',
  supportChatReview: 'chat/rating/review/v1/add',
  supportChatDelete: 'chat/api/v1/deleteChatMsgV2',
  supportTicketReopen: 'chat/api/reopen/ticket',
  supportChatAutomatedMessages: 'support/api/v1/automated-messages',
  likeMessage: 'chat/api/like/message',
  ticketStatus: 'chat/api/v3/get-open-chat-status',
  restartChatNow: 'chat/api/restart-chat',

  // group chat
  groupChatHistory: 'group/chat/get-history',
  groupChatSendMessage: 'group/chat/send-message',
  groupChatGetMessage: 'group/chat/get-latest-messages',
  groupChatGetMessageDelete: 'group/chat/delete-msg',

  seoMetaDataApi: 'api/v1/get/seo/keys/by/url',
  seoDataApi: 'sitemap/get/by/url',
  seoBaseUrl: 'https://astrotalk.com/',

  // shared chat
  sharedChatApi: 'order/share/get',
  sharedChatApiNew: 'order/share/get/v2',
  getEncodedIdNewForCallChat: 'order/share/create/v3',
  shareVisiterApi: 'order/share/visitorCount/update',

  // fraud detection api
  antiFraudFlagsAPI: 'chat/message/v2/get-flag-value',
  fraudFlagsAPI: 'chat/message/get-flag-value',

  // AstroTV module
  agoraAppID: '68904093e3cc4cc696d77821e8b9570b',
  liveEventUserViews: 'live/event/online/addCount',
  liveSubscriberCount: 'live/event/subscriber/count',
  liveEventList: 'live/event/user/list',
  liveEventV2List: 'live/event/v2/user/list',
  liveEventV3List: 'live/event/v3/user/list',
  liveEventSearch: 'live/event/search/event',
  liveEventSubscribe: 'live/event/user/subscribe',
  liveEventSubscribePublic: 'live/event/user/joinevent',
  liveEventActiveUsers: 'live/event/v2/current/subscribers',
  liveEventChatHistory: 'live/event/v2/activity/user/history',
  liveEventChatActivity: 'live/event/v2/activity/user',
  liveEventPostChat: 'live/event/activity/message/user',
  liveEventGetGifts: 'live/event/products/user',
  liveEventPostGift: 'live/event/product/buy',
  // liveEventGetHeartIntensity: 'live/event/heart/intensity',
  // liveEventHeartIntensity: 'live/event/heart',
  liveEventGiftsDonations: 'live/event/v2/activity/donations/user',
  liveEventCallPrice: 'live/event/call/get/price',
  eventIDData: 'live/event/id',
  callWaitList: 'live/event/call/get/status/v4',
  perSecCallStatus: 'live/event/call/get/status/v5',
  pingUser: 'live/event/ping/user',
  slugFromID: 'consultant/get/slug',
  videoCallAllowed: 'crm-user-analytics/user/allowed-for-video/call',
  statusCheck: 'live/event/status',
  callWaitTime: 'live/event/call/get/wait-time/v3',

  // Astrologer Signup Module
  generateOpt: 'v2/login/consultant-registration/mobile-otp-login',
  verifyOtp: 'consultantRegistration/mobile-otp-login/verify',
  firebaseVerifyOtp: 'consultantRegistration/firebase-login/verify',
  savePageTwo: 'consultantRegistration/savePageTwo',
  savePageThree: 'consultantRegistration/savePageThree',
  savePageFour: 'consultantRegistration/savePageFour',
  filterApi: 'filter/get/filter-type-value/active',
  getAllCurrency: 'currency/get/all',
  setCurrency: 'currency/set/by/user',
  getCurrency: 'currency/get/currency/details',
  firstFormData: 'consultantRegistration/getPageTwo',
  secondFormData: 'consultantRegistration/getPageThree',
  thirdFormData: 'consultantRegistration/getPageFour',
  referralCode: 'consultantRegistration/addReferral',
  saveAlertLogs: 'alerts/logs-save',
  twilioLogin: 'login/get/is-twilio-on/foreign',
  loginWithShaadiDotComApi: 'v2/login/verify/user-external',

  // panchang
  panchang: 'panchanga',
  panchangCalandar: 'panchanga/monthly',

  // pdf-Kundli
  getPdfKundli: 'get/kundlihtml',
  combinedGeneral: 'combined/general',
  combinedDivision: 'combined/divisional',
  combinedReport: 'combined/report',
  encryptId: 'utils/getencode',

  // live-event call apis
  createLiveEvent : 'queue-token/create/liveEvent',
  declineCall: 'call-chat/queue/cancel/token',
  rejectCall: 'live/event/call/reject',
  acceptCall: 'live/event/call/join/user',
  endCall: 'live/event/call/end',

  // assistance chat
  chatAssistantHistory: 'chat-assistance-manager/get-chat-history',
  latestAssistantMessage: 'chat-assistance-manager/get-latest-message',
  sendAssChatMessage: 'chat-assistance-manager/send/message',
  deleteAssMessageApi: 'chat-assistance-manager/delete/message',
  blockConsultantFromAss: 'chat-assistance-manager/block/assistance',
  muteConsultantFromAss: 'chat-assistance-manager/mute/chat',
  createTicketForChat: 'chat-assistance-manager/create-chat',

  staticValue: 'get/static/value',

  // calculator Love
  calculateLove: 'love/calculator',

  getEPoojaFilter: 'product/tag/get/for/user',
  getEPoojaAllCategories: 'product-type/get/products/by-tag',
  getEPoojaDetails: 'astromall/get/product',
  getEPoojaDetailsById: 'astromall/get/latest/pooja/by/product-id',
  getEPoojaAddOns: 'product-type/get/all/add-on/e-pooja',
  memberDetailsUpdate: 'product-type/update/gotra-names',
  sharedPoojaDetails: 'live/event/status/by/mapping/id',
  getRecordingPooja: 'live/event/get/epooja/recording',

  getGuestUser: 'get/guest/user',
  guestBookings: 'product-type/order/history/get/order/by/uuid',
  blockReport: 'consultant/rating/block/report',

  celebrityVideos: 'user/celebrityTrust/getAllVideosForUser'
};
