import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {TranslateModule} from '@ngx-translate/core';
import {PipeModule} from '../pipe/pipe.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {UnSaveChangesGuard} from '../guards/un-save-changes.guard';
import {GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    LazyLoadImageModule,
    TranslateModule,
    PipeModule,
    RecaptchaV3Module,
    SocialLoginModule
  ],
  exports: [HeaderComponent],
  providers: [UnSaveChangesGuard,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LcOcMoZAAAAACytcWRqWrl13cVOXjAx2JEG_QHs'
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('832708213727-nbdvd0u6p3md6eps3bc813vfsdcu7eko.apps.googleusercontent.com')
          }
        ], onError: (err) => {
          console.log(err);
        }
      } as SocialAuthServiceConfig,
    }],
})
export class GlobalModule {
}
