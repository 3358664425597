import {CanDeactivate} from '@angular/router';

export interface CanComponentLeave {
  canLeave: () => boolean;
}

export class UnSaveChangesGuard implements CanDeactivate<CanComponentLeave> {
  canDeactivate(component: CanComponentLeave): boolean {
    if (component.canLeave) {
      return component.canLeave();
    }
    return true;
  }
}
