import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'degree'
})
export class DegreePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let degree = value.toString().split('.');
    let degreeSplit = parseFloat("." + degree[1]) * 60;
    let miter = degreeSplit.toString().split('.');
    let miterSplit = parseFloat("." + miter[1]) * 60;
    let inch = miterSplit.toString().split('.');
    return degree[0] + "<sup>&#8728;</sup>" + miter[0] + "&#8242;" + inch[0] + "&#8243;";
  }

}
