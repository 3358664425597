import {Injectable} from '@angular/core';
import {AppConstant} from '../global/common-file/constant';
import {HttpWrapperService} from '../global/common-file/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  private loginApi = AppConstant.apiUrl + AppConstant.login;
  private googleLoginApi = AppConstant.apiUrl + AppConstant.googleLogin;
  private googleLoginNew = AppConstant.apiUrl + AppConstant.googleLoginNew;
  private loginWithOrWithout = AppConstant.apiUrl + AppConstant.loginWithOrWithout;
  // private mobileOtpApi = AppConstant.apiUrl + AppConstant.mobileOtpApi;
  private mobileOtp4DigitApi = AppConstant.apiUrl + AppConstant.mobileOtp4DigitApi;
  private verifyMobileOtpApi = AppConstant.apiUrl + AppConstant.verifyMobileOtpApi;
  private ipAddressApi = AppConstant.ipAddress;
  // private verifyTokenApi = AppConstant.apiUrl + AppConstant.verifyTokenApi;
  private regApi = AppConstant.apiUrl + AppConstant.regApi;
  private regOptApi = AppConstant.apiUrl + AppConstant.regOtpApi;
  private regFinalApi = AppConstant.apiUrl + AppConstant.regFinalApi;
  private forgotApi = AppConstant.apiUrl + AppConstant.forgotApi;
  private myLanguageApi = AppConstant.apiUrl + AppConstant.myLanguageApi;
  private updateLanguageApi = AppConstant.apiUrl + AppConstant.updateLanguageApi;
  private deleteLanguageApi = AppConstant.apiUrl + AppConstant.deleteLanguageApi;
  private isVerifiedMobileApi = AppConstant.apiUrl + AppConstant.isVerifiedMobileApi;
  private saveVerifiedMobilePrimaryApi = AppConstant.apiUrl + AppConstant.saveVerifiedMobilePrimaryApi;
  private saveVerifiedMobileCallApi = AppConstant.apiUrl + AppConstant.saveVerifiedMobileCallApi;
  private mobileFirebaseLoginVerify = AppConstant.apiUrl + AppConstant.mobileLoginFirebase;
  private updateProfile = AppConstant.apiUrl + AppConstant.updateProfile;
  private timeZoneApi = AppConstant.apiUrl + AppConstant.timeZoneApi;
  private logoutOtherDevices = AppConstant.apiUrl + AppConstant.logoutOtherDevices;
  private twilioLogin = AppConstant.apiUrl + AppConstant.twilioLogin;

  constructor(private _http: HttpWrapperService) {
  }

  public updateUserProfile(params) {
    return this._http.postHeaderParams(this.updateProfile, params, true);
  }

  public timeZone = (params) => {
    return this._http.get(this.timeZoneApi, params, true);
  }

  public login = (body) => {
    return this._http.post(this.loginApi, body);
  }

  public forgot = (body) => {
    return this._http.post(this.forgotApi, body);
  }

  public loginWithGoogle = (body) => {
    return this._http.post(this.googleLoginApi, body);
  }

  public loginWithGoogleApi = (body) => {
    return this._http.postHeaderParams(this.googleLoginNew, body);
  }

  public isToLoginWith = (body) => {
    return this._http.postHeaderParams(this.loginWithOrWithout, body);
  }

  public ipAddress = () => {
    return this._http.get(this.ipAddressApi);
  }

  // public sentMobileOtp = (body) => {
  //   return this._http.post(this.mobileOtpApi, body);
  // }

  public checkMobileIsVerified = (params) => {
    return this._http.get(this.isVerifiedMobileApi, params, true);
  }

  /*public verifyMobileOtp = (body) => {
    return this._http.post(this.verifyMobileOtpApi, body);
  }*/

  // public sentMobileOtpParams = (params) => {
  //   return this._http.postParams(this.mobileOtpApi, params);
  // }

  public sent4DigitMobileOtpParams = (params) => {
    return this._http.postParams(this.mobileOtp4DigitApi, params);
  }

  public verifyMobileOtpParams = (params) => {
    return this._http.postParams(this.verifyMobileOtpApi, params);
  }

  public saveVerifiedMobilePrimary = (params) => {
    return this._http.postParams(this.saveVerifiedMobilePrimaryApi, params, true);
  }

  public saveVerifiedMobileCall = (params) => {
    return this._http.postParams(this.saveVerifiedMobileCallApi, params, true);
  }

  /*public verifyToken = (param) => {
    return this._http.postParams(this.verifyTokenApi, param, true);
  }*/

  public submitRegistrationForm = (body) => {
    return this._http.post(this.regApi, body);
  }

  public submitRegistrationOtpForm = (body) => {
    return this._http.post(this.regOptApi, body);
  }

  public submitRegistrationFinalForm = (body) => {
    return this._http.post(this.regFinalApi, body);
  }

  public myLanguage = (param) => {
    return this._http.get(this.myLanguageApi, param, true);
  }

  public myUpdateLanguage = (param) => {
    return this._http.postParams(this.updateLanguageApi, param, true);
  }

  public myDeleteLanguage = (param) => {
    return this._http.delete(this.deleteLanguageApi, param, true);
  }

  public mobileVerifyFirebase = (params) => {
    return this._http.postHeaderParams(this.mobileFirebaseLoginVerify, params, false);
  }

  public logoutFromAllDevices = (params) => {
    return this._http.postHeaderParams(this.logoutOtherDevices, params, true);
  }

  public twilioCheck = (params): any => {
    return this._http.get(this.twilioLogin, params);
  }
}
