import {
  AfterViewInit,
  Component, Inject,
  OnDestroy,
  TemplateRef,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RoutesRecognized
} from '@angular/router';
import {CommonService} from './global/common-file/common.service';
import {MessagingService} from './shared/messaging.service';
import {MatDialog} from '@angular/material/dialog';
import {WaitListService} from './astrologer/wait-list.service';
import {ToastrService} from 'ngx-toastr';
import {filter, pairwise} from 'rxjs/operators';
import {CanonicalService} from './shared/canonical.service';
import {environment} from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
// import {AppInstallationService} from './global/common-file/app-installation.service';

declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit, OnDestroy {
  cdnDomain: string = environment.CDN_DOMAIN;
  @ViewChild('chatCallKit') chatCallKitTemplate: TemplateRef<any>;
  isBrowser = false;
  title = 'webApp';
  public LoadingVideo = false;
  currentUrl: string;
  message;
  notification: Notification;
  firebaseMessage;
  myAudio;
  activeUrl: string;
  language: string;
  pageName: string;
  languages: any;
  firstCode;
  secCode;
  browserTimeZone: string;
  webView = false;
  acceptLoader = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public common: CommonService,
    private canonical: CanonicalService,
    private messagingService: MessagingService,
    public dialog: MatDialog,
    private waitList: WaitListService,
    private toastr: ToastrService,
    public translate: TranslateService,
    // private inApp: AppInstallationService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.LoadingVideo = true;
          break;
        }
        case event instanceof NavigationEnd: {
          this.LoadingVideo = false;
          break;
        }
        case event instanceof NavigationCancel: {
          this.LoadingVideo = false;
          break;
        }
        case event instanceof NavigationError: {
          this.LoadingVideo = false;
          break;
        }
        default: {
          this.LoadingVideo = false;
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.languages = ['hi', 'en', 'pa', 'kn'];
    this.isBrowser = this.common.checkDeviceIsBrowser();
    this.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.browserTimeZone == 'Asia/Calcutta') {
      this.browserTimeZone = 'Asia/Kolkata';
    }

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      this.currentUrl = this.activeUrl = e.url;
      if (this.isBrowser) {
        fbq('track', 'PageView');
      }
      this.canonical.setCanonicalURL('https://astrotalk.com' + this.currentUrl);
      const languageCode = this.currentUrl.split('/')[1];
      this.firstCode = this.currentUrl.split('/')[1];
      this.secCode = this.currentUrl.split('/')[2];
      if (this.currentUrl.includes('signup')) {
        this.activatedRoute.queryParams.subscribe((queryParams: any) => {
          if (queryParams?.isWebView) {
            this.webView = queryParams?.isWebView;
          }
        });
      }
      if (!(this.currentUrl.includes('e-pooja') || this.currentUrl.includes('book-a-pooja'))) {
        const guestLoggedIn = this.common.getItem('guestUser');
        if (guestLoggedIn != '' && guestLoggedIn == 'true') {
          this.common.logout();
        }
      }
      const lastIndex = this.currentUrl.indexOf('/') + 1;
      this.pageName = this.currentUrl.substring(lastIndex);
      if (languageCode.length == 2) {
        this.currentUrl = this.pageName.replace(languageCode, '');
      }
      this.common.pageName.next(this.currentUrl);
      if (languageCode.length == 2 && this.languages.indexOf(languageCode) > -1) {
        this.document.documentElement.lang = languageCode;
        this.common.langStatus.next(languageCode);
        this.translate.use(languageCode);
      } else {
        this.document.documentElement.lang = 'en';
        this.common.langStatus.next('en');
        this.translate.use('en');
      }
    });
    this.common.setTitle('Astrotalk - Free Online Astrology Predictions by Best Astrologer');
    this.common.updateTag('description', 'Astrotalk is the best astrology website for online astrology predictions from the best astrologers of India. Our astrologer can get answers to all your worries.');
    this.common.updateTag('keywords', 'Best astrologers near me, My horoscope online, Best online astrology website in India, Vastu astrology, Horoscope Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, astroyogi, career horoscope.');

    if (this.isBrowser) {
      if (this.common.checkDeviceIsMobile() && this.common.getMobileOperatingSystem() == 'iOS') {
        /*this.inApp.isAppInstalled('astrotalk://').then((isInstalled) => {
          if (isInstalled) {
            console.log('The app is installed.');
          } else {
            console.log('The app is not installed.');
          }
        }).catch((error) => {
          console.error('Error checking if the app is installed:');
        });*/
      } else if (this.common.checkDeviceIsMobile() && this.common.getMobileOperatingSystem() == 'Android') {
        // this.inApp.isAppInstalledAndroid('com.astrotalk');
        /* if (window.location.pathname === '/') { // Adjust this condition to target your homepage URL
           // checkIfAppInstalled();
           this.inApp.isAppInstalled();
         }*/
      }
      this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()).subscribe((events: RoutesRecognized[]) => {
        this.common.setPreviousUrl(events[0].urlAfterRedirects);
      });
      this.firebaseMessage = this.messagingService.getNotification().subscribe((payload) => {
        this.handleNotification(payload);
      });
    }
  }

  loadAudio() {
    this.myAudio = new Audio();
    this.myAudio.src = './assets/audio/ringing.mp3';
    this.myAudio.load();
  }

  playAudio = () => {
    this.myAudio.currentTime = 0;
    this.myAudio.play();
  }

  stopAudio = () => {
    this.myAudio.currentTime = 0;
    this.myAudio.pause();
  }

  /*getRateOfConvirsion = () => {
      const rate = this.common.getWithExpiry('conRate');
      if (!rate) {
        this.common.rateOfConversion({
          // userId: this.common.isLogin ? this.common.getUserData('id') : '',
          appId: this.common.getAppId(),
          timezone: encodeURIComponent(this.browserTimeZone)
        }, true).subscribe((response: any) => {
            if (response?.status == 'success') {
              this.common.setWithExpiry('conRate', response.data.currencyDto, '86400');
              if (response.data.isForeign) {
                this.common.setItem('timeZone', 'Foreign');
                this.common.setItem('multipleCurrencyEnabled', response.data.multipleCurrencyEnabled);
              } else {
                this.common.setItem('timeZone', 'Asia/Kolkata');
                this.common.setItem('multipleCurrencyEnabled', 'false');
              }
            }
          }, (error) => {
            console.error('conRate', error);
          });
      }
  }*/

  ngAfterViewInit() {
    if (this.common.checkDeviceIsBrowser()) {
      this.loadAudio();
    }
  }

  ngOnDestroy(): void {
    if (this.firebaseMessage) {
      this.firebaseMessage.unsubscribe();
    }
  }

  onActivate(event) {
  }

  openChatCalKit = (chatData) => {
    this.playAudio();
    return this.dialog.open(this.chatCallKitTemplate, {
      data: chatData,
      minWidth: 400,
      maxWidth: 400,
      disableClose: true
    });
  }

  checkChatStatus = (payload) => {
    this.waitList.checkChatStatus({
      orderId: payload.chatOrderId,
      userId: this.common.getUserData('id')
    }).subscribe((response: any) => {
      if (response.status == 'success' && response.data?.chatStatus == 'ACCEPTED_BY_CONSULTANT') {
        response.data.consultantPic = (payload.pic) ? payload.pic :
          this.cdnDomain + '/assets/images/astro-background-gray.webp';
        this.openChatCalKit(response.data);
      }
    }, () => {
      this.toastr.error('Something went wrong, please try again later!');
    });
  }

  acceptChat = (payload) => {
    this.acceptLoader = true;
    this.waitList.acceptWaitListChat({
      chatOrderId: payload?.orderId,
      userId: this.common.getUserData('id'),
      appId: this.common.getAppId(),
    }).subscribe((response: any) => {
      this.stopAudio();
      this.acceptLoader = false;
      if (response.status == 'success') {
        const chatId = this.common.customEncode(payload.orderId);
        const astrologerId = this.common.customEncode(payload.consultantId);
        const route = '/chat-with-astrologer/start/' + astrologerId + '/' + chatId;
        this.router.navigate([route]);
      } else {
        this.toastr.error(response.reason);
      }
    }, () => {
      this.stopAudio();
      this.acceptLoader = false;
      this.toastr.error('Something went wrong, please try again later!');
    });
  }

  rejectChat = (payload) => {
    this.acceptLoader = true;
    this.waitList.rejectWaitListChat({
      chatOrderId: payload?.orderId,
      userId: this.common.getUserData('id')
    }).subscribe((response: any) => {
      this.stopAudio();
      this.acceptLoader = false;
      if (response.status != 'success') {
        this.toastr.error(response.reason);
      }
    }, () => {
      this.acceptLoader = false;
      this.stopAudio();
      this.toastr.error('Something went wrong, please try again later!');
    });
  }

  handleNotification(payload) {
    if (payload && payload.data) {
      if (payload.data.type == 'chatPerMin_status_to_user') {
        if (payload.data.playSiren) {
          this.checkChatStatus(payload.data);
        }
      } else if (payload.data.type == 'chatPerMin_to_user' || payload.data.type == 'update_token') {
        const lastParams = this.common.customEncode(payload.data.consultantId) +
          '/' + this.common.customEncode(payload.data.chatOrderId);
        if ((this.activeUrl != '/chat-with-astrologer/start/' + lastParams) ||
          (this.activeUrl != '/order-history/chat/' + lastParams)) {
          if (payload.data.status == 'ACCEPTED' || payload.data.status == 'INPROGRESS') {
            payload.data.clickAction = '/chat-with-astrologer/start/' +
              this.common.customEncode(payload.data.consultantId) + '/' +
              this.common.customEncode(payload.data.chatOrderId);
          } else if (payload.data.status == 'COMPLETED') {
            payload.data.clickAction = '/order-history/chat/' +
              this.common.customEncode(payload.data.consultantId) +
              '/' + this.common.customEncode(payload.data.chatOrderId);
          } else if (payload.data.status == 'LOW') {
            payload.data.clickAction = '/add-wallet-money/price-list';
          }
          if (payload.data.type != 'update_token') {
            const noti: Notification = new Notification(payload.notification.title, {
              body: payload.notification.body,
              icon: (payload.data?.pic) ? payload.data?.pic : this.cdnDomain + '/assets/images/header/icons/logo.webp',
              data: payload.data
            });
            noti.addEventListener('click', () => {
              this.router.navigate([payload.data.clickAction]);
            });
          }
        }
      } else if (payload.data.type == 'freeChat_userToAdmin') {
        if (this.activeUrl != '/customer-support') {
          payload.data.clickAction = '/customer-support';
          const noti: Notification = new Notification(payload.notification.title, {
            body: payload.notification.body,
            icon: (payload.data?.pic) ? payload.data?.pic : this.cdnDomain + '/assets/images/header/icons/logo.webp',
            data: payload.data
          });
          noti.addEventListener('click', () => {
            this.router.navigate([payload.data.clickAction]);
          });
        }
      } else {
        const noti: Notification = new Notification(payload.notification?.title, {
          body: payload.notification?.body,
          icon: (payload.data?.pic) ? payload.data.pic : this.cdnDomain + '/assets/images/header/icons/logo.webp',
          data: payload.data
        });
        noti.addEventListener('click', () => {
          window.open((payload.data?.click_action) ? payload.data.click_action : 'https://astrotalk.com', '_blank');
        });
      }
    } else if (payload) {
      const noti: Notification = new Notification(payload.notification?.title, {
        body: payload.notification?.body,
        icon: (payload.notification?.pic) ? payload.notification?.pic : this.cdnDomain + '/assets/images/header/icons/logo.webp',
        data: payload.notification
      });
      noti.addEventListener('click', () => {
        window.open((payload.data?.click_action) ? payload.data.click_action : 'https://astrotalk.com', '_blank');
      });
    }
  }

  changeSource(event) {
    event.target.src = this.cdnDomain + '/assets/images/astro-background-gray.webp';
  }
}
