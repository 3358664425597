import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {LangGuard} from './guards/lang.guard';

const routes: Routes = [
  {
    path: 'about-us',
    loadChildren: () => import('./staticPages/about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: ':lang/about-us',
    loadChildren: () => import('./staticPages/about-us/about-us.module').then(m => m.AboutUsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./staticPages/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: ':lang/terms-and-conditions',
    loadChildren: () => import('./staticPages/terms/terms.module').then(m => m.TermsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'privacy-policies',
    loadChildren: () => import('./staticPages/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: ':lang/privacy-policies',
    loadChildren: () => import('./staticPages/privacy/privacy.module').then(m => m.PrivacyModule),
    canActivate: [LangGuard]
  },
  {
    path: 'pricing',
    loadChildren: () => import('./staticPages/pricing/pricing.module').then(m => m.PricingModule)
  },
  {
    path: ':lang/pricing',
    loadChildren: () => import('./staticPages/pricing/pricing.module').then(m => m.PricingModule),
    canActivate: [LangGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./staticPages/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: ':lang/contact-us',
    loadChildren: () => import('./staticPages/contact/contact.module').then(m => m.ContactModule),
    canActivate: [LangGuard]
  },
  {
    path: 'astrologer-terms-and-conditions',
    loadChildren: () => import('./staticPages/astrologer-terms/astrologer-terms.module').then(m => m.AstrologerTermsModule)
  },
  {
    path: ':lang/astrologer-terms-and-conditions',
    loadChildren: () => import('./staticPages/astrologer-terms/astrologer-terms.module').then(m => m.AstrologerTermsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'astrotalk-reviews',
    loadChildren: () => import('./staticPages/reviews/reviews.module').then(m => m.ReviewsModule)
  },
  {
    path: ':lang/astrotalk-reviews',
    loadChildren: () => import('./staticPages/reviews/reviews.module').then(m => m.ReviewsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./staticPages/disclaimer/disclaimer.module').then(m => m.DisclaimerModule)
  },
  {
    path: ':lang/disclaimer',
    loadChildren: () => import('./staticPages/disclaimer/disclaimer.module').then(m => m.DisclaimerModule),
    canActivate: [LangGuard]
  },
  {
    path: 'refund-and-cancellation-policy',
    loadChildren: () => import('./staticPages/refund/refund.module').then(m => m.RefundModule)
  },
  {
    path: ':lang/refund-and-cancellation-policy',
    loadChildren: () => import('./staticPages/refund/refund.module').then(m => m.RefundModule),
    canActivate: [LangGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('./staticPages/notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: ':lang/notification',
    loadChildren: () => import('./staticPages/notification/notification.module').then(m => m.NotificationModule),
    canActivate: [LangGuard]
  },
  {
    path: 'payment-success',
    loadChildren: () => import('./appRedirect/payment-success/payment-success.module').then(m => m.PaymentSuccessModule),
  },
  {
    path: 'payment-fail',
    loadChildren: () => import('./appRedirect/payment-fail/payment-fail.module').then(m => m.PaymentFailModule),
  },
  {
    path: 'call-recording',
    loadChildren: () => import('./appRedirect/play-recording/play-recording.module').then(m => m.PlayRecordingModule),
  },
  {
    path: 'astrology-yoga',
    loadChildren: () => import('./astrology-yogas/astrology-yogas.module').then(m => m.AstrologyYogasModule)
  },
  {
    path: ':lang/astrology-yoga',
    loadChildren: () => import('./astrology-yogas/astrology-yogas.module').then(m => m.AstrologyYogasModule),
    canActivate: [LangGuard]
  },
  {
    path: 'jyotish-yoga',
    loadChildren: () => import('./astrology-yogas/astrology-yogas.module').then(m => m.AstrologyYogasModule)
  },
  {
    path: ':lang/jyotish-yoga',
    loadChildren: () => import('./astrology-yogas/astrology-yogas.module').then(m => m.AstrologyYogasModule),
    canActivate: [LangGuard]
  },
  {
    path: 'jyotishyada-prakara-jaatakadallina-yoga',
    loadChildren: () => import('./astrology-yogas/astrology-yogas.module').then(m => m.AstrologyYogasModule)
  },
  {
    path: ':lang/jyotishyada-prakara-jaatakadallina-yoga',
    loadChildren: () => import('./astrology-yogas/astrology-yogas.module').then(m => m.AstrologyYogasModule),
    canActivate: [LangGuard]
  },
  {
    path: 'profile-settings',
    loadChildren: () => import('./profile-user/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: ':lang/profile-settings',
    loadChildren: () => import('./profile-user/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./profile-user/forget-password/forget-password.module').then(m => m.ForgetPasswordModule)
  },
  {
    path: ':lang/forgot-password',
    loadChildren: () => import('./profile-user/forget-password/forget-password.module').then(m => m.ForgetPasswordModule),
    canActivate: [LangGuard]
  },
  {
    path: 'payment-logs',
    loadChildren: () => import('./recharge-and-transaction/logs/logs.module').then(m => m.LogsModule)
  },
  {
    path: ':lang/payment-logs',
    loadChildren: () => import('./recharge-and-transaction/logs/logs.module').then(m => m.LogsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'add-wallet-money',
    loadChildren: () => import('./recharge-and-transaction/price-list/price-list.module').then(m => m.PriceListModule),
    canActivate: [AuthGuard],
  },
  {
    path: ':lang/add-wallet-money',
    loadChildren: () => import('./recharge-and-transaction/price-list/price-list.module').then(m => m.PriceListModule),
    canActivate: [AuthGuard, LangGuard],
  },
  {
    path: 'thank-you',
    loadChildren: () => import('./recharge-and-transaction/thanks/thanks.module').then(m => m.ThanksModule)
  },
  {
    path: ':lang/thank-you',
    loadChildren: () => import('./recharge-and-transaction/thanks/thanks.module').then(m => m.ThanksModule),
    canActivate: [LangGuard]
  },
  {
    path: 'my-wallet',
    loadChildren: () => import('./recharge-and-transaction/transactions/transactions.module').then(m => m.TransactionsModule)
  },
  {
    path: ':lang/my-wallet',
    loadChildren: () => import('./recharge-and-transaction/transactions/transactions.module').then(m => m.TransactionsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'career-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/career/career.module').then(m => m.CareerModule)
  },
  {
    path: ':lang/career-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/career/career.module').then(m => m.CareerModule),
    canActivate: [LangGuard]
  },
  {
    path: 'career-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/career/career.module').then(m => m.CareerModule)
  },
  {
    path: ':lang/career-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/career/career.module').then(m => m.CareerModule),
    canActivate: [LangGuard]
  },
  {
    path: 'education-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/education/education.module').then(m => m.EducationModule)
  },
  {
    path: ':lang/education-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/education/education.module').then(m => m.EducationModule),
    canActivate: [LangGuard]
  },
  {
    path: 'education-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/education/education.module').then(m => m.EducationModule)
  },
  {
    path: ':lang/education-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/education/education.module').then(m => m.EducationModule),
    canActivate: [LangGuard]
  },
  {
    path: 'finance-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/finance/finance.module').then(m => m.FinanceModule)
  },
  {
    path: ':lang/finance-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/finance/finance.module').then(m => m.FinanceModule),
    canActivate: [LangGuard]
  },
  {
    path: 'finance-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/finance/finance.module').then(m => m.FinanceModule)
  },
  {
    path: ':lang/finance-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/finance/finance.module').then(m => m.FinanceModule),
    canActivate: [LangGuard]
  },
  {
    path: 'health-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/health/health.module').then(m => m.HealthModule)
  },
  {
    path: ':lang/health-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/health/health.module').then(m => m.HealthModule),
    canActivate: [LangGuard]
  },
  {
    path: 'swasthya-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/health/health.module').then(m => m.HealthModule)
  },
  {
    path: ':lang/swasthya-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/health/health.module').then(m => m.HealthModule),
    canActivate: [LangGuard]
  },
  {
    path: 'lalkitab-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/lalkitab/lalkitab.module').then(m => m.LalkitabModule)
  },
  {
    path: ':lang/lalkitab-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/lalkitab/lalkitab.module').then(m => m.LalkitabModule),
    canActivate: [LangGuard]
  },
  {
    path: 'lalkitab-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/lalkitab/lalkitab.module').then(m => m.LalkitabModule)
  },
  {
    path: ':lang/lalkitab-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/lalkitab/lalkitab.module').then(m => m.LalkitabModule),
    canActivate: [LangGuard]
  },
  {
    path: 'love-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/love/love.module').then(m => m.LoveModule)
  },
  {
    path: ':lang/love-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/love/love.module').then(m => m.LoveModule),
    canActivate: [LangGuard]
  },
  {
    path: 'prem-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/love/love.module').then(m => m.LoveModule)
  },
  {
    path: ':lang/prem-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/love/love.module').then(m => m.LoveModule),
    canActivate: [LangGuard]
  },
  {
    path: 'numerology-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/numerology/numerology.module').then(m => m.NumerologyModule)
  },
  {
    path: ':lang/numerology-horoscope-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/numerology/numerology.module').then(m => m.NumerologyModule),
    canActivate: [LangGuard]
  },
  {
    path: 'ank-jyotish-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/numerology/numerology.module').then(m => m.NumerologyModule)
  },
  {
    path: ':lang/ank-jyotish-rashifal-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/numerology/numerology.module').then(m => m.NumerologyModule),
    canActivate: [LangGuard]
  },
  {
    path: 'tarot-reading-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/tarot2022/tarot2022.module').then(m => m.Tarot2022Module)
  },
  {
    path: ':lang/tarot-reading-2022-predictions',
    loadChildren: () => import('./type-horoscope2022/tarot2022/tarot2022.module').then(m => m.Tarot2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'todays-love-horoscope',
    loadChildren: () => import('./love-horoscope/love-horoscope.module').then(m => m.LoveHoroscopeModule)
  },
  {
    path: ':lang/todays-love-horoscope',
    loadChildren: () => import('./love-horoscope/love-horoscope.module').then(m => m.LoveHoroscopeModule),
    canActivate: [LangGuard]
  },
  {
    path: 'aaj-ka-love-rashifal',
    loadChildren: () => import('./love-horoscope/love-horoscope.module').then(m => m.LoveHoroscopeModule)
  },
  {
    path: ':lang/aaj-ka-love-rashifal',
    loadChildren: () => import('./love-horoscope/love-horoscope.module').then(m => m.LoveHoroscopeModule),
    canActivate: [LangGuard]
  },
  {
    path: 'indina-preeti-rashi-bhavishya',
    loadChildren: () => import('./love-horoscope/love-horoscope.module').then(m => m.LoveHoroscopeModule)
  },
  {
    path: ':lang/indina-preeti-rashi-bhavishya',
    loadChildren: () => import('./love-horoscope/love-horoscope.module').then(m => m.LoveHoroscopeModule),
    canActivate: [LangGuard]
  },
  {
    path: 'planetary-transit-2022',
    loadChildren: () => import('./transit-2022/planetaryTransitMain2022/planetaryTransitMain2022.module').then(m =>
      m.PlanetaryTransitMain2022Module)
  },
  {
    path: ':lang/planetary-transit-2022',
    loadChildren: () => import('./transit-2022/planetaryTransitMain2022/planetaryTransitMain2022.module').then(m =>
      m.PlanetaryTransitMain2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'graha-gochar-2022',
    loadChildren: () => import('./transit-2022/planetaryTransitMain2022/planetaryTransitMain2022.module').then(m =>
      m.PlanetaryTransitMain2022Module)
  },
  {
    path: ':lang/graha-gochar-2022',
    loadChildren: () => import('./transit-2022/planetaryTransitMain2022/planetaryTransitMain2022.module').then(m =>
      m.PlanetaryTransitMain2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'graha-sanchara-maahiti-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/planetaryTransitMain2022/planetaryTransitMain2022.module').then(m =>
      m.PlanetaryTransitMain2022Module)
  },
  {
    path: ':lang/graha-sanchara-maahiti-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/planetaryTransitMain2022/planetaryTransitMain2022.module').then(m =>
      m.PlanetaryTransitMain2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rahu-transit-2022',
    loadChildren: () => import('./transit-2022/rahu2022/rahu2022.module').then(m => m.Rahu2022Module)
  },
  {
    path: ':lang/rahu-transit-2022',
    loadChildren: () => import('./transit-2022/rahu2022/rahu2022.module').then(m => m.Rahu2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rahu-gochar-2022',
    loadChildren: () => import('./transit-2022/rahu2022/rahu2022.module').then(m => m.Rahu2022Module)
  },
  {
    path: ':lang/rahu-gochar-2022',
    loadChildren: () => import('./transit-2022/rahu2022/rahu2022.module').then(m => m.Rahu2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rahu-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/rahu2022/rahu2022.module').then(m => m.Rahu2022Module)
  },
  {
    path: ':lang/rahu-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/rahu2022/rahu2022.module').then(m => m.Rahu2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'saturn-transit-2022',
    loadChildren: () => import('./transit-2022/saturn2022/saturn2022.module').then(m => m.Saturn2022Module)
  },
  {
    path: ':lang/saturn-transit-2022',
    loadChildren: () => import('./transit-2022/saturn2022/saturn2022.module').then(m => m.Saturn2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shani-gochar-2022',
    loadChildren: () => import('./transit-2022/saturn2022/saturn2022.module').then(m => m.Saturn2022Module)
  },
  {
    path: ':lang/shani-gochar-2022',
    loadChildren: () => import('./transit-2022/saturn2022/saturn2022.module').then(m => m.Saturn2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shani-sanchara-dinanka-samaya-2022',
    loadChildren: () => import('./transit-2022/saturn2022/saturn2022.module').then(m => m.Saturn2022Module)
  },
  {
    path: ':lang/shani-sanchara-dinanka-samaya-2022',
    loadChildren: () => import('./transit-2022/saturn2022/saturn2022.module').then(m => m.Saturn2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'sun-transit-2022',
    loadChildren: () => import('./transit-2022/sun2022/sun2022.module').then(m => m.Sun2022Module)
  },
  {
    path: ':lang/sun-transit-2022',
    loadChildren: () => import('./transit-2022/sun2022/sun2022.module').then(m => m.Sun2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'surya-gochar-2022',
    loadChildren: () => import('./transit-2022/sun2022/sun2022.module').then(m => m.Sun2022Module)
  },
  {
    path: ':lang/surya-gochar-2022',
    loadChildren: () => import('./transit-2022/sun2022/sun2022.module').then(m => m.Sun2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'surya-sanchara-dinanka-samaya-2022',
    loadChildren: () => import('./transit-2022/sun2022/sun2022.module').then(m => m.Sun2022Module)
  },
  {
    path: ':lang/surya-sanchara-dinanka-samaya-2022',
    loadChildren: () => import('./transit-2022/sun2022/sun2022.module').then(m => m.Sun2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'venus-transit-2022',
    loadChildren: () => import('./transit-2022/venus2022/venus2022.module').then(m => m.Venus2022Module)
  },
  {
    path: ':lang/venus-transit-2022',
    loadChildren: () => import('./transit-2022/venus2022/venus2022.module').then(m => m.Venus2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shukra-gochar-2022',
    loadChildren: () => import('./transit-2022/venus2022/venus2022.module').then(m => m.Venus2022Module)
  },
  {
    path: ':lang/shukra-gochar-2022',
    loadChildren: () => import('./transit-2022/venus2022/venus2022.module').then(m => m.Venus2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shukra-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/venus2022/venus2022.module').then(m => m.Venus2022Module)
  },
  {
    path: ':lang/shukra-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/venus2022/venus2022.module').then(m => m.Venus2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'jupiter-transit-2022',
    loadChildren: () => import('./transit-2022/jupiter2022/jupiter2022.module').then(m => m.Jupiter2022Module)
  },
  {
    path: ':lang/jupiter-transit-2022',
    loadChildren: () => import('./transit-2022/jupiter2022/jupiter2022.module').then(m => m.Jupiter2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'brihaspati-gochar-2022',
    loadChildren: () => import('./transit-2022/jupiter2022/jupiter2022.module').then(m => m.Jupiter2022Module)
  },
  {
    path: ':lang/brihaspati-gochar-2022',
    loadChildren: () => import('./transit-2022/jupiter2022/jupiter2022.module').then(m => m.Jupiter2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'guru-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/jupiter2022/jupiter2022.module').then(m => m.Jupiter2022Module)
  },
  {
    path: ':lang/guru-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/jupiter2022/jupiter2022.module').then(m => m.Jupiter2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'ketu-transit-2022',
    loadChildren: () => import('./transit-2022/ketu2022/ketu2022.module').then(m => m.Ketu2022Module)
  },
  {
    path: ':lang/ketu-transit-2022',
    loadChildren: () => import('./transit-2022/ketu2022/ketu2022.module').then(m => m.Ketu2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'ketu-gochar-2022',
    loadChildren: () => import('./transit-2022/ketu2022/ketu2022.module').then(m => m.Ketu2022Module)
  },
  {
    path: ':lang/ketu-gochar-2022',
    loadChildren: () => import('./transit-2022/ketu2022/ketu2022.module').then(m => m.Ketu2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'ketu-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/ketu2022/ketu2022.module').then(m => m.Ketu2022Module)
  },
  {
    path: ':lang/ketu-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/ketu2022/ketu2022.module').then(m => m.Ketu2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mercury-transit-2022',
    loadChildren: () => import('./transit-2022/mercury2022/mercury2022.module').then(m => m.Mercury2022Module)
  },
  {
    path: ':lang/mercury-transit-2022',
    loadChildren: () => import('./transit-2022/mercury2022/mercury2022.module').then(m => m.Mercury2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'budh-gochar-2022',
    loadChildren: () => import('./transit-2022/mercury2022/mercury2022.module').then(m => m.Mercury2022Module)
  },
  {
    path: ':lang/budh-gochar-2022',
    loadChildren: () => import('./transit-2022/mercury2022/mercury2022.module').then(m => m.Mercury2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'budha-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/mercury2022/mercury2022.module').then(m => m.Mercury2022Module)
  },
  {
    path: ':lang/budha-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/mercury2022/mercury2022.module').then(m => m.Mercury2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'moon-transit-2022',
    loadChildren: () => import('./transit-2022/moon2022/moon2022.module').then(m => m.Moon2022Module)
  },
  {
    path: ':lang/moon-transit-2022',
    loadChildren: () => import('./transit-2022/moon2022/moon2022.module').then(m => m.Moon2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'chandra-gochar-2022',
    loadChildren: () => import('./transit-2022/moon2022/moon2022.module').then(m => m.Moon2022Module)
  },
  {
    path: ':lang/chandra-gochar-2022',
    loadChildren: () => import('./transit-2022/moon2022/moon2022.module').then(m => m.Moon2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'chandra-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/moon2022/moon2022.module').then(m => m.Moon2022Module)
  },
  {
    path: ':lang/chandra-sanchara-samaya-dinanka-2022',
    loadChildren: () => import('./transit-2022/moon2022/moon2022.module').then(m => m.Moon2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mars-transit-2022',
    loadChildren: () => import('./transit-2022/mars2022/mars2022.module').then(m => m.Mars2022Module)
  },
  {
    path: ':lang/mars-transit-2022',
    loadChildren: () => import('./transit-2022/mars2022/mars2022.module').then(m => m.Mars2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mangal-gochar-2022',
    loadChildren: () => import('./transit-2022/mars2022/mars2022.module').then(m => m.Mars2022Module)
  },
  {
    path: ':lang/mangal-gochar-2022',
    loadChildren: () => import('./transit-2022/mars2022/mars2022.module').then(m => m.Mars2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mangala-sanchara-dinanka-samaya-2022',
    loadChildren: () => import('./transit-2022/mars2022/mars2022.module').then(m => m.Mars2022Module)
  },
  {
    path: ':lang/mangala-sanchara-dinanka-samaya-2022',
    loadChildren: () => import('./transit-2022/mars2022/mars2022.module').then(m => m.Mars2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'planetary-transit-2023',
    loadChildren: () => import('./transit-2023/main2k23/main2k23.module').then(m => m.Main2k23Module)
  },
  {
    path: ':lang/planetary-transit-2023',
    loadChildren: () => import('./transit-2023/main2k23/main2k23.module').then(m => m.Main2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'graha-gochar-2023',
    loadChildren: () => import('./transit-2023/main2k23/main2k23.module').then(m => m.Main2k23Module)
  },
  {
    path: ':lang/graha-gochar-2023',
    loadChildren: () => import('./transit-2023/main2k23/main2k23.module').then(m => m.Main2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'graha-sanchara-maahiti-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/main2k23/main2k23.module').then(m => m.Main2k23Module)
  },
  {
    path: ':lang/graha-sanchara-maahiti-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/main2k23/main2k23.module').then(m => m.Main2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mars-transit-2023',
    loadChildren: () => import('./transit-2023/mars2k23/mars2k23.module').then(m => m.Mars2k23Module)
  },
  {
    path: ':lang/mars-transit-2023',
    loadChildren: () => import('./transit-2023/mars2k23/mars2k23.module').then(m => m.Mars2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mangal-gochar-2023',
    loadChildren: () => import('./transit-2023/mars2k23/mars2k23.module').then(m => m.Mars2k23Module)
  },
  {
    path: ':lang/mangal-gochar-2023',
    loadChildren: () => import('./transit-2023/mars2k23/mars2k23.module').then(m => m.Mars2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mangala-sanchara-dinanka-samaya-2023',
    loadChildren: () => import('./transit-2023/mars2k23/mars2k23.module').then(m => m.Mars2k23Module)
  },
  {
    path: ':lang/mangala-sanchara-dinanka-samaya-2023',
    loadChildren: () => import('./transit-2023/mars2k23/mars2k23.module').then(m => m.Mars2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mercury-transit-2023',
    loadChildren: () => import('./transit-2023/mercury2k23/mercury2k23.module').then(m => m.Mercury2k23Module)
  },
  {
    path: ':lang/mercury-transit-2023',
    loadChildren: () => import('./transit-2023/mercury2k23/mercury2k23.module').then(m => m.Mercury2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'budh-gochar-2023',
    loadChildren: () => import('./transit-2023/mercury2k23/mercury2k23.module').then(m => m.Mercury2k23Module)
  },
  {
    path: ':lang/budh-gochar-2023',
    loadChildren: () => import('./transit-2023/mercury2k23/mercury2k23.module').then(m => m.Mercury2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'budha-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/mercury2k23/mercury2k23.module').then(m => m.Mercury2k23Module)
  },
  {
    path: ':lang/budha-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/mercury2k23/mercury2k23.module').then(m => m.Mercury2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'moon-transit-2023',
    loadChildren: () => import('./transit-2023/moon2k23/moon2k23.module').then(m => m.Moon2k23Module)
  },
  {
    path: ':lang/moon-transit-2023',
    loadChildren: () => import('./transit-2023/moon2k23/moon2k23.module').then(m => m.Moon2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'chandra-gochar-2023',
    loadChildren: () => import('./transit-2023/moon2k23/moon2k23.module').then(m => m.Moon2k23Module)
  },
  {
    path: ':lang/chandra-gochar-2023',
    loadChildren: () => import('./transit-2023/moon2k23/moon2k23.module').then(m => m.Moon2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'chandra-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/moon2k23/moon2k23.module').then(m => m.Moon2k23Module)
  },
  {
    path: ':lang/chandra-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/moon2k23/moon2k23.module').then(m => m.Moon2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rahu-transit-2023',
    loadChildren: () => import('./transit-2023/rahu2k23/rahu2k23.module').then(m => m.Rahu2k23Module)
  },
  {
    path: ':lang/rahu-transit-2023',
    loadChildren: () => import('./transit-2023/rahu2k23/rahu2k23.module').then(m => m.Rahu2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rahu-gochar-2023',
    loadChildren: () => import('./transit-2023/rahu2k23/rahu2k23.module').then(m => m.Rahu2k23Module)
  },
  {
    path: ':lang/rahu-gochar-2023',
    loadChildren: () => import('./transit-2023/rahu2k23/rahu2k23.module').then(m => m.Rahu2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rahu-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/rahu2k23/rahu2k23.module').then(m => m.Rahu2k23Module)
  },
  {
    path: ':lang/rahu-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/rahu2k23/rahu2k23.module').then(m => m.Rahu2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'saturn-transit-2023',
    loadChildren: () => import('./transit-2023/saturn2k23/saturn2k23.module').then(m => m.Saturn2k23Module)
  },
  {
    path: ':lang/saturn-transit-2023',
    loadChildren: () => import('./transit-2023/saturn2k23/saturn2k23.module').then(m => m.Saturn2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shani-gochar-2023',
    loadChildren: () => import('./transit-2023/saturn2k23/saturn2k23.module').then(m => m.Saturn2k23Module)
  },
  {
    path: ':lang/shani-gochar-2023',
    loadChildren: () => import('./transit-2023/saturn2k23/saturn2k23.module').then(m => m.Saturn2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shani-sanchara-dinanka-samaya-2023',
    loadChildren: () => import('./transit-2023/saturn2k23/saturn2k23.module').then(m => m.Saturn2k23Module)
  },
  {
    path: ':lang/shani-sanchara-dinanka-samaya-2023',
    loadChildren: () => import('./transit-2023/saturn2k23/saturn2k23.module').then(m => m.Saturn2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'sun-transit-2023',
    loadChildren: () => import('./transit-2023/sun2k23/sun2k23.module').then(m => m.Sun2k23Module)
  },
  {
    path: ':lang/sun-transit-2023',
    loadChildren: () => import('./transit-2023/sun2k23/sun2k23.module').then(m => m.Sun2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'surya-gochar-2023',
    loadChildren: () => import('./transit-2023/sun2k23/sun2k23.module').then(m => m.Sun2k23Module)
  },
  {
    path: ':lang/surya-gochar-2023',
    loadChildren: () => import('./transit-2023/sun2k23/sun2k23.module').then(m => m.Sun2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'surya-sanchara-dinanka-samaya-2023',
    loadChildren: () => import('./transit-2023/sun2k23/sun2k23.module').then(m => m.Sun2k23Module)
  },
  {
    path: ':lang/surya-sanchara-dinanka-samaya-2023',
    loadChildren: () => import('./transit-2023/sun2k23/sun2k23.module').then(m => m.Sun2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'venus-transit-2023',
    loadChildren: () => import('./transit-2023/venus2k23/venus2k23.module').then(m => m.Venus2k23Module)
  },
  {
    path: ':lang/venus-transit-2023',
    loadChildren: () => import('./transit-2023/venus2k23/venus2k23.module').then(m => m.Venus2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shukra-gochar-2023',
    loadChildren: () => import('./transit-2023/venus2k23/venus2k23.module').then(m => m.Venus2k23Module)
  },
  {
    path: ':lang/shukra-gochar-2023',
    loadChildren: () => import('./transit-2023/venus2k23/venus2k23.module').then(m => m.Venus2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shukra-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/venus2k23/venus2k23.module').then(m => m.Venus2k23Module)
  },
  {
    path: ':lang/shukra-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/venus2k23/venus2k23.module').then(m => m.Venus2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'jupiter-transit-2023',
    loadChildren: () => import('./transit-2023/jupiter2k23/jupiter2k23.module').then(m => m.Jupiter2k23Module)
  },
  {
    path: ':lang/jupiter-transit-2023',
    loadChildren: () => import('./transit-2023/jupiter2k23/jupiter2k23.module').then(m => m.Jupiter2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'brihaspati-gochar-2023',
    loadChildren: () => import('./transit-2023/jupiter2k23/jupiter2k23.module').then(m => m.Jupiter2k23Module)
  },
  {
    path: ':lang/brihaspati-gochar-2023',
    loadChildren: () => import('./transit-2023/jupiter2k23/jupiter2k23.module').then(m => m.Jupiter2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'guru-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/jupiter2k23/jupiter2k23.module').then(m => m.Jupiter2k23Module)
  },
  {
    path: ':lang/guru-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/jupiter2k23/jupiter2k23.module').then(m => m.Jupiter2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'ketu-transit-2023',
    loadChildren: () => import('./transit-2023/ketu2k23/ketu2k23.module').then(m => m.Ketu2k23Module)
  },
  {
    path: ':lang/ketu-transit-2023',
    loadChildren: () => import('./transit-2023/ketu2k23/ketu2k23.module').then(m => m.Ketu2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'ketu-gochar-2023',
    loadChildren: () => import('./transit-2023/ketu2k23/ketu2k23.module').then(m => m.Ketu2k23Module)
  },
  {
    path: ':lang/ketu-gochar-2023',
    loadChildren: () => import('./transit-2023/ketu2k23/ketu2k23.module').then(m => m.Ketu2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'ketu-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/ketu2k23/ketu2k23.module').then(m => m.Ketu2k23Module)
  },
  {
    path: ':lang/ketu-sanchara-samaya-dinanka-2023',
    loadChildren: () => import('./transit-2023/ketu2k23/ketu2k23.module').then(m => m.Ketu2k23Module),
    canActivate: [LangGuard]
  },
  {
    path: 'kaal-sarp-dosh-12-types',
    loadChildren: () => import('./seo-temp2022/kaalsarp/kaalsarp.module').then(m => m.KaalsarpModule)
  },
  {
    path: ':lang/kaal-sarp-dosh-12-types',
    loadChildren: () => import('./seo-temp2022/kaalsarp/kaalsarp.module').then(m => m.KaalsarpModule),
    canActivate: [LangGuard]
  },
  {
    path: 'kaal-sarp-dosh-ke-prakar',
    loadChildren: () => import('./seo-temp2022/kaalsarp/kaalsarp.module').then(m => m.KaalsarpModule)
  },
  {
    path: ':lang/kaal-sarp-dosh-ke-prakar',
    loadChildren: () => import('./seo-temp2022/kaalsarp/kaalsarp.module').then(m => m.KaalsarpModule),
    canActivate: [LangGuard]
  },
  {
    path: 'kaalasarpa-dosha',
    loadChildren: () => import('./seo-temp2022/kaalsarp/kaalsarp.module').then(m => m.KaalsarpModule)
  },
  {
    path: ':lang/kaalasarpa-dosha',
    loadChildren: () => import('./seo-temp2022/kaalsarp/kaalsarp.module').then(m => m.KaalsarpModule),
    canActivate: [LangGuard]
  },
  {
    path: 'astrology-remedies/astrological-remedies-for-job-promotion',
    loadChildren: () => import('./seo-temp2022/job-promotion/job-promotion.module').then(m => m.JobPromotionModule)
  },
  {
    path: ':lang/astrology-remedies/astrological-remedies-for-job-promotion',
    loadChildren: () => import('./seo-temp2022/job-promotion/job-promotion.module').then(m => m.JobPromotionModule),
    canActivate: [LangGuard]
  },
  {
    path: 'jyotish-upay/job-me-tarakki-ke-upay',
    loadChildren: () => import('./seo-temp2022/job-promotion/job-promotion.module').then(m => m.JobPromotionModule)
  },
  {
    path: ':lang/jyotish-upay/job-me-tarakki-ke-upay',
    loadChildren: () => import('./seo-temp2022/job-promotion/job-promotion.module').then(m => m.JobPromotionModule),
    canActivate: [LangGuard]
  },
  {
    path: 'gemstones-for-ascendants',
    loadChildren: () => import('./seo-temp2022/gemstone/gemstone.module').then(m => m.GemstoneModule)
  },
  {
    path: ':lang/gemstones-for-ascendants',
    loadChildren: () => import('./seo-temp2022/gemstone/gemstone.module').then(m => m.GemstoneModule),
    canActivate: [LangGuard]
  },
  {
    path: 'janm-lagna-ke-anusar-ratna',
    loadChildren: () => import('./seo-temp2022/gemstone/gemstone.module').then(m => m.GemstoneModule)
  },
  {
    path: ':lang/janm-lagna-ke-anusar-ratna',
    loadChildren: () => import('./seo-temp2022/gemstone/gemstone.module').then(m => m.GemstoneModule),
    canActivate: [LangGuard]
  },
  {
    path: 'arohana-chihne-ratnadakallu',
    loadChildren: () => import('./seo-temp2022/gemstone/gemstone.module').then(m => m.GemstoneModule)
  },
  {
    path: ':lang/arohana-chihne-ratnadakallu',
    loadChildren: () => import('./seo-temp2022/gemstone/gemstone.module').then(m => m.GemstoneModule),
    canActivate: [LangGuard]
  },
  {
    path: 'astrological-remedies-to-conceive-a-child',
    loadChildren: () => import('./seo-temp2022/child-astrology/child-astrology.module').then(m => m.ChildAstrologyModule)
  },
  {
    path: ':lang/astrological-remedies-to-conceive-a-child',
    loadChildren: () => import('./seo-temp2022/child-astrology/child-astrology.module').then(m => m.ChildAstrologyModule),
    canActivate: [LangGuard]
  },
  {
    path: 'santan-prapti-ke-jyotish-upay',
    loadChildren: () => import('./seo-temp2022/child-astrology/child-astrology.module').then(m => m.ChildAstrologyModule)
  },
  {
    path: ':lang/santan-prapti-ke-jyotish-upay',
    loadChildren: () => import('./seo-temp2022/child-astrology/child-astrology.module').then(m => m.ChildAstrologyModule),
    canActivate: [LangGuard]
  },
  {
    path: 'makkalannu-hondalu-makkala-jyotishya',
    loadChildren: () => import('./seo-temp2022/child-astrology/child-astrology.module').then(m => m.ChildAstrologyModule)
  },
  {
    path: ':lang/makkalannu-hondalu-makkala-jyotishya',
    loadChildren: () => import('./seo-temp2022/child-astrology/child-astrology.module').then(m => m.ChildAstrologyModule),
    canActivate: [LangGuard]
  },
  {
    path: 'lunar-eclipse',
    loadChildren: () => import('./solarlunar2022/lunar/lunar.module').then(m => m.LunarModule)
  },
  {
    path: ':lang/lunar-eclipse',
    loadChildren: () => import('./solarlunar2022/lunar/lunar.module').then(m => m.LunarModule),
    canActivate: [LangGuard]
  },
  {
    path: 'chandra-grahan-2022',
    loadChildren: () => import('./solarlunar2022/lunar/lunar.module').then(m => m.LunarModule)
  },
  {
    path: ':lang/chandra-grahan-2022',
    loadChildren: () => import('./solarlunar2022/lunar/lunar.module').then(m => m.LunarModule),
    canActivate: [LangGuard]
  },
  {
    path: 'chandra-grahana-2022',
    loadChildren: () => import('./solarlunar2022/lunar/lunar.module').then(m => m.LunarModule)
  },
  {
    path: ':lang/chandra-grahana-2022',
    loadChildren: () => import('./solarlunar2022/lunar/lunar.module').then(m => m.LunarModule),
    canActivate: [LangGuard]
  },
  {
    path: 'solar-eclipse',
    loadChildren: () => import('./solarlunar2022/solar/solar.module').then(m => m.SolarModule)
  },
  {
    path: ':lang/solar-eclipse',
    loadChildren: () => import('./solarlunar2022/solar/solar.module').then(m => m.SolarModule),
    canActivate: [LangGuard]
  },
  {
    path: 'surya-grahan-2022',
    loadChildren: () => import('./solarlunar2022/solar/solar.module').then(m => m.SolarModule)
  },
  {
    path: ':lang/surya-grahan-2022',
    loadChildren: () => import('./solarlunar2022/solar/solar.module').then(m => m.SolarModule),
    canActivate: [LangGuard]
  },
  {
    path: 'surya-grahana-2022',
    loadChildren: () => import('./solarlunar2022/solar/solar.module').then(m => m.SolarModule)
  },
  {
    path: ':lang/surya-grahana-2022',
    loadChildren: () => import('./solarlunar2022/solar/solar.module').then(m => m.SolarModule),
    canActivate: [LangGuard]
  },
  {
    path: 'solar-eclipse-2023',
    loadChildren: () => import('./solarlunar2023/solar2023/solar2023.module').then(m => m.Solar2023Module)
  },
  {
    path: ':lang/solar-eclipse-2023',
    loadChildren: () => import('./solarlunar2023/solar2023/solar2023.module').then(m => m.Solar2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'surya-grahan-2023',
    loadChildren: () => import('./solarlunar2023/solar2023/solar2023.module').then(m => m.Solar2023Module)
  },
  {
    path: ':lang/surya-grahan-2023',
    loadChildren: () => import('./solarlunar2023/solar2023/solar2023.module').then(m => m.Solar2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'lunar-eclipse-2023',
    loadChildren: () => import('./solarlunar2023/lunar2023/lunar2023.module').then(m => m.Lunar2023Module)
  },
  {
    path: ':lang/lunar-eclipse-2023',
    loadChildren: () => import('./solarlunar2023/lunar2023/lunar2023.module').then(m => m.Lunar2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'chandra-grahan-2023',
    loadChildren: () => import('./solarlunar2023/lunar2023/lunar2023.module').then(m => m.Lunar2023Module)
  },
  {
    path: ':lang/chandra-grahan-2023',
    loadChildren: () => import('./solarlunar2023/lunar2023/lunar2023.module').then(m => m.Lunar2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'solar-eclipse-2024',
    loadChildren: () => import('./solarlunar2024/solar2024/solar2024.module').then(m => m.Solar2024Module)
  },
  {
    path: ':lang/solar-eclipse-2024',
    loadChildren: () => import('./solarlunar2024/solar2024/solar2024.module').then(m => m.Solar2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'surya-grahan-2024',
    loadChildren: () => import('./solarlunar2024/solar2024/solar2024.module').then(m => m.Solar2024Module)
  },
  {
    path: ':lang/surya-grahan-2024',
    loadChildren: () => import('./solarlunar2024/solar2024/solar2024.module').then(m => m.Solar2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'lunar-eclipse-2024',
    loadChildren: () => import('./solarlunar2024/lunar2024/lunar2024.module').then(m => m.Lunar2024Module)
  },
  {
    path: ':lang/lunar-eclipse-2024',
    loadChildren: () => import('./solarlunar2024/lunar2024/lunar2024.module').then(m => m.Lunar2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'chandra-grahan-2024',
    loadChildren: () => import('./solarlunar2024/lunar2024/lunar2024.module').then(m => m.Lunar2024Module)
  },
  {
    path: ':lang/chandra-grahan-2024',
    loadChildren: () => import('./solarlunar2024/lunar2024/lunar2024.module').then(m => m.Lunar2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'numerology-introduction',
    loadChildren: () => import('./numerology-new/numerology-new.module').then(m => m.NumerologyNewModule)
  },
  {
    path: ':lang/numerology-introduction',
    loadChildren: () => import('./numerology-new/numerology-new.module').then(m => m.NumerologyNewModule),
    canActivate: [LangGuard]
  },
  {
    path: 'numerology-in-hindi',
    loadChildren: () => import('./numerology-new/numerology-new.module').then(m => m.NumerologyNewModule)
  },
  {
    path: ':lang/numerology-in-hindi',
    loadChildren: () => import('./numerology-new/numerology-new.module').then(m => m.NumerologyNewModule),
    canActivate: [LangGuard]
  },
  {
    path: 'sankhyashastra-numerology-in-kannada',
    loadChildren: () => import('./numerology-new/numerology-new.module').then(m => m.NumerologyNewModule)
  },
  {
    path: ':lang/sankhyashastra-numerology-in-kannada',
    loadChildren: () => import('./numerology-new/numerology-new.module').then(m => m.NumerologyNewModule),
    canActivate: [LangGuard]
  },
  {
    path: 'nakshatras-constellations',
    loadChildren: () => import('./nakshatra-astrology/nakshatra-astrology.module').then(m => m.NakshatraAstrologyModule)
  },
  {
    path: ':lang/nakshatras-constellations',
    loadChildren: () => import('./nakshatra-astrology/nakshatra-astrology.module').then(m => m.NakshatraAstrologyModule),
    canActivate: [LangGuard]
  },
  {
    path: 'vastu-shastra',
    loadChildren: () => import('./vastu-shastra/vastu-shastra.module').then(m => m.VastuShastraModule)
  },
  {
    path: ':lang/vastu-shastra',
    loadChildren: () => import('./vastu-shastra/vastu-shastra.module').then(m => m.VastuShastraModule),
    canActivate: [LangGuard]
  },
  {
    path: 'collaboration',
    loadChildren: () => import('./collaboration/collaboration.module').then(m => m.CollaborationModule)
  },
  {
    path: ':lang/collaboration',
    loadChildren: () => import('./collaboration/collaboration.module').then(m => m.CollaborationModule),
    canActivate: [LangGuard]
  },
  {
    path: 'mantras',
    loadChildren: () => import('./mantras/mantras.module').then(m => m.MantrasModule)
  },
  {
    path: ':lang/mantras',
    loadChildren: () => import('./mantras/mantras.module').then(m => m.MantrasModule),
    canActivate: [LangGuard]
  },
  {
    path: 'tarot',
    loadChildren: () => import('./tarot-reading/tarot-reading.module').then(m => m.TarotReadingModule)
  },
  {
    path: ':lang/tarot',
    loadChildren: () => import('./tarot-reading/tarot-reading.module').then(m => m.TarotReadingModule),
    canActivate: [LangGuard]
  },
  {
    path: 'annanprashan-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/annanprashan-m2023/annanprashan-m2023.module').then(m => m.AnnanprashanM2023Module)
  },
  {
    path: ':lang/annanprashan-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/annanprashan-m2023/annanprashan-m2023.module').then(m => m.AnnanprashanM2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'bhoomi-pujan-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/bhoomi-pujan-m2023/bhoomi-pujan-m2023.module').then(m => m.BhoomiPujanM2023Module)
  },
  {
    path: ':lang/bhoomi-pujan-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/bhoomi-pujan-m2023/bhoomi-pujan-m2023.module').then(m => m.BhoomiPujanM2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'car-bike-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/car-bike-m2023/car-bike-m2023.module').then(m => m.CarBikeM2023Module)
  },
  {
    path: ':lang/car-bike-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/car-bike-m2023/car-bike-m2023.module').then(m => m.CarBikeM2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'gold-buying-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/gold-buying-m2023/gold-buying-m2023.module').then(m => m.GoldBuyingM2023Module)
  },
  {
    path: ':lang/gold-buying-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/gold-buying-m2023/gold-buying-m2023.module').then(m => m.GoldBuyingM2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'griha-pravesh-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/griha-pravesh-m2023/griha-pravesh-m2023.module').then(m => m.GrihaPraveshM2023Module)
  },
  {
    path: ':lang/griha-pravesh-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/griha-pravesh-m2023/griha-pravesh-m2023.module').then(m => m.GrihaPraveshM2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'marriage-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/marriage-m2023/marriage-m2023.module').then(m => m.MarriageM2023Module)
  },
  {
    path: ':lang/marriage-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/marriage-m2023/marriage-m2023.module').then(m => m.MarriageM2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'vivah-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/marriage-m2023/marriage-m2023.module').then(m => m.MarriageM2023Module)
  },
  {
    path: ':lang/vivah-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/marriage-m2023/marriage-m2023.module').then(m => m.MarriageM2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mundan-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/mundan-m2023/mundan-m2023.module').then(m => m.MundanM2023Module)
  },
  {
    path: ':lang/mundan-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/mundan-m2023/mundan-m2023.module').then(m => m.MundanM2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'naamkaran-shubh-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/naamkaran-m2023/naamkaran-m2023.module').then(m => m.NaamkaranM2023Module)
  },
  {
    path: ':lang/naamkaran-shubh-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/naamkaran-m2023/naamkaran-m2023.module').then(m => m.NaamkaranM2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shubh-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/shub-main-m2023/shub-main-m2023.module').then(m => m.ShubMainM2023Module)
  },
  {
    path: ':lang/shubh-muhurat-2023',
    loadChildren: () => import('./shubh-muhurat-2023/shub-main-m2023/shub-main-m2023.module').then(m => m.ShubMainM2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'astrology-2022',
    loadChildren: () => import('./app2022/app2022.module').then(m => m.App2022Module)
  },
  {
    path: ':lang/astrology-2022',
    loadChildren: () => import('./app2022/app2022.module').then(m => m.App2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'order-history',
    loadChildren: () => import('./order-history/order-history.module').then(m => m.OrderHistoryModule),
    canActivate: [AuthGuard],
  },
  {
    path: ':lang/order-history',
    loadChildren: () => import('./order-history/order-history.module').then(m => m.OrderHistoryModule),
    canActivate: [AuthGuard, LangGuard],
  },
  {
    path: 'talk-to-astrologer',
    loadChildren: () => import('./call-with-astrologer/call-with-astrologer.module').then(m => m.CallWithAstrologerModule)
  },
  {
    path: ':lang/talk-to-astrologer',
    loadChildren: () => import('./call-with-astrologer/call-with-astrologer.module').then(m => m.CallWithAstrologerModule),
    canActivate: [LangGuard]
  },
  {
    path: 'chat-with-astrologer',
    loadChildren: () => import('./chat-with-astrologer/chat-with-astrologer.module').then(m => m.ChatWithAstrologerModule)
  },
  {
    path: ':lang/chat-with-astrologer',
    loadChildren: () => import('./chat-with-astrologer/chat-with-astrologer.module').then(m => m.ChatWithAstrologerModule),
    canActivate: [LangGuard]
  },
  {
    path: 'consultation-with-astrologer',
    loadChildren: () => import('./consult-with-astrologer/consult-with-astrologer.module').then(m => m.ConsultWithAstrologerModule)
  },
  {
    path: ':lang/consultation-with-astrologer',
    loadChildren: () => import('./consult-with-astrologer/consult-with-astrologer.module').then(m => m.ConsultWithAstrologerModule),
    canActivate: [LangGuard]
  },
  {
    path: 'assistant-chat',
    loadChildren: () => import('./assistant-chat/assistant-chat.module').then(m => m.AssistantChatModule)
  },
  {
    path: 'best-astrologer',
    loadChildren: () => import('./astrologer-profile/astrologer-profile.module').then(m => m.AstrologerProfileModule)
  },
  {
    path: ':lang/best-astrologer',
    loadChildren: () => import('./astrologer-profile/astrologer-profile.module').then(m => m.AstrologerProfileModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli',
    loadChildren: () => import('./kundli/kundli.module').then(m => m.KundliModule)
  },
  {
    path: ':lang/freekundli',
    loadChildren: () => import('./kundli/kundli.module').then(m => m.KundliModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/basic-detail',
    loadChildren: () => import('./kundli-detail/basic-detail/basic-detail.module').then(m => m.BasicDetailModule)
  },
  {
    path: ':lang/freekundli/basic-detail',
    loadChildren: () => import('./kundli-detail/basic-detail/basic-detail.module').then(m => m.BasicDetailModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/how-to-read-kundli',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule)
  },
  {
    path: ':lang/freekundli/how-to-read-kundli',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/apni-kundli-kaise-dekhe',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule)
  },
  {
    path: ':lang/freekundli/apni-kundli-kaise-dekhe',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/jaatakavannu-hege-oduvudu',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule)
  },
  {
    path: ':lang/freekundli/jaatakavannu-hege-oduvudu',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/how-to-read-houses-in-kundli',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m =>
      m.HowToReadHousesModule)
  },
  {
    path: ':lang/freekundli/how-to-read-houses-in-kundli',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m =>
      m.HowToReadHousesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/kundli-me-bhav-ko-kaise-dekhe',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m =>
      m.HowToReadHousesModule)
  },
  {
    path: ':lang/freekundli/kundli-me-bhav-ko-kaise-dekhe',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m =>
      m.HowToReadHousesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/manegalannu-oduvudu-hege-jaataka',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m =>
      m.HowToReadHousesModule)
  },
  {
    path: ':lang/freekundli/manegalannu-oduvudu-hege-jaataka',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m =>
      m.HowToReadHousesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/how-to-read-planets-in-astrology',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m =>
      m.HowToReadPlanetsModule)
  },
  {
    path: ':lang/freekundli/how-to-read-planets-in-astrology',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m =>
      m.HowToReadPlanetsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/jyotish-me-grahon-ko-kaise-dekhe',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m =>
      m.HowToReadPlanetsModule)
  },
  {
    path: ':lang/freekundli/jyotish-me-grahon-ko-kaise-dekhe',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m =>
      m.HowToReadPlanetsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/grahagalannu-oduvudu-hege-jyotishya',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m =>
      m.HowToReadPlanetsModule)
  },
  {
    path: ':lang/freekundli/grahagalannu-oduvudu-hege-jyotishya',
    loadChildren: () => import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m =>
      m.HowToReadPlanetsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/planets-signs-houses-in-astrology',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m =>
      m.PlanetsSignsHousesModule)
  },
  {
    path: ':lang/freekundli/planets-signs-houses-in-astrology',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m =>
      m.PlanetsSignsHousesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/graha-rashi-bhav-in-jyotish',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m =>
      m.PlanetsSignsHousesModule)
  },
  {
    path: ':lang/freekundli/graha-rashi-bhav-in-jyotish',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m =>
      m.PlanetsSignsHousesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/grahagalu-chihnegalu-manegalu-jyotishya',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m =>
      m.PlanetsSignsHousesModule)
  },
  {
    path: ':lang/freekundli/grahagalu-chihnegalu-manegalu-jyotishya',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m =>
      m.PlanetsSignsHousesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/planets-signs-and-their-significance-in-astrology',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
      m.PlanetsSignsAndSignificanceModule)
  },
  {
    path: ':lang/freekundli/planets-signs-and-their-significance-in-astrology',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
      m.PlanetsSignsAndSignificanceModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/graha-rashi-aur-unka-mahatva-in-jyotish',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
      m.PlanetsSignsAndSignificanceModule)
  },
  {
    path: ':lang/freekundli/graha-rashi-aur-unka-mahatva-in-jyotish',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
      m.PlanetsSignsAndSignificanceModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundli/grahagalu-chihnegalu-mattu-avugala-mahatva-jyotishya',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
      m.PlanetsSignsAndSignificanceModule)
  },
  {
    path: ':lang/freekundli/grahagalu-chihnegalu-mattu-avugala-mahatva-jyotishya',
    loadChildren: () => import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
      m.PlanetsSignsAndSignificanceModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali',
    loadChildren: () => import('./kundali/kundali.module').then(m => m.KundaliModule),
  },
  {
    path: ':lang/freekundali',
    loadChildren: () => import('./kundali/kundali.module').then(m => m.KundaliModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/basic-detail',
    loadChildren: () => import('./kundli-detail/basic-detail/basic-detail.module').then(m => m.BasicDetailModule),
  },
  {
    path: ':lang/freekundali/basic-detail',
    loadChildren: () => import('./kundli-detail/basic-detail/basic-detail.module').then(m => m.BasicDetailModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/how-to-read-kundli',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule),
  },
  {
    path: ':lang/freekundali/how-to-read-kundli',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/apni-kundli-kaise-dekhe',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule),
  },
  {
    path: ':lang/freekundali/apni-kundli-kaise-dekhe',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/jaatakavannu-hege-oduvudu',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule),
  },
  {
    path: ':lang/freekundali/jaatakavannu-hege-oduvudu',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-kundli/how-to-read-kundli.module').then(m => m.HowToReadKundliModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/how-to-read-houses-in-kundli',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m => m.HowToReadHousesModule),
  },
  {
    path: ':lang/freekundali/how-to-read-houses-in-kundli',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m => m.HowToReadHousesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/kundli-me-bhav-ko-kaise-dekhe',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m => m.HowToReadHousesModule),
  },
  {
    path: ':lang/freekundali/kundli-me-bhav-ko-kaise-dekhe',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m => m.HowToReadHousesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/manegalannu-oduvudu-hege-jaataka',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m => m.HowToReadHousesModule),
  },
  {
    path: ':lang/freekundali/manegalannu-oduvudu-hege-jaataka',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-houses/how-to-read-houses.module').then(m => m.HowToReadHousesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/how-to-read-planets-in-astrology',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m => m.HowToReadPlanetsModule),
  },
  {
    path: ':lang/freekundali/how-to-read-planets-in-astrology',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m => m.HowToReadPlanetsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/jyotish-me-grahon-ko-kaise-dekhe',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m => m.HowToReadPlanetsModule),
  },
  {
    path: ':lang/freekundali/jyotish-me-grahon-ko-kaise-dekhe',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m => m.HowToReadPlanetsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/grahagalannu-oduvudu-hege-jyotishya',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m => m.HowToReadPlanetsModule),
  },
  {
    path: ':lang/freekundali/grahagalannu-oduvudu-hege-jyotishya',
    loadChildren: () =>
      import('./kundliUnderstanding/how-to-read-planets/how-to-read-planets.module').then(m => m.HowToReadPlanetsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/planets-signs-houses-in-astrology',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m => m.PlanetsSignsHousesModule),
  },
  {
    path: ':lang/freekundali/planets-signs-houses-in-astrology',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m => m.PlanetsSignsHousesModule),
  },
  {
    path: 'freekundali/graha-rashi-bhav-in-jyotish',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m => m.PlanetsSignsHousesModule),
  },
  {
    path: ':lang/freekundali/graha-rashi-bhav-in-jyotish',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m => m.PlanetsSignsHousesModule),
  },
  {
    path: 'freekundali/grahagalu-chihnegalu-manegalu-jyotishya',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m => m.PlanetsSignsHousesModule),
  },
  {
    path: ':lang/freekundali/grahagalu-chihnegalu-manegalu-jyotishya',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-houses/planets-signs-houses.module').then(m => m.PlanetsSignsHousesModule),
  },
  {
    path: 'freekundali/planets-signs-and-their-significance-in-astrology',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
        m.PlanetsSignsAndSignificanceModule),
  },
  {
    path: ':lang/freekundali/planets-signs-and-their-significance-in-astrology',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
        m.PlanetsSignsAndSignificanceModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/graha-rashi-aur-unka-mahatva-in-jyotish',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
        m.PlanetsSignsAndSignificanceModule),
  },
  {
    path: ':lang/freekundali/graha-rashi-aur-unka-mahatva-in-jyotish',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
        m.PlanetsSignsAndSignificanceModule),
    canActivate: [LangGuard]
  },
  {
    path: 'freekundali/grahagalu-chihnegalu-mattu-avugala-mahatva-jyotishya',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
        m.PlanetsSignsAndSignificanceModule),
  },
  {
    path: ':lang/freekundali/grahagalu-chihnegalu-mattu-avugala-mahatva-jyotishya',
    loadChildren: () =>
      import('./kundliUnderstanding/planets-signs-and-significance/planets-signs-and-significance.module').then(m =>
        m.PlanetsSignsAndSignificanceModule),
    canActivate: [LangGuard]
  },
  {
    path: 'kundli-generate',
    loadChildren: () => import('./kundli-detail/basic-detail/basic-detail.module').then(m => m.BasicDetailModule)
  },
  {
    path: ':lang/kundli-generate',
    loadChildren: () => import('./kundli-detail/basic-detail/basic-detail.module').then(m => m.BasicDetailModule),
    canActivate: [LangGuard]
  },
  {
    path: 'shubh-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat/shubh-muhurat.module').then(m => m.ShubhMuhuratModule)
  },
  {
    path: ':lang/shubh-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat/shubh-muhurat.module').then(m => m.ShubhMuhuratModule),
    canActivate: [LangGuard]
  },
  {
    path: 'shubha-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat/shubh-muhurat.module').then(m => m.ShubhMuhuratModule)
  },
  {
    path: ':lang/shubha-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat/shubh-muhurat.module').then(m => m.ShubhMuhuratModule),
    canActivate: [LangGuard]
  },
  {
    path: 'annaprashan-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/annaprashan-m/annaprashan-m.module').then(m =>
      m.AnnaprashanMModule)
  },
  {
    path: ':lang/annaprashan-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/annaprashan-m/annaprashan-m.module').then(m =>
      m.AnnaprashanMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'anna-prashana-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/annaprashan-m/annaprashan-m.module').then(m =>
      m.AnnaprashanMModule)
  },
  {
    path: ':lang/anna-prashana-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/annaprashan-m/annaprashan-m.module').then(m =>
      m.AnnaprashanMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'griha-pravesh-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/griha-pravesh-m/griha-pravesh-m.module').then(m =>
      m.GrihaPraveshMModule)
  },
  {
    path: ':lang/griha-pravesh-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/griha-pravesh-m/griha-pravesh-m.module').then(m =>
      m.GrihaPraveshMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'gruha-pravesha-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/griha-pravesh-m/griha-pravesh-m.module').then(m =>
      m.GrihaPraveshMModule)
  },
  {
    path: ':lang/gruha-pravesha-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/griha-pravesh-m/griha-pravesh-m.module').then(m =>
      m.GrihaPraveshMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'marriage-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/marriage-m/marriage-m.module').then(m => m.MarriageMModule)
  },
  {
    path: ':lang/marriage-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/marriage-m/marriage-m.module').then(m => m.MarriageMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'vivah-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/marriage-m/marriage-m.module').then(m => m.MarriageMModule)
  },
  {
    path: ':lang/vivah-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/marriage-m/marriage-m.module').then(m => m.MarriageMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'vivaha-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/marriage-m/marriage-m.module').then(m => m.MarriageMModule)
  },
  {
    path: ':lang/vivaha-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/marriage-m/marriage-m.module').then(m => m.MarriageMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'mundan-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/mundan-m/mundan-m.module').then(m => m.MundanMModule)
  },
  {
    path: ':lang/mundan-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/mundan-m/mundan-m.module').then(m => m.MundanMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'kesha-mundana-sanskara-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/mundan-m/mundan-m.module').then(m => m.MundanMModule)
  },
  {
    path: ':lang/kesha-mundana-sanskara-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/mundan-m/mundan-m.module').then(m => m.MundanMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'namkaran-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/namkaran-m/namkaran-m.module').then(m => m.NamkaranMModule)
  },
  {
    path: ':lang/namkaran-muhurat-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/namkaran-m/namkaran-m.module').then(m => m.NamkaranMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'namakarana-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/namkaran-m/namkaran-m.module').then(m => m.NamkaranMModule)
  },
  {
    path: ':lang/namakarana-muhurta-2022',
    loadChildren: () => import('./shub-muhurat-2022/shubh-muhurat-types/namkaran-m/namkaran-m.module').then(m => m.NamkaranMModule),
    canActivate: [LangGuard]
  },
  {
    path: 'festival',
    loadChildren: () => import('./festival/festival2021/festival2021.module').then(m => m.Festival2021Module)
  },
  {
    path: ':lang/festival',
    loadChildren: () => import('./festival/festival2021/festival2021.module').then(m => m.Festival2021Module),
    canActivate: [LangGuard]
  },
  {
    path: 'indian-calendar-festivals-2022',
    loadChildren: () => import('./festival/festival2022/festival2022.module').then(m => m.Festival2022Module)
  },
  {
    path: ':lang/indian-calendar-festivals-2022',
    loadChildren: () => import('./festival/festival2022/festival2022.module').then(m => m.Festival2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'indian-calendar-festivals-2023',
    loadChildren: () => import('./festival/festival2023/festival2023.module').then(m => m.Festival2023Module)
  },
  {
    path: ':lang/indian-calendar-festivals-2023',
    loadChildren: () => import('./festival/festival2023/festival2023.module').then(m => m.Festival2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'indian-calendar-festivals-2024',
    loadChildren: () => import('./festival/festival2024/festival2024.module').then(m => m.Festival2024Module)
  },
  {
    path: ':lang/indian-calendar-festivals-2024',
    loadChildren: () => import('./festival/festival2024/festival2024.module').then(m => m.Festival2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: ':lang/signup',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [LangGuard]
  },
  {
    path: 'matchmaking',
    loadChildren: () => import('./kundli-matching/matching.module').then(m => m.MatchingModule)
  },
  {
    path: ':lang/matchmaking',
    loadChildren: () => import('./kundli-matching/matching.module').then(m => m.MatchingModule),
    canActivate: [LangGuard]
  },
  {
    path: 'kundli-milan-matching',
    loadChildren: () => import('./kundli-matching/matching.module').then(m => m.MatchingModule)
  },
  {
    path: ':lang/kundli-milan-matching',
    loadChildren: () => import('./kundli-matching/matching.module').then(m => m.MatchingModule),
    canActivate: [LangGuard]
  },
  {
    path: 'chinese-astrology',
    loadChildren: () => import('./chinese-horoscope/chinese-horoscope.module').then(m => m.ChineseHoroscopeModule)
  },
  {
    path: ':lang/chinese-astrology',
    loadChildren: () => import('./chinese-horoscope/chinese-horoscope.module').then(m => m.ChineseHoroscopeModule),
    canActivate: [LangGuard]
  },
  {
    path: 'chinese-rashifal',
    loadChildren: () => import('./chinese-horoscope/chinese-horoscope.module').then(m => m.ChineseHoroscopeModule)
  },
  {
    path: ':lang/chinese-rashifal',
    loadChildren: () => import('./chinese-horoscope/chinese-horoscope.module').then(m => m.ChineseHoroscopeModule),
    canActivate: [LangGuard]
  },
  {
    path: 'chinese-horoscope-2022',
    loadChildren: () => import('./chinese-2022/chinese-2022.module').then(m => m.Chinese2022Module)
  },
  {
    path: ':lang/chinese-horoscope-2022',
    loadChildren: () => import('./chinese-2022/chinese-2022.module').then(m => m.Chinese2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'chinese-rashifal-2022',
    loadChildren: () => import('./chinese-2022/chinese-2022.module').then(m => m.Chinese2022Module)
  },
  {
    path: ':lang/chinese-rashifal-2022',
    loadChildren: () => import('./chinese-2022/chinese-2022.module').then(m => m.Chinese2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shop',
    loadChildren: () => import('./astromall/astromall.module').then(m => m.AstromallModule)
  },
  {
    path: ':lang/shop',
    loadChildren: () => import('./astromall/astromall.module').then(m => m.AstromallModule),
    canActivate: [LangGuard]
  },
  {
    path: 'annual-horoscope',
    loadChildren: () => import('./annual-horoscope/annual-horoscope.module').then(m => m.AnnualHoroscopeModule)
  },
  {
    path: ':lang/annual-horoscope',
    loadChildren: () => import('./annual-horoscope/annual-horoscope.module').then(m => m.AnnualHoroscopeModule),
    canActivate: [LangGuard]
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./comingSoon/comingSoon.module').then(m => m.ComingSoonModule)
  },
  {
    path: ':lang/coming-soon',
    loadChildren: () => import('./comingSoon/comingSoon.module').then(m => m.ComingSoonModule),
    canActivate: [LangGuard]
  },
  {
    path: 'horoscope',
    loadChildren: () => import('./horoscopes/horoscopes.module').then(m => m.HoroscopesModule)
  },
  {
    path: ':lang/horoscope',
    loadChildren: () => import('./horoscopes/horoscopes.module').then(m => m.HoroscopesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'rashifal',
    loadChildren: () => import('./horoscopes/horoscopes.module').then(m => m.HoroscopesModule)
  },
  {
    path: ':lang/rashifal',
    loadChildren: () => import('./horoscopes/horoscopes.module').then(m => m.HoroscopesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'rashi-bhavishya',
    loadChildren: () => import('./horoscopes/horoscopes.module').then(m => m.HoroscopesModule)
  },
  {
    path: ':lang/rashi-bhavishya',
    loadChildren: () => import('./horoscopes/horoscopes.module').then(m => m.HoroscopesModule),
    canActivate: [LangGuard]
  },
  {
    path: 'horoscope-2022',
    loadChildren: () => import('./yearly2022/yearly2022.module').then(m => m.Yearly2022Module)
  },
  {
    path: ':lang/horoscope-2022',
    loadChildren: () => import('./yearly2022/yearly2022.module').then(m => m.Yearly2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rashifal-2022',
    loadChildren: () => import('./yearly2022/yearly2022.module').then(m => m.Yearly2022Module)
  },
  {
    path: ':lang/rashifal-2022',
    loadChildren: () => import('./yearly2022/yearly2022.module').then(m => m.Yearly2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'varshika-rashi-bhavishya-2022',
    loadChildren: () => import('./yearly2022/yearly2022.module').then(m => m.Yearly2022Module)
  },
  {
    path: ':lang/varshika-rashi-bhavishya-2022',
    loadChildren: () => import('./yearly2022/yearly2022.module').then(m => m.Yearly2022Module),
    canActivate: [LangGuard]
  },
  {
    path: 'horoscope-2023',
    loadChildren: () => import('./yearly2023/yearly2023.module').then(m => m.Yearly2023Module)
  },
  {
    path: ':lang/horoscope-2023',
    loadChildren: () => import('./yearly2023/yearly2023.module').then(m => m.Yearly2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rashifal-2023',
    loadChildren: () => import('./yearly2023/yearly2023.module').then(m => m.Yearly2023Module)
  },
  {
    path: ':lang/rashifal-2023',
    loadChildren: () => import('./yearly2023/yearly2023.module').then(m => m.Yearly2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'varshika-rashi-bhavishya-2023',
    loadChildren: () => import('./yearly2023/yearly2023.module').then(m => m.Yearly2023Module)
  },
  {
    path: ':lang/varshika-rashi-bhavishya-2023',
    loadChildren: () => import('./yearly2023/yearly2023.module').then(m => m.Yearly2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'horoscope-2024',
    loadChildren: () => import('./yearly2024/yearly2024.module').then(m => m.Yearly2024Module)
  },
  {
    path: ':lang/horoscope-2024',
    loadChildren: () => import('./yearly2024/yearly2024.module').then(m => m.Yearly2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rashifal-2024',
    loadChildren: () => import('./yearly2024/yearly2024.module').then(m => m.Yearly2024Module)
  },
  {
    path: ':lang/rashifal-2024',
    loadChildren: () => import('./yearly2024/yearly2024.module').then(m => m.Yearly2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'varshika-rashi-bhavishya-2024',
    loadChildren: () => import('./yearly2024/yearly2024.module').then(m => m.Yearly2024Module)
  },
  {
    path: ':lang/varshika-rashi-bhavishya-2024',
    loadChildren: () => import('./yearly2024/yearly2024.module').then(m => m.Yearly2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'compatibility',
    loadChildren: () => import('./compatibility/compatibility.module').then(m => m.CompatibilityModule),
  },
  {
    path: ':lang/compatibility',
    loadChildren: () => import('./compatibility/compatibility.module').then(m => m.CompatibilityModule),
    canActivate: [LangGuard]
  },
  {
    path: 'annual-chinese-horoscope',
    loadChildren: () => import('./annual-chinese-horoscope/annual-chinese-horoscope.module').then(m => m.AnnualChineseHoroscopeModule)
  },
  {
    path: ':lang/annual-chinese-horoscope',
    loadChildren: () => import('./annual-chinese-horoscope/annual-chinese-horoscope.module').then(m => m.AnnualChineseHoroscopeModule),
    canActivate: [LangGuard]
  },
  {
    path: 'customer-support',
    loadChildren: () => import('./support-chat/support-chat.module').then(m => m.SupportChatModule)
  },
  {
    path: ':lang/customer-support',
    loadChildren: () => import('./support-chat/support-chat.module').then(m => m.SupportChatModule),
    canActivate: [LangGuard]
  },
  {
    path: 'astrologer',
    loadChildren: () => import('./astrologer-seo/astrologer-seo.module').then(m => m.AstrologerSeoModule)
  },
  {
    path: ':lang/astrologer',
    loadChildren: () => import('./astrologer-seo/astrologer-seo.module').then(m => m.AstrologerSeoModule),
    canActivate: [LangGuard]
  },
  {
    path: 'today-panchang',
    loadChildren: () => import('./panchang/panchang.module').then(m => m.PanchangModule)
  },
  {
    path: ':lang/today-panchang',
    loadChildren: () => import('./panchang/panchang.module').then(m => m.PanchangModule),
    canActivate: [LangGuard]
  },
  {
    path: 'aaj-ka-panchang',
    loadChildren: () => import('./panchang/panchang.module').then(m => m.PanchangModule)
  },
  {
    path: ':lang/aaj-ka-panchang',
    loadChildren: () => import('./panchang/panchang.module').then(m => m.PanchangModule),
    canActivate: [LangGuard]
  },
  {
    path: 'indina-panchanga',
    loadChildren: () => import('./panchang/panchang.module').then(m => m.PanchangModule)
  },
  {
    path: ':lang/indina-panchanga',
    loadChildren: () => import('./panchang/panchang.module').then(m => m.PanchangModule),
    canActivate: [LangGuard]
  },
  {
    path: 'kundali-report',
    loadChildren: () => import('./pdf/pdf.module').then(m => m.PdfModule),
  },
  {
    path: 'live',
    loadChildren: () => import('./astro-tv/astro-tv.module').then(m => m.AstroTvModule)
  },
  {
    path: ':lang/live',
    loadChildren: () => import('./astro-tv/astro-tv.module').then(m => m.AstroTvModule),
    canActivate: [LangGuard]
  },
  {
    path: 'user-testimonials',
    loadChildren: () => import('./user-testimonials/user-testimonials.module').then(m => m.UserTestimonialsModule)
  },
  {
    path: ':lang/user-testimonials',
    loadChildren: () => import('./user-testimonials/user-testimonials.module').then(m => m.UserTestimonialsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'mole-astrology',
    loadChildren: () => import('./mole-astrology/mole-astrology.module').then(m => m.MoleAstrologyModule)
  },
  {
    path: ':lang/mole-astrology',
    loadChildren: () => import('./mole-astrology/mole-astrology.module').then(m => m.MoleAstrologyModule),
    canActivate: [LangGuard]
  },
  {
    path: 'sitemap',
    loadChildren: () => import('./sitemap/sitemap.module').then(m => m.SitemapModule)
  },
  {
    path: ':lang/sitemap',
    loadChildren: () => import('./sitemap/sitemap.module').then(m => m.SitemapModule),
    canActivate: [LangGuard]
  },
  {
    path: 'zodiac-signs',
    loadChildren: () => import('./zodiac-signs-astrology/zodiac-signs-astrology.module').then(m => m.ZodiacSignsAstrologyModule)
  },
  {
    path: ':lang/zodiac-signs',
    loadChildren: () => import('./zodiac-signs-astrology/zodiac-signs-astrology.module').then(m => m.ZodiacSignsAstrologyModule),
    canActivate: [LangGuard]
  },
  {
    path: 'amavasya-2023',
    loadChildren: () => import('./amavasya2023/amavasya2023.module').then(m => m.Amavasya2023Module)
  },
  {
    path: ':lang/amavasya-2023',
    loadChildren: () => import('./amavasya2023/amavasya2023.module').then(m => m.Amavasya2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'purnima-2023',
    loadChildren: () => import('./purnima2023/purnima2023.module').then(m => m.Purnima2023Module)
  },
  {
    path: ':lang/purnima-2023',
    loadChildren: () => import('./purnima2023/purnima2023.module').then(m => m.Purnima2023Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shared',
    loadChildren: () => import('./recordings/recordings.module').then(m => m.RecordingsModule)
  },
  {
    path: ':lang/shared',
    loadChildren: () => import('./recordings/recordings.module').then(m => m.RecordingsModule),
    canActivate: [LangGuard]
  },
  {
    path: 'love-calculator',
    loadChildren: () => import('./love-calculator/love-calculator.module').then(m => m.LoveCalculatorModule)
  },
  {
    path: ':lang/love-calculator',
    loadChildren: () => import('./love-calculator/love-calculator.module').then(m => m.LoveCalculatorModule),
    canActivate: [LangGuard]
  },
  {
    path: 'e-pooja',
    loadChildren: () => import('./e-pooja/e-pooja.module').then(m => m.EPoojaModule)
  },
  {
    path: ':lang/e-pooja',
    loadChildren: () => import('./e-pooja/e-pooja.module').then(m => m.EPoojaModule),
    canActivate: [LangGuard]
  },
  {
    path: 'book-a-pooja',
    loadChildren: () => import('./e-pooja/e-pooja.module').then(m => m.EPoojaModule)
  },
  {
    path: ':lang/book-a-pooja',
    loadChildren: () => import('./e-pooja/e-pooja.module').then(m => m.EPoojaModule),
    canActivate: [LangGuard]
  },
  {
    path: 'referral-program',
    loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule)
  },
  {
    path: ':lang/referral-program',
    loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule),
    canActivate: [LangGuard]
  },
  {
    path: 'pooja',
    loadChildren: () => import('./pooja-share/pooja-share.module').then(m => m.PoojaShareModule)
  },
  {
    path: ':lang/pooja',
    loadChildren: () => import('./pooja-share/pooja-share.module').then(m => m.PoojaShareModule),
    canActivate: [LangGuard]
  },
  {
    path: 'numerology-2024',
    loadChildren: () => import('./numerology-2024/numerology-2024.module').then(m => m.Numerology2024Module)
  },
  {
    path: ':lang/numerology-2024',
    loadChildren: () => import('./numerology-2024/numerology-2024.module').then(m => m.Numerology2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'tarot-2024',
    loadChildren: () => import('./tarot-2024/tarot-2024.module').then(m => m.Tarot2024Module)
  },
  {
    path: ':lang/tarot-2024',
    loadChildren: () => import('./tarot-2024/tarot-2024.module').then(m => m.Tarot2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'planetary-transit-2024',
    loadChildren: () => import('./transit-2024/main2k24/main2k24.module').then(m => m.Main2k24Module)
  },
  {
    path: ':lang/planetary-transit-2024',
    loadChildren: () => import('./transit-2024/main2k24/main2k24.module').then(m => m.Main2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'graha-gochar-2024',
    loadChildren: () => import('./transit-2024/main2k24/main2k24.module').then(m => m.Main2k24Module)
  },
  {
    path: ':lang/graha-gochar-2024',
    loadChildren: () => import('./transit-2024/main2k24/main2k24.module').then(m => m.Main2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rahu-transit-2024',
    loadChildren: () => import('./transit-2024/rahu2k24/rahu2k24.module').then(m => m.Rahu2k24Module)
  },
  {
    path: ':lang/rahu-transit-2024',
    loadChildren: () => import('./transit-2024/rahu2k24/rahu2k24.module').then(m => m.Rahu2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'rahu-gochar-2024',
    loadChildren: () => import('./transit-2024/rahu2k24/rahu2k24.module').then(m => m.Rahu2k24Module)
  },
  {
    path: ':lang/rahu-gochar-2024',
    loadChildren: () => import('./transit-2024/rahu2k24/rahu2k24.module').then(m => m.Rahu2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'sun-transit-2024',
    loadChildren: () => import('./transit-2024/sun2k24/sun2k24.module').then(m => m.Sun2k24Module)
  },
  {
    path: ':lang/sun-transit-2024',
    loadChildren: () => import('./transit-2024/sun2k24/sun2k24.module').then(m => m.Sun2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'surya-gochar-2024',
    loadChildren: () => import('./transit-2024/sun2k24/sun2k24.module').then(m => m.Sun2k24Module)
  },
  {
    path: ':lang/surya-gochar-2024',
    loadChildren: () => import('./transit-2024/sun2k24/sun2k24.module').then(m => m.Sun2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'venus-transit-2024',
    loadChildren: () => import('./transit-2024/venus2k24/venus2k24.module').then(m => m.Venus2k24Module)
  },
  {
    path: ':lang/venus-transit-2024',
    loadChildren: () => import('./transit-2024/venus2k24/venus2k24.module').then(m => m.Venus2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shukra-gochar-2024',
    loadChildren: () => import('./transit-2024/venus2k24/venus2k24.module').then(m => m.Venus2k24Module)
  },
  {
    path: ':lang/shukra-gochar-2024',
    loadChildren: () => import('./transit-2024/venus2k24/venus2k24.module').then(m => m.Venus2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'jupiter-transit-2024',
    loadChildren: () => import('./transit-2024/jupiter2k24/jupiter2k24.module').then(m => m.Jupiter2k24Module)
  },
  {
    path: ':lang/jupiter-transit-2024',
    loadChildren: () => import('./transit-2024/jupiter2k24/jupiter2k24.module').then(m => m.Jupiter2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'brihaspati-gochar-2024',
    loadChildren: () => import('./transit-2024/jupiter2k24/jupiter2k24.module').then(m => m.Jupiter2k24Module)
  },
  {
    path: ':lang/brihaspati-gochar-2024',
    loadChildren: () => import('./transit-2024/jupiter2k24/jupiter2k24.module').then(m => m.Jupiter2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'ketu-transit-2024',
    loadChildren: () => import('./transit-2024/ketu2k24/ketu2k24.module').then(m => m.Ketu2k24Module)
  },
  {
    path: ':lang/ketu-transit-2024',
    loadChildren: () => import('./transit-2024/ketu2k24/ketu2k24.module').then(m => m.Ketu2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'ketu-gochar-2024',
    loadChildren: () => import('./transit-2024/ketu2k24/ketu2k24.module').then(m => m.Ketu2k24Module)
  },
  {
    path: ':lang/ketu-gochar-2024',
    loadChildren: () => import('./transit-2024/ketu2k24/ketu2k24.module').then(m => m.Ketu2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mercury-transit-2024',
    loadChildren: () => import('./transit-2024/mercury2k24/mercury2k24.module').then(m => m.Mercury2k24Module)
  },
  {
    path: ':lang/mercury-transit-2024',
    loadChildren: () => import('./transit-2024/mercury2k24/mercury2k24.module').then(m => m.Mercury2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'budh-gochar-2024',
    loadChildren: () => import('./transit-2024/mercury2k24/mercury2k24.module').then(m => m.Mercury2k24Module)
  },
  {
    path: ':lang/budh-gochar-2024',
    loadChildren: () => import('./transit-2024/mercury2k24/mercury2k24.module').then(m => m.Mercury2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'moon-transit-2024',
    loadChildren: () => import('./transit-2024/moon2k24/moon2k24.module').then(m => m.Moon2k24Module)
  },
  {
    path: ':lang/moon-transit-2024',
    loadChildren: () => import('./transit-2024/moon2k24/moon2k24.module').then(m => m.Moon2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'chandra-gochar-2024',
    loadChildren: () => import('./transit-2024/moon2k24/moon2k24.module').then(m => m.Moon2k24Module)
  },
  {
    path: ':lang/chandra-gochar-2024',
    loadChildren: () => import('./transit-2024/moon2k24/moon2k24.module').then(m => m.Moon2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mars-transit-2024',
    loadChildren: () => import('./transit-2024/mars2k24/mars2k24.module').then(m => m.Mars2k24Module)
  },
  {
    path: ':lang/mars-transit-2024',
    loadChildren: () => import('./transit-2024/mars2k24/mars2k24.module').then(m => m.Mars2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'mangal-gochar-2024',
    loadChildren: () => import('./transit-2024/mars2k24/mars2k24.module').then(m => m.Mars2k24Module)
  },
  {
    path: ':lang/mangal-gochar-2024',
    loadChildren: () => import('./transit-2024/mars2k24/mars2k24.module').then(m => m.Mars2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'saturn-transit-2024',
    loadChildren: () => import('./transit-2024/saturn2k24/saturn2k24.module').then(m => m.Saturn2k24Module)
  },
  {
    path: ':lang/saturn-transit-2024',
    loadChildren: () => import('./transit-2024/saturn2k24/saturn2k24.module').then(m => m.Saturn2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shani-gochar-2024',
    loadChildren: () => import('./transit-2024/saturn2k24/saturn2k24.module').then(m => m.Saturn2k24Module)
  },
  {
    path: ':lang/shani-gochar-2024',
    loadChildren: () => import('./transit-2024/saturn2k24/saturn2k24.module').then(m => m.Saturn2k24Module),
    canActivate: [LangGuard]
  },
  {
    path: 'TnC-World-Cup-Offer-2023',
    loadChildren: () => import('./world-cup/world-cup.module').then(m => m.WorldCupModule)
  },
  {
    path: ':lang/TnC-World-Cup-Offer-2023',
    loadChildren: () => import('./world-cup/world-cup.module').then(m => m.WorldCupModule),
    canActivate: [LangGuard]
  },
  {
    path: 'fasts-calendar-2024',
    loadChildren: () => import('./fasts/fasts2024/fasts2024.module').then(m => m.Fasts2024Module)
  },
  {
    path: ':lang/fasts-calendar-2024',
    loadChildren: () => import('./fasts/fasts2024/fasts2024.module').then(m => m.Fasts2024Module),
    canActivate: [LangGuard]
  },
  {
    path: 'shubh-muhurat-2024',
    loadChildren: () => import('./shubh-muhurat-2024/shubh-muhurat-2024.module').then(m => m.ShubhMuhurat2024Module)
  },
  {
    path: ':lang/shubh-muhurat-2024',
    loadChildren: () => import('./shubh-muhurat-2024/shubh-muhurat-2024.module').then(m => m.ShubhMuhurat2024Module),
    canActivate: [LangGuard]
  },
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: '',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
}
