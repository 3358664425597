<div class="ttloader" *ngIf="LoadingVideo || this.common.getFullPageLoader()"><span class="loader_spinner"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span></div>
<app-header [ngClass]="{'visibleNone': firstCode == 'user-testimonials'}"></app-header>
<ng-template #chatCallKit let-payload>
  <h3 mat-dialog-title class="tool-kit-heading"><i class="fa fa-comment"></i> Chat Request</h3>
  <div mat-dialog-content>
    <div class="offline-astrologer">
      <img (error)="changeSource($event)" src="{{payload.consultantPic}}" alt="Astrologer"
           class="img img-responsive">
    </div>
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="text-center">
          <h6 class="name_astrologers_request"><strong>{{payload.consultantName}}</strong></h6>
        </div>
        <div class="text-center">
          <p class="chat_request_accept">{{'Please accept chat request' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="center">
    <button mat-button mat-dialog-close class="btn_cht busy-status chat_request_reject_btn" [disabled]="acceptLoader"
            (click)="rejectChat(payload)">{{'Reject' | translate}}</button>&nbsp;&nbsp;
    <button mat-button class="btn_cht online-status chat_request_accept_btn" [mat-dialog-close]="true"
            [disabled]="acceptLoader" (click)="acceptChat(payload)">{{'Accept' | translate}}</button>
  </mat-dialog-actions>
</ng-template>
<router-outlet>
</router-outlet>
