import { Injectable } from '@angular/core';
import {AppConstant} from '../global/common-file/constant';
import {HttpWrapperService} from '../global/common-file/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class WaitListService {
  private waitListApi = AppConstant.apiUrl + AppConstant.waitListApi;
  private cancelWaitListApi = AppConstant.apiUrl + AppConstant.cancelWaitListApi;
  private acceptWaitListChatApi = AppConstant.chatApi + AppConstant.chatAcceptApi;
  private rejectWaitListChatApi = AppConstant.chatApi + AppConstant.chatRejectApi;
  private chatStatusApi = AppConstant.chatApi + AppConstant.chatStatusApi;
  private unholdWaitlist = AppConstant.apiUrl + AppConstant.unholdWaitlist;

  constructor(private _http: HttpWrapperService) { }
  getWaitList = (params) => {
    return this._http.get(this.waitListApi, params, true);
  }

  deleteWaitList = (params) => {
    return this._http.postParams(this.cancelWaitListApi, params, true);
  }

  acceptWaitListChat = (params) => {
    return this._http.postHeaderParams(this.acceptWaitListChatApi, params, true);
  }

  rejectWaitListChat = (params) => {
    return this._http.postParams(this.rejectWaitListChatApi, params, true);
  }

  unholdWaitList = (params) => {
    return this._http.postParams(this.unholdWaitlist, params, true);
  }

  checkChatStatus = (params) => {
    return this._http.get(this.chatStatusApi, params, true);
  }
}
