import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RatePipe} from './rate.pipe';
import {TimePipe} from './time.pipe';
import {MinuteSecondsPipe} from './minute-seconds.pipe';
import {SearchPipe} from './search.pipe';
import {Nl2brPipe} from './nl2br.pipe';
import {RateSignPipe} from './rate-sign.pipe';
import {KeySearchPipe} from './key-search.pipe';
import {DegreePipe} from './degree.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import {SafeResourcePipe} from './safe-resource.pipe';
import {ShortNumberPipe} from './short-number.pipe';
import {StringToHtmlPipe} from './string-to-html.pipe';


@NgModule({
  declarations: [RatePipe, TimePipe, MinuteSecondsPipe, SearchPipe, Nl2brPipe, RateSignPipe, KeySearchPipe, DegreePipe,
    SafeHtmlPipe, SafeResourcePipe, ShortNumberPipe, StringToHtmlPipe],
  exports: [
    RatePipe,
    RateSignPipe,
    TimePipe,
    SearchPipe,
    Nl2brPipe,
    MinuteSecondsPipe,
    KeySearchPipe,
    DegreePipe,
    SafeHtmlPipe,
    SafeResourcePipe,
    ShortNumberPipe,
    StringToHtmlPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipeModule {
}
