import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../global/common-file/common.service';

@Pipe({
  name: 'rateSign'
})
export class RateSignPipe implements PipeTransform {
  constructor(private common: CommonService) {}

  transform(value, toFixed = 0, formatted = false): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    let conversion = this.common.getWithExpiry('conRate');
    if (conversion) {
      let rate = conversion.conversionFactor;
      if (formatted) {
        return ((conversion.isoCode != 'INR') ? conversion.isoCode : '₹ ') + ' ' + formatter.format(parseFloat(value) / rate);
      }else{
        if (conversion.isoCode == 'INR') {
          return '₹ ' + (parseFloat(value) / rate).toFixed(toFixed).toString();
        }else{
          return conversion.isoCode + ' ' + (parseFloat(value) / rate).toFixed((toFixed == 0) ? 2 : toFixed).toString();
        }
      }
    } else{
      if (this.common.timeZone() == 'Asia/Kolkata') {
        if (formatted) {
          return '₹ ' + formatter.format(parseFloat(value));
        }else{
          return '₹ ' + parseFloat(value).toFixed(toFixed).toString();
        }
      }else{
        if (formatted) {
          return 'USD ' + formatter.format(parseFloat(value) * 0.77);
        }else{
          return 'USD ' + (parseFloat(value) * 0.77).toFixed(2).toString();
        }
      }
    }
  }
}
